import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { registrationList } from "../../actions/registrasi/registrasi_action";
import {
    getRegistrationListPending, getRegistrationList, getRegistrationListError
} from "../../reducers/registrasi/registrasi_reducer";
import { editPpds } from "../../actions/member/ppds_action";
import {
    getEditPpds,
    getEditPpdsPending,
    getEditPpdsError,
} from "../../reducers/member/ppds_reducer"
import LabelInputVerticalComponent from "../../components/global-components/LabelInputVerticalComponent";
import InputComponent from "../../components/form/InputComponent"
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class ModalEditNoKolegium extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kolegium_no: this.props.kolegium_no,
    };
    this.handleChangeNoKolegium = this.handleChangeNoKolegium.bind(this);
    this.editNoKolegium = this.editNoKolegium.bind(this);
  }

  handleChangeNoKolegium(e) {
    if (e !== null) {
      this.setState({ kolegium_no: e.target.value });
    } else {
      this.setState({ kolegium_no: "" });
    }
  }

  editNoKolegium() {
    const { editPpds, onHide, registrationList } = this.props;
    const { kolegium_no } = this.state;

    if (!kolegium_no) {
      this.showToastError(
        "Gagal mengubah PPDS, Nomor Kolegium tidak boleh kosong."
      );
      return;
    }

    const param_editNoKolegium = {
      id: this.props.id,
      email: this.props.email,
      name: this.props.name,
      university_before: this.props.university_before,
      graduated: this.props.graduated,
      birthday: this.props.birthday,
      place_of_birth: this.props.place_of_birth,
      address: this.props.address,
      handphone: this.props.handphone,
      npa_idi: this.props.npa_idi,
      ip_s1: this.props.ip_s1,
      ip_profesi: this.props.ip_profesi,
      ip_cumulation: this.props.ip_cumulation,
      destination_universities: [
        {
          university_id: this.props.prodi,
          period_selection_month: this.props.bulan,
          period_selection_year: this.props.tahun,
        },
      ],
      notes: this.props.notes,
      kolegium_no:
        this.state.kolegium_no !== ""
          ? this.state.kolegium_no
          : this.props.kolegium_no,
    };
    const callback = () => {
      onHide();
      let param_registrationList = {
        id: this.props.id,
      };
      registrationList(param_registrationList);
    };
    editPpds(param_editNoKolegium, callback);
  }

  showToastError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  componentDidMount() {
    let param_registrationList = {
      id: this.props.id,
    };
    this.props.registrationList(param_registrationList);
  }

  render() {
    const { registrationList_data } = this.props;
    let kolegium_no = "";
    if (registrationList_data) {
      let registrationData = registrationList_data.data[0];
      kolegium_no = registrationData.kolegium_no;
    }
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        id={this.props.id}
        prodi={this.props.prodi}
        bulan={this.props.bulan}
        tahun={this.props.tahun}
        email={this.props.email}
        name={this.props.name}
        university_before={this.props.university_before}
        graduated={this.props.graduated}
        birthday={this.props.birthday}
        place_of_birth={this.props.place_of_birth}
        address={this.props.address}
        handphone={this.props.handphone}
        npa_idi={this.props.npa_idi}
        kolegium_no={this.props.kolegium_no}
        notes={this.props.notes}
        dialogClassName="modal-width"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600, marginBottom: 50 }}>
            Edit Nomor Kolegium
          </p>
        </Modal.Header>
        <Modal.Body>
          <Row className={css(styles.bodyModal)}>
            <LabelInputVerticalComponent label="Nomor Kolegium" width="600px">
              <InputComponent
                isClearable={false}
                placeholder="Nomor Kolegium"
                onChange={this.handleChangeNoKolegium}
                defaultValue={kolegium_no}
                id="kolegium_no"
                required = "required"
              />
            </LabelInputVerticalComponent>
          </Row>
          <Row
            horizontal="flex-end"
            vertical="center"
            className={css(styles.footerModal)}
          >
            <Button
              variant="contained"
              color="primary"
              className={css(styles.approveButton)}
              startIcon={<CheckIcon />}
              onClick={() => this.editNoKolegium()}
            >
              SIMPAN
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        marginTop: 20,
        backgroundColor: "#FBFBFD",
        height: 80,
        paddingLeft: 40,
        paddingRight: 40,
    },
    bodyModal: {
        paddingLeft: 40,
        paddingRight: 40,
    },
});

function mapStateToProps({ registrationList, editPpds }) {
    return {
        registrationList_pending: getRegistrationListPending(registrationList),
        registrationList_data: getRegistrationList(registrationList),
        registrationList_error: getRegistrationListError(registrationList),
        editPpds_pending: getEditPpdsPending(editPpds),
        editPpds_data: getEditPpds(editPpds),
        editPpds_error: getEditPpdsError(editPpds),
    };
}


export default connect(mapStateToProps, { registrationList, editPpds })(ModalEditNoKolegium);
