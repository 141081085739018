import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { universityList } from "../../actions/master-data/university_action";
import {
  getUniversityListPending,
  getUniversityList,
  getUniversityListError,
} from "../../reducers/master-data/university_reducer";
import { registrationList } from "../../actions/registrasi_sp2/registrasi_action";
import {
  getRegistrationListPending,
  getRegistrationList,
  getRegistrationListError,
} from "../../reducers/registrasi_sp2/registrasi_reducer";
import { editPpds } from "../../actions/member/ppds_sp2_action";
import {
  getEditPpds,
  getEditPpdsPending,
  getEditPpdsError,
} from "../../reducers/member/ppds_reducer";
import LabelInputVerticalComponent from "../../components/global-components/LabelInputVerticalComponent";
import SelectComponent from "../../components/form/SelectComponent";
import { connect } from "react-redux";

class ModalEditProdiTujuan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProdi: this.props.prodi,
      selectedBulan: this.props.bulan,
      selectedTahun: this.props.tahun,
    };
    this.handleChangeProdi = this.handleChangeProdi.bind(this);
    this.handleChangeBulan = this.handleChangeBulan.bind(this);
    this.handleChangeTahun = this.handleChangeTahun.bind(this);
    this.editProdiTujuan = this.editProdiTujuan.bind(this);
  }

  handleChangeProdi(e) {
    if (e !== null) {
      this.setState({ selectedProdi: e.value });
    } else {
      this.setState({ selectedProdi: "" });
    }
  }

  handleChangeBulan(e) {
    if (e !== null) {
      this.setState({ selectedBulan: e.value });
    } else {
      this.setState({ selectedBulan: "" });
    }
  }

  handleChangeTahun(e) {
    if (e !== null) {
      this.setState({ selectedTahun: e.value });
    } else {
      this.setState({ selectedTahun: "" });
    }
  }

  editProdiTujuan() {
    const { editPpds, onHide, registrationList } = this.props;
    const param_editProdiTujuan = {
      id: this.props.id,
      email: this.props.email,
      name: this.props.name,
      university_before: this.props.university_before,
      graduated: this.props.graduated,
      birthday: this.props.birthday,
      place_of_birth: this.props.place_of_birth,
      address: this.props.address,
      handphone: this.props.handphone,
      npa_idi: this.props.npa_idi,
      ip_s1: this.props.ip_s1,
      ip_profesi: this.props.ip_profesi,
      ip_cumulation: this.props.ip_cumulation,
      notes: this.props.notes,
      kolegium_no: this.props.kolegium_no,
      working_hospital_1: this.props.working_hospital_1,
      working_hospital_2: this.props.working_hospital_2,
      working_hospital_3: this.props.working_hospital_3,
      destination_universities: [
        {
          university_id:
            this.state.selectedProdi !== ""
              ? this.state.selectedProdi
              : this.props.prodi,
          period_selection_month:
            this.state.selectedBulan !== ""
              ? this.state.selectedBulan
              : this.props.bulan,
          period_selection_year:
            this.state.selectedTahun !== ""
              ? this.state.selectedTahun
              : this.props.tahun,
          interest: this.props.interest,
        },
      ],
    };
    const callback = () => {
      onHide();
      let param_registrationList = {
        id: this.props.id,
      };
      registrationList(param_registrationList);
    };
    editPpds(param_editProdiTujuan, callback);
  }

  componentDidMount() {
    let param_universityList = {
      page: 1,
      length: 999,
      search_text: "",
      id: "",
    };
    this.props.universityList(param_universityList);
    let param_registrationList = {
      id: this.props.id,
    };
    this.props.registrationList(param_registrationList);
  }

  render() {
    const { universityList_data, registrationList_data } = this.props;
    let data,
      prodiOption = [];
    let yearOption = [];
    let userProdiLabel = "";
    let userProdiID = "";
    let userProdiBulan = "";
    let userProdiTahun = "";
    let faculty;

    if (universityList_data) {
      data = universityList_data.data;
      faculty = data.filter(
        (item) =>
          (item.abbreviation === "FKUI" && item.code === "01") ||
          (item.abbreviation === "FKUNAIR" && item.code === "9")
      );

      prodiOption = faculty.map((item) => ({
        value: item.id,
        label: item.abbreviation + " - Fakultas Kedokteran " + item.name,
      }));
    }
    if (registrationList_data) {
      let registrationData = registrationList_data.data[0];
      userProdiLabel =
        registrationData.ppds_destination_university_data[0].university_data
          .abbreviation +
        " - Fakultas Kedokteran " +
        registrationData.ppds_destination_university_data[0].university_data
          .name;
      userProdiID =
        registrationData.ppds_destination_university_data[0].university_data.id;
      userProdiBulan =
        registrationData.ppds_destination_university_data[0]
          .period_selection_month;
      userProdiTahun =
        registrationData.ppds_destination_university_data[0]
          .period_selection_year;
    }
    const bulanOption = [
      { value: "1", label: "Januari" },
      { value: "7", label: "Juli" },
    ];
    const currentYear = new Date().getFullYear();
    for (let i = currentYear-1; i <= currentYear + 1; i++) {
      const year = { value: `${i}`, label: i };
      yearOption = [...yearOption, year];
    }
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        id={this.props.id}
        interest={this.props.interest}
        prodi={this.props.prodi}
        bulan={this.props.bulan}
        tahun={this.props.tahun}
        email={this.props.email}
        name={this.props.name}
        university_before={this.props.university_before}
        graduated={this.props.graduated}
        birthday={this.props.birthday}
        place_of_birth={this.props.place_of_birth}
        address={this.props.address}
        handphone={this.props.handphone}
        npa_idi={this.props.npa_idi}
        working_hospital_1={this.props.working_hospital_1}
        working_hospital_2={this.props.working_hospital_2}
        working_hospital_3={this.props.working_hospital_3}
        notes={this.props.notes}
        dialogClassName="modal-width-prodi"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600, marginBottom: 50 }}>
            Prodi Tujuan
          </p>
        </Modal.Header>
        <Modal.Body>
          <Row className={css(styles.bodyModal)}>
            <LabelInputVerticalComponent
              label="Fakultas"
              width="400px"
              marginRight="10px"
            >
              <SelectComponent
                isClearable={false}
                placeholder="Program Studi"
                options={prodiOption}
                onChange={this.handleChangeProdi}
                defaultValue={{ value: userProdiID, label: userProdiLabel }}
                id="prodi"
              />
            </LabelInputVerticalComponent>
            <LabelInputVerticalComponent
              label="Bulan"
              width="200px"
              marginRight="10px"
            >
              <SelectComponent
                isClearable={false}
                placeholder="Bulan"
                options={bulanOption}
                onChange={this.handleChangeBulan}
                defaultValue={{
                  value: userProdiBulan,
                  label: userProdiBulan === "1" ? "Januari" : "Juli",
                }}
                id="bulan"
              />
            </LabelInputVerticalComponent>
            <LabelInputVerticalComponent label="Tahun" width="150px">
              <SelectComponent
                isClearable={false}
                placeholder="Tahun"
                options={yearOption}
                onChange={this.handleChangeTahun}
                defaultValue={{ value: userProdiTahun, label: userProdiTahun }}
                id="tahun"
              />
            </LabelInputVerticalComponent>
          </Row>
          <Row
            horizontal="flex-end"
            vertical="center"
            className={css(styles.footerModal)}
          >
            <Button
              variant="contained"
              color="primary"
              className={css(styles.approveButton)}
              startIcon={<CheckIcon />}
              onClick={() => this.editProdiTujuan()}
            >
              SIMPAN
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    marginTop: 20,
    backgroundColor: "#FBFBFD",
    height: 80,
    paddingLeft: 40,
    paddingRight: 40,
  },
  bodyModal: {
    paddingLeft: 40,
    paddingRight: 40,
  },
});

function mapStateToProps({ universityList, registrationList, editPpds }) {
  return {
    universityList_pending: getUniversityListPending(universityList),
    universityList_data: getUniversityList(universityList),
    universityList_error: getUniversityListError(universityList),
    registrationList_pending: getRegistrationListPending(registrationList),
    registrationList_data: getRegistrationList(registrationList),
    registrationList_error: getRegistrationListError(registrationList),
    editPpds_pending: getEditPpdsPending(editPpds),
    editPpds_data: getEditPpds(editPpds),
    editPpds_error: getEditPpdsError(editPpds),
  };
}

export default connect(mapStateToProps, {
  universityList,
  registrationList,
  editPpds,
})(ModalEditProdiTujuan);
