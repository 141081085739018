import React, { Component } from "react";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import ModalAddTransRS from "./Modal_add_transrs";
import _ from "lodash";
import { getUser } from "../../helpers/user";

const styles = StyleSheet.create({
  container: {
    width: 225,
    marginLeft: 10,
  },
});
class HeaderJournal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      modalAddTransRSShow: false,
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.delayedQuery = this.delayedQuery.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
  }
  componentDidMount() {}

  delayedQuery = _.debounce((value) => {
    return this.props.handleRefresh({ search: value });
  }, 500);

  handleRefresh() {
    this.props.handleRefresh();
  }

  handleSearchChange(e) {
    this.setState({ search: e.target.value });
    this.delayedQuery(e.target.value);
  }

  render() {
    const user = getUser().role_codes[0];
    return (
      <Row
        style={{ padding: 24, paddingBottom: 30, backgroundColor: "#F8F9FB" }}
        horizontal={user === "member" ? "space-between" : "flex-end"}
      >
        <ModalAddTransRS
          show={this.state.modalAddPelatihanShow}
          onHide={() => this.setState({ modalAddPelatihanShow: false })}
          handleRefresh={this.handleRefresh}
          branchOptions={this.props.branchOptions}
          hospitalOptions={this.props.hospitalOptions}
          strOptions={this.props.strOptions}
        />
        {user === "member" && (
          <Button
            variant="contained"
            color="primary"
            className={css(styles.approveButton)}
            startIcon={<AddIcon />}
            onClick={() => this.setState({ modalAddPelatihanShow: true })}
          >
            Ajukan Trans RS
          </Button>
        )}
        <div className={css(styles.container)}>
          <InputComponent
            onChange={this.handleSearchChange}
            value={this.state.tes}
            placeholder="Cari Nama, Kode Request"
            startIcon={SearchIcon}
          />
        </div>
      </Row>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(HeaderJournal);
