import React, { Component } from "react";
import { Row } from "simple-flexbox";
import ContentContainer from "../../components/container/ContainerComponent";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import HeaderDetailP2kbComponent from "../../components/p2kb/HeaderDetailP2kbComponent";
import IconError from "../../assets/icon-error";
import IconDocumentP2KB from "../../assets/icon-document-p2kb";
import IconLaunch from "../../assets/icon-launch";
import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { StyleSheet, css } from "aphrodite";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import {
  p2kbList,
  setSelectedSKP,
  rejectDetailP2kb,
} from "../../actions/p2kb/p2kb_action";
import {
  getP2kbListPending,
  getP2kbList,
  getP2kbListError,
} from "../../reducers/p2kb/p2kb_reducer";
import ModalAcceptSkp from "./Modal_accept_skp";
import ModalRejectSkp from "./Modal_reject_skp";
import ModalDetailSKPKongres from "./Skp_menghadiri_kongres_detail_modal";
import ModalDocument from "../../components/modal/ModalDocument";
import moment from "moment";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import DetailRejectModal from "../../components/modal/DetailRejectModalP2kb";

class Skp_menghadiri_kongres_approval extends Component {
  constructor() {
    super();
    this.tableRef = React.createRef();
    this.state = {
      modalDetailDokumenSKPShow: false,
      modalAcceptSKPShow: false,
      modalRejectSKPShow: false,
      ModalDetailSKP_KongresShow: false,
      imgUrl: "",
      start_date: localStorage.getItem('start_date') ? localStorage.getItem('start_date') : '',
      end_date: localStorage.getItem('end_date') ? localStorage.getItem('end_date') : '',
      modal_title: "",
      id: null,
      forRefresh: false,
    };
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleShowRevision = this.handleShowRevision.bind(this);
  }

  handleRefresh(state) {
    if (state) {
      this.setState(state);
    }
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ imgUrl: imgUrl });
    this.setState({ modal_title: modal_title });
    this.setState({ modalDetailDokumenSKPShow: true });
  }

  handleSkpDetail(data) {
    this.props.setSelectedSKP(data);
    this.setState({ ModalDetailSKP_KongresShow: true });
  }

  handleShowModalReject(id) {
    this.setState({ id: id });
    this.setState({ modalRejectSKPShow: true });
  }

  handleShowRevision(data) {
    this.setState({
      last_reject_notes: data.last_reject_notes,
      last_reject_filename: data.last_reject_filename,
      showDetailReject: true,
    });
  }

  componentDidMount() {
    const param = {
      activity_id: this.props.match.params.id,
      start_date: this.state.start_date,
      user_id: localStorage.getItem('user_id_skp'),
      serkom_id: localStorage.getItem('isSerkom') ? localStorage.getItem('id_serkom') : '',
      end_date: this.state.end_date
    };
    const callback = () => {
      let skp = 0
      const data = this.props.p2kbList_data.data[0]
      this.props.p2kbList_data.data.map((item) => {
        skp += item.total_skp
      })
      this.setState({ skp_total: skp })
    }
    this.props.p2kbList(param, callback);
  }

  render() {
    const {
      p2kbList,
      p2kbList_data,
      match: {
        params: { id },
      },
      p2kbList_pending,
    } = this.props;
    const role_codes = JSON.parse(localStorage.getItem("user_paboi")).data
      .role_codes[0];
    let data = [];
    let activity_name = "";
    let activity_type = "";
    let realm_activity = "";
    let skp_status = "";
    let last_skp_admin = "";
    let step = "";
    let isRoleCodesMatchStep = false;
    let stepOne = "admin_paboi_cabang";
    let stepTwo = "ketua_paboi_cabang";
    let stepThree = "root";
    let stepFour = "ketua_paboi_pusat";
    let stepFive = "cpd_cme";
    if (p2kbList_data && p2kbList_data.length !== 0) {
      data = p2kbList_data.data[0];
      let arrayData = []
      p2kbList_data.data.map((item, index) => {
        item.p2kb_details.map((detail, idx) => {
          arrayData.push(detail)
        })
      })
      data.p2kb_details = arrayData;
      activity_name = data.activity_data.name;
      activity_type = data.activity_data.activity_type;
      realm_activity = data.activity_data.realm_activity.name;
      skp_status = data.status;
      step = data.step;
      last_skp_admin =
        data.role_next_approve !== null
          ? data.role_next_approve.role_name
          : "CPD/CME";
      if (role_codes === stepOne && step === 1 && skp_status === 0) {
        isRoleCodesMatchStep = true;
      }
      if (role_codes === stepTwo && step === 2 && skp_status === 0) {
        isRoleCodesMatchStep = true;
      }
      if (role_codes === stepThree && step === 3 && skp_status === 0) {
        isRoleCodesMatchStep = true;
      }
      if (role_codes === stepFour && step === 4 && skp_status === 0) {
        isRoleCodesMatchStep = true;
      }
      if (role_codes === stepFive && step === 5 && skp_status === 0) {
        isRoleCodesMatchStep = true;
      }
    }
    let length = 1;
    let records_total = 1;
    return (
      <ContentContainer>
        <ModalDocument
          show={this.state.modalDetailDokumenSKPShow}
          onHide={() => this.setState({ modalDetailDokumenSKPShow: false })}
          filepath="member_p2kb"
          filename={this.state.imgUrl}
          modalTitle={this.state.modal_title}
        />
        <ModalAcceptSkp
          show={this.state.modalAcceptSKPShow}
          onHide={() => this.setState({ modalAcceptSKPShow: false })}
          id={data.id}
          handleRefresh={this.handleRefresh}
        />
        <ModalRejectSkp
          show={this.state.modalRejectSKPShow}
          onHide={() => this.setState({ modalRejectSKPShow: false })}
          id={this.state.id}
          handleRefresh={this.handleRefresh}
        />
        <DetailRejectModal
          show={this.state.showDetailReject}
          onHide={() => this.setState({ showDetailReject: false })}
          reason={this.state.last_reject_notes}
          filepath="member_p2kb"
          filename={this.state.last_reject_filename}
        />
        {!p2kbList_pending && (
          <HeaderDetailP2kbComponent
            activity_name={activity_name}
            activity_type={activity_type}
            realm_activity={realm_activity}
            skp={this.state.skp_total}
            skp_status={skp_status}
            last_skp_admin={last_skp_admin}
            icon={IconError}
            isAdmin={
              isRoleCodesMatchStep === true
                ? role_codes.includes("ketua") || role_codes.includes("cpd")
                  ? "ketua"
                  : "admin"
                : false
            }
            approval={
              data.role_next_approve
                ? data.role_next_approve.role_code.includes("ketua") ||
                  data.role_next_approve.role_code.includes("cpd")
                  ? "Persetujuan"
                  : "Verifikasi"
                : "Persetujuan"
            }
            acceptSKP={() => this.setState({ modalAcceptSKPShow: true })}
            rejectSKP={() => this.setState({ modalRejectSKPShow: true })}
          />
        )}
        <ModalDetailSKPKongres
          show={this.state.ModalDetailSKP_KongresShow}
          onHide={() => this.setState({ ModalDetailSKP_KongresShow: false })}
        />
        <MaterialTable
          tableRef={this.tableRef}
          title="P2kb"
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          columns={[
            {
              title: "No",
              field: "name",
              render: (rowData) => {
                return <>{rowData.no}</>;
              },
              width: 20,
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },
            {
              title: "Tanggal Kegiatan",
              render: ({ activity_date }) =>
                moment(activity_date).format("DD MMMM YYYY"),
            },

            {
              title: "Tema Kegiatan",
              render: ({ form_json }) => {
                if (form_json) {
                  let objectParse = JSON.parse(form_json);
                  if (objectParse.theme) return objectParse.theme;
                }
              },
            },
            {
              title: "Tingkat",
              field: "mst_activity_skp_type_detail_data.parent.name",
            },
            {
              title: "Peran Serta",
              field: "mst_activity_skp_type_detail_data.name",
            },
            {
              title: "Durasi",
              field: "activity_skp_type_value_data.label",
            },
            {
              title: "Jumlah SKP", render: (data) => {
                if (data.total_skp_eksternal !== undefined) {
                  if (data.total_skp_eksternal > 0) {
                    return data.total_skp_eksternal.toFixed(2);
                  }
                  else {
                    return data.total_skp.toFixed(2);
                  }
                }
                else {
                  if (data.total_skp_eksternal > 0) {
                    return data.total_skp_eksternal.toFixed(2);
                  }
                  else {
                    return data.total_skp.toFixed(2)
                  }
                }
              }
            },
            {
              title: "Dokumen",
              render: (data) => {
                return (
                  <DetailButtonComponent
                    button={(props) => (
                      <Row
                        onClick={props.onClick}
                        className={css(styles.buttonDocHover)}
                      >
                        <IconDocumentP2KB />
                      </Row>
                    )}
                  >
                    <MenuItem
                      onClick={() =>
                        this.handleOpenDocument(
                          data.attachments[0].filename,
                          "Daftar Hadir"
                        )
                      }
                    >
                      <IconLaunch />
                      <span style={{ marginLeft: 10, paddingTop: 2 }}>
                        Daftar Hadir
                      </span>
                    </MenuItem>
                  </DetailButtonComponent>
                );
              },
            },
            {
              title: "Status",
              field: "status",
              render: ({ status }) => {
                return (
                  <TableStatusComponent
                    status={
                      status === 0
                        ? "Menunggu"
                        : status === 1
                          ? "Approved"
                          : "Revisi"
                    }
                  />
                );
              },
            },
            {
              title: "",
              render: (data) => {
                return (
                  <DetailButtonComponent>
                    <MenuItem onClick={() => this.handleSkpDetail(data)}>
                      Detail
                    </MenuItem>

                    {/* {data.status === 2 ? (
                      <MenuItem onClick={() => this.handleShowRevision(data)}>
                        Alasan Revisi
                      </MenuItem>
                    ) : (
                      isRoleCodesMatchStep && (
                        <MenuItem
                          onClick={() => this.handleShowModalReject(data.id)}
                        >
                          Revisi
                        </MenuItem>
                      )
                    )} */}
                  </DetailButtonComponent>
                );
              },
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                activity_id: id,
                start_date: this.state.start_date,
                user_id: localStorage.getItem('user_id_skp'),
                serkom_id: localStorage.getItem('isSerkom') ? localStorage.getItem('id_serkom') : '',
                end_date: this.state.end_date
              };
              p2kbList(param, resolve, reject, true);
            })
          }
          options={{
            paginationType: "stepped",
            pageSize: 10,
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            headerStyle: {
              backgroundColor: "#F8F9FB",
              zIndex: 0,
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <Row
                // vertical="center"
                horizontal="space-between"
                style={{ paddingRight: 20, paddingLeft: 20 }}
              >
                <Row
                  style={{
                    // backgroundColor: "red",
                    width: "50%",
                    borderBottom: "1px solid rgba(224, 224, 224)",
                  }}
                  vertical="center"
                >
                  <p>
                    {" "}
                    Menampilkan {length} dari {records_total} data{" "}
                  </p>
                </Row>
                <TablePagination
                  {...props}
                  color="primary"
                  labelRowsPerPage={
                    <div style={{ fontSize: 14 }}>{props.labelRowsPerPage}</div>
                  }
                  labelDisplayedRows={(row) => (
                    <div style={{ fontSize: 14, color: "red" }}></div>
                  )}
                  SelectProps={{
                    style: {
                      fontSize: 20,
                    },
                  }}
                />
              </Row>
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  buttonDocHover: {
    ":hover": {
      cursor: "pointer",
    },
  },
});

function mapStateToProps({ p2kbList, setSelectedSKP }) {
  return {
    p2kbList_pending: getP2kbListPending(p2kbList),
    p2kbList_data: getP2kbList(p2kbList),
    p2kbList_error: getP2kbListError(p2kbList),
  };
}

export default connect(mapStateToProps, {
  p2kbList,
  setSelectedSKP,
  rejectDetailP2kb,
})(withRouter(Skp_menghadiri_kongres_approval));
