import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, reset } from "redux-form";
import {
  renderSelectWithLabel,
  renderDatepickerWithLabel,
  renderInputWithLabel,
  renderFileForm,
} from "../../../components/form/FormComponent";
import { listActivity } from "../../../actions/master-data/activity_action";
import {
  getListActivity,
  getActivityPending,
  getActivityError,
} from "../../../reducers/master-data/activity_reducer";
import { uploadP2kb, editDetailP2kb } from "../../../actions/p2kb/p2kb_action";
import {
  getP2kbUploadPending,
  getP2kbUpload,
  getP2kbUploadError,
} from "../../../reducers/p2kb/p2kb_reducer";
import moment from "moment";
import DetailContentLihatBerkas from "../../../components/detail/DetailContentLihatBerkas";
import ModalDetailDokumenSKP from "../Modal_document";
import Loading from "../../../components/global-components/LoadingComponent";

class ModalEditDetailSKP_AcaraIlmiah extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempSKPData: JSON.parse(localStorage.getItem("skp_add_data_temp")),
      skp_detail: JSON.parse(localStorage.getItem("skp_detail")),
      modalDetailDokumenSKPShow: false,
      last_activity_skp_detail_id: "",
      values_id: "",
      berkas_sip: "",
      berkas_medis: "",
      skp: "",
      imgUrl: "",
      modal_title: "",
      waktuOptions: null,
      tingkatan: null,
      berkas_medis_button: false,
      isDoneUploading: true,
      spek_berkas_medis: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeTingkatan = this.handleChangeTingkatan.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ imgUrl: imgUrl });
    this.setState({ modal_title: modal_title });
    this.setState({ modalDetailDokumenSKPShow: true });
  }

  handleChangeTingkatan(values) {
    let listActivity_selected = "";
    if (this.state.tempSKPData !== null) {
      listActivity_selected = this.props.listActivity_data.data.find(
        (item) => item.id === this.state.tempSKPData.activity.value
      );
    } else {
      listActivity_selected = this.props.listActivity_data.data.find(
        (item) => item.id === this.state.skp_detail.activity_id
      );
    }

    let childrens_selected = listActivity_selected.childrens.find(
      (item) => item.id === values.value
    );

    // this.setState({
    //   waktuOptions: childrens_selected.values.map((item) => ({
    //     label: item.label,
    //     value: item.id,
    //   })),
    // });

    this.setState({ last_activity_skp_detail_id: childrens_selected.id });
    this.setState({ values_id: childrens_selected.values[0].id });
    let skp_must_be_multipied =
      childrens_selected.values[0].skp_must_be_multipied;
    let value_multipied = childrens_selected.values[0].value_multipied;
    if (skp_must_be_multipied === 0) {
      this.props.change("skp_total", childrens_selected.values[0].value_skp);
      this.setState({ skp: childrens_selected.values[0].value_skp });
    } else {
      this.props.change(
        "skp_total",
        +(
          this.props.formValue.total_for_calculate_skp * value_multipied
        ).toFixed(2)
      );
      this.setState({
        skp: +(
          this.props.formValue.total_for_calculate_skp * value_multipied
        ).toFixed(2),
      });
    }
  }

  handleUploadFile(values) {
    const formName = values.target.name;
    this.setState({ berkas_medis_button: true });
    this.setState({ isDoneUploading: false });
    if (formName === "berkas_medis") {
      this.setState({ berkas_medis: <Loading /> });
    }
    const callback = () => {
      this.setState({ isDoneUploading: true });
      this.setState({ berkas_medis_button: false });
      if (this.props.uploadP2kb_data) {
        if (formName === "berkas_medis") {
          this.props.change("berkas_medis", this.props.uploadP2kb_data);
          this.setState({ berkas_medis: <Loading done={true} /> });
          setTimeout(
            () =>
              this.setState({
                berkas_medis: (
                  <DetailContentLihatBerkas
                    onClick={() =>
                      this.handleOpenDocument(
                        this.props.formValue.berkas_medis,
                        "Dokumen Bukti"
                      )
                    }
                  />
                ),
              }),
            2000
          );
        }
      }
    };
    const error = () => {
      this.setState({ isDoneUploading: true });
      this.setState({ berkas_medis_button: false });
      if (this.props.uploadP2kb_error) {
        if (formName === "berkas_medis") {
          this.props.change("berkas_medis", null);
          this.setState({
            berkas_medis: <Loading done={true} failed={true} />,
          });
        }
      }
    };
    this.props.uploadP2kb(values.target.files[0], callback, error);
    this.props.change("spek_berkas_medis", values.target.files[0]);
  }

  handleSubmit(values) {
    let data = JSON.parse(
      localStorage.getItem("skp_edit_detail_skp_acara_ilmiah")
    );

    const { onHide, handleRefresh } = this.props;
    let details_tobesent = {
      activity_date: values.activity_date,
      activity_name: values.activity_name,
      title_of_paper: values.title_of_paper,
      organizer: values.organizer,
      place_of_organization: values.place_of_organization,
      city_name: values.city_name,
      total_for_calculate_skp: values.total_for_calculate_skp,
      last_activity_skp_detail_id:
        this.state.last_activity_skp_detail_id === ""
          ? this.props.formValue.tingkatan.value
          : this.state.last_activity_skp_detail_id,
      values_id: this.state.values_id,
      no: data.no,
      member_p2kb_id : data.member_p2kb_id,
      attachments: [
        {
          label: "Bukti Permintaan Sebagai Pembicara",
          filename: values.berkas_medis,
        },
      ],
    };
    let details_tobeshown = {
      ...details_tobesent,
      tingkatan: values.tingkatan.label,
      waktuPenyelenggaraan: values.waktuPenyelenggaraan.label,
      skp: values.skp_total,
      no: data.no,
      tableData: data.tableData,
    };
    details_tobesent.hasEdit = true;

    if (data.status) {
      details_tobesent.id = data.id;
      const callback = () => {
        onHide();
        handleRefresh();
      };
      this.props.editDetailP2kb(details_tobesent, callback);
    } else {
      this.props.handleEditDetail(details_tobeshown, details_tobesent);
      localStorage.removeItem("skp_edit_detail_skp_acara_ilmiah");
      // localStorage.removeItem("skp_add_data_temp");
      this.props.onHide();
    }
  }

  updateState() {
    if (this.state.last_activity_skp_detail_id === "") {
      if (
        JSON.parse(
          localStorage.getItem("skp_edit_detail_skp_acara_ilmiah") !== null
        )
      ) {
        this.setState({
          last_activity_skp_detail_id: JSON.parse(
            localStorage.getItem("skp_edit_detail_skp_acara_ilmiah")
          ).last_activity_skp_detail_id,
        });
        this.setState({
          values_id: JSON.parse(
            localStorage.getItem("skp_edit_detail_skp_acara_ilmiah")
          ).values_id,
        });
        this.setState({
          berkas_sip: (
            <DetailContentLihatBerkas
              onClick={() =>
                this.handleOpenDocument(
                  JSON.parse(
                    localStorage.getItem("skp_edit_detail_skp_acara_ilmiah")
                  ).attachments[0].filename,
                  "SIP"
                )
              }
            />
          ),
        });
        this.setState({
          berkas_medis: (
            <DetailContentLihatBerkas
              onClick={() =>
                this.handleOpenDocument(
                  JSON.parse(
                    localStorage.getItem("skp_edit_detail_skp_acara_ilmiah")
                  ).attachments[0].filename,
                  "Medis"
                )
              }
            />
          ),
        });
        this.setState({
          skp: JSON.parse(
            localStorage.getItem("skp_edit_detail_skp_acara_ilmiah")
          ).total_skp,
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    this.updateState();
  }

  componentDidMount() {
    this.props.listActivity();
  }

  render() {
    const {
      show,
      onHide,
      listActivity_data,
      handleSubmit,
      uploadP2kb_pending,
      // formValue: tingkatan,
    } = this.props;
    let tingkatanOptions = [];
    // let waktuOptions = [];
    // let total_for_calculate_skp_disabled =
    //   tingkatan.tingkatan !== undefined ? false : true;
    // let total_for_calculate_skp_placeholder =
    //   tingkatan.tingkatan !== undefined
    //     ? "Masukkan Jumlah Pasien"
    //     : "Pilih tingkatan terlebih dahulu";
    let activity_id_skp =
      this.state.tempSKPData !== null &&
      this.state.tempSKPData.activity !== null
        ? this.state.tempSKPData.activity.value
        : this.state.skp_detail.activity_id;
    activity_id_skp =
      activity_id_skp === undefined
        ? this.state.skp_detail.activity_id
        : activity_id_skp;
    if (listActivity_data) {
      let listActivity_selected = listActivity_data.data.find(
        (item) => item.id === activity_id_skp
      );
      if (listActivity_selected.childrens !== undefined) {
        tingkatanOptions = listActivity_selected?.childrens.map((item) => ({
          label: item.name,
          value: item.id,
        }));
      }
    }

    //pastikan local storage edit ada isinya
    // if (JSON.parse(localStorage.getItem("skp_edit_detail_skp_acara_ilmiah"))) {
    //   let waktu =
    //     this.state.tingkatan === null
    //       ? JSON.parse(localStorage.getItem("skp_edit_detail_skp_acara_ilmiah"))
    //           .last_activity_skp_detail_id
    //       : this.state.tingkatan;
    //   //conditional waktu diatas untuk milih antara pake initial value (local storage) atau state tingkatan (set state tingkatan sata tingkatan diubah saat edit detail skp (handlechangetingkatan))

    //   //pastikan data listactivity sudah dapet responnya
    //   if (listActivity_data) {
    //     if (waktu) {
    //       let listActivity_selected = listActivity_data.data.find(
    //         (item) => item.id === activity_id_skp
    //       );

    //       let listTingkatan_selected = listActivity_selected.childrens.find(
    //         (item) => item.id === waktu
    //       );

    //       waktuOptions = listTingkatan_selected.values.map((item) => ({
    //         label: item.label,
    //         value: item.id,
    //       }));
    //     }
    //   }
    // }

    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalDetailDokumenSKP
          show={this.state.modalDetailDokumenSKPShow}
          onHide={() => this.setState({ modalDetailDokumenSKPShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
        />
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Edit SKP</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              <>
                <Field
                  name="activity_date"
                  placeholder="Masukkan Tanggal Kegiatan"
                  label="Tanggal Kegiatan"
                  component={renderDatepickerWithLabel}
                />
                <Field
                  name="activity_name"
                  placeholder="Nama Kegiatan"
                  label="Nama Kegiatan"
                  component={renderInputWithLabel}
                />
                <Field
                  name="title_of_paper"
                  placeholder="Judul Makalah"
                  label="Judul Makalah"
                  component={renderInputWithLabel}
                />
                <Field
                  name="organizer"
                  placeholder="Penyelenggara"
                  label="Penyelenggara"
                  component={renderInputWithLabel}
                />
                <Field
                  name="place_of_organization"
                  placeholder="Tempat Penyelenggara"
                  label="Tempat Penyelenggara"
                  component={renderInputWithLabel}
                />
                <Field
                  name="city_name"
                  placeholder="Kota Penyelenggara"
                  label="Kota Penyelenggara"
                  component={renderInputWithLabel}
                />
                <Field
                  name="tingkatan"
                  placeholder="Masukkan Skala Pertemuan"
                  label="Skala Pertemuan"
                  onChange={this.handleChangeTingkatan}
                  isClearable={false}
                  options={tingkatanOptions}
                  component={renderSelectWithLabel}
                />
                <Field
                  name="skp_total"
                  placeholder="Jumlah SKP"
                  label="Jumlah SKP"
                  disable={true}
                  component={renderInputWithLabel}
                />
                <Field
                  name="berkas_medis"
                  type="file"
                  fileType="skp"
                  title="Dokumen Bukti"
                  disabled={this.state.berkas_medis_button}
                  isDone={this.state.isDoneUploading}
                  message={
                    this.props.formValue.berkas_medis === undefined
                      ? "Image atau PDF maksimal 1 MB"
                      : this.state.berkas_medis === null
                      ? "Image atau PDF maksimal 1 MB"
                      : this.state.berkas_medis
                  }
                  onChange={this.handleUploadFile}
                  component={renderFileForm}
                />
              </>
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={uploadP2kb_pending}
                className={css(styles.approveButton)}
                startIcon={
                  uploadP2kb_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const maxFileSize = 1000000; //1MB
  const typeImage = /image/;
  const fileType = "application/pdf";
  const {
    activity_date,
    activity_name,
    title_of_paper,
    organizer,
    place_of_organization,
    city_name,
    tingkatan,
    berkas_medis,
    spek_berkas_medis,
  } = values;
  const errors = {};
  if (!activity_date) {
    errors.activity_date = "Tanggal kegiatan wajib diisi";
  }
  if (!activity_name) {
    errors.activity_name = "Nama Kegiatan wajib diisi";
  }
  if (!tingkatan) {
    errors.tingkatan = "Skala Pertemuan wajib diisi";
  }
  if (!title_of_paper) {
    errors.title_of_paper = "Judul Makalah wajib diisi";
  }
  if (!organizer) {
    errors.organizer = "Penyelenggara wajib diisi";
  }
  if (!place_of_organization) {
    errors.place_of_organization = "Tempat Penyelenggara wajib diisi";
  }
  if (!city_name) {
    errors.city_name = "Kota Penyelenggara wajib diisi";
  }
  if (!berkas_medis) {
    errors.berkas_medis = "Bukti Kegiatan wajib diisi";
  } else {
    if (
      spek_berkas_medis &&
      !spek_berkas_medis.type.match(typeImage) &&
      spek_berkas_medis.type !== fileType
    ) {
      errors.berkas_medis = "Format file hanya bisa Image atau PDF";
    } else if (spek_berkas_medis && spek_berkas_medis.size > maxFileSize) {
      errors.berkas_medis = "File lebih dari 1 MB";
    }
  }
  return errors;
}

const afterSubmit = (result, dispatch) =>
  dispatch(reset("ModalAddDetailSKP_TindakanOperasi"));

ModalEditDetailSKP_AcaraIlmiah = reduxForm({
  form: "ModalEditDetailSKP_AcaraIlmiah",
  validate: validate,
  shouldError: () => true,
  onSubmitSuccess: afterSubmit,
  enableReinitialize: true,
})(ModalEditDetailSKP_AcaraIlmiah);

function mapStateToProps(state) {
  const { listActivity, uploadP2kb, selectedSKP } = state;
  const selector = formValueSelector("ModalEditDetailSKP_AcaraIlmiah");
  const formValue = selector(
    state,
    "tingkatan",
    "total_for_calculate_skp",
    "berkas_sip",
    "berkas_medis",
    "spek_berkas_medis"
  );
  const editData = JSON.parse(
    localStorage.getItem("skp_edit_detail_skp_acara_ilmiah")
  );
  let initialValues = {};

  if (editData) {
    if (!editData.isDetail) {
      initialValues = {
        activity_date: editData.activity_date,
        activity_name: editData.activity_name,
        title_of_paper: editData.title_of_paper,
        organizer: editData.organizer,
        place_of_organization: editData.place_of_organization,
        city_name: editData.city_name,
        tingkatan: {
          label: editData.tingkatan,
          value: editData.last_activity_skp_detail_id,
        },
        waktuPenyelenggaraan: {
          label: editData.waktuPenyelenggaraan,
          value: editData.values_id,
        },
        total_for_calculate_skp: parseFloat(editData.total_for_calculate_skp),
        skp_total: editData.skp,
        berkas_medis: editData.attachments[0].filename,
      };
    } else {
      if (editData.form_json) {
        let result = JSON.parse(editData.form_json);
        initialValues = {
          // id: editData.id,
          // status: editData.status,
          activity_date: editData.activity_date,
          activity_name: result.activity_name,
          title_of_paper: result.title_of_paper,
          organizer: result.organizer,
          place_of_organization: result.place_of_organization,
          city_name: result.city_name,
          tingkatan: {
            label: editData.mst_activity_skp_type_detail_data.name,
            value: editData.last_activity_skp_detail_id,
          },
          waktuPenyelenggaraan: {
            label: editData.waktuPenyelenggaraan,
            value: editData.values_id,
          },
          total_for_calculate_skp: parseFloat(editData.total_for_calculate_skp),
          skp_total: editData.total_skp,
          berkas_medis: editData.attachments[0].filename,
        };
      } else {
        initialValues = {
          // id: editData.id,
          // status: editData.status,
          activity_date: editData.activity_date,
          activity_name: editData.activity_name,
          title_of_paper: editData.title_of_paper,
          organizer: editData.organizer,
          place_of_organization: editData.place_of_organization,
          city_name: editData.city_name,
          tingkatan: {
            label: editData.mst_activity_skp_type_detail_data.name,
            value: editData.last_activity_skp_detail_id,
          },
          waktuPenyelenggaraan: {
            label: editData.waktuPenyelenggaraan,
            value: editData.values_id,
          },
          total_for_calculate_skp: parseFloat(editData.total_for_calculate_skp),
          skp_total: editData.total_skp,
          berkas_medis: editData.attachments[0].filename,
        };
      }
    }
  }
  return {
    listActivity_pending: getActivityPending(listActivity),
    listActivity_data: getListActivity(listActivity),
    listActivity_error: getActivityError(listActivity),
    uploadP2kb_pending: getP2kbUploadPending(uploadP2kb),
    uploadP2kb_data: getP2kbUpload(uploadP2kb),
    uploadP2kb_error: getP2kbUploadError(uploadP2kb),
    formValue,
    selectedSKP,
    initialValues,
  };
}

export default connect(mapStateToProps, {
  listActivity,
  uploadP2kb,
  editDetailP2kb,
})(withRouter(ModalEditDetailSKP_AcaraIlmiah));
