import axios from "axios";

export default axios.create({
  // baseURL: "http://agrico-api.bigio.id/api",
  // baseURL: window.location.href.includes('dev') || window.location.href.includes('localhost') ? "https://dev.api.paboi.bigio.id/v1" : "https://api.paboi.bigio.id/v1"
  // baseURL:
  //   window.location.href.includes("dev") ||
  //   window.location.href.includes("localhost")
  //     ? "http://localhost:9000/v1"
  //     : "https://api.paboi.bigio.id/v1",

  baseURL:
    window.location.href.includes("dev") ||
    window.location.href.includes("localhost")
      ? "https://dev.api.paboi.bigio.id/v1"
      : "https://api.paboi.bigio.id/v1",
});
