import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import { Column, Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import MenuItem from "@material-ui/core/MenuItem";
import { memberList } from "../../actions/member/member_action";
import {
  getMemberListPending,
  getMemberList,
  getMemberListError,
} from "../../reducers/member/member_reducer";
import { connect } from "react-redux";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import MaterialTable from "material-table";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import { Paper } from "@material-ui/core";
import { p2kbList } from "../../actions/p2kb/p2kb_action";
import {
  getP2kbListPending,
  getP2kbList,
  getP2kbListError,
} from "../../reducers/p2kb/p2kb_reducer";
import HeaderDetailP2KB from "./Header_list_skp_member";
import HeaderListP2kbComponent from "../../components/p2kb/HeaderAddDetailP2kbComponent";
import PaginationComponent from "../../components/table/PaginationComponent";
import moment from "moment";
import localization from "moment/locale/id";

class DetailMemberP2KB extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      search: "",
      status: "",
      activity_type: "",
      id: null,
      isFirstLayer: true,
      start_date: localStorage.getItem("start_date")
        ? localStorage.getItem("start_date")
        : "",
      end_date: localStorage.getItem("end_date")
        ? localStorage.getItem("end_date")
        : "",
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleApprovalP2kb = this.handleApprovalP2kb.bind(this);
  }

  handleApprovalP2kb(data) {
    const tindakanOperasi = [
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dc",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dd",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363de",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363df",
    ];
    const pelayananMedis = [
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dg",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dh",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363di",
    ];
    const pertemuanAuditor = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dl"];
    const membacaJurnal = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dn"];
    const penelusuranInformasi = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363do"];
    const acaraIlmiah = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dj"];
    const seminar = [
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dp",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dq",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dr",
    ];
    const kajianIlmiah = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dk"];
    const menghadiriKongres = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ds"];
    const edukasiPasien = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dm"];
    const menulisIlmiah = [
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dw",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dx",
    ];
    const menulisBuku = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dy"];
    const mengikutiPelatihan = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dt"];
    const membimbingMahasiswa = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363eg"];
    const mengajarMahasiswa = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ee"];
    const kepengurusanProfesi = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dv"];
    const pengabdianMasyarakat = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363du"];
    const penelitianDipublikasikan = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ec"];
    const penyeliaSupervisor = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ed"];
    const wawancaraKajianElektronik = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dz"];
    const menciptakanAlat = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363eb"];
    const praktekMahasiswa = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ef"];
    const membuatVideo = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ea"];
    const mengujiMahasiswa = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363eh"];

    if (tindakanOperasi.includes(data.activity_data.id)) {
      this.props.history.push(
        `/admin-p2kb/tindakan-operasi/${data.activity_data.id}`
      );
    }
    if (seminar.includes(data.activity_data.id)) {
      this.props.history.push(`/admin-p2kb/seminar/${data.activity_data.id}`);
    }
    if (pelayananMedis.includes(data.activity_data.id)) {
      this.props.history.push(
        `/admin-p2kb/pelayanan-medis/${data.activity_data.id}`
      );
    }
    if (pertemuanAuditor.includes(data.activity_data.id)) {
      this.props.history.push(
        `/admin-p2kb/pertemuan-auditor/${data.activity_data.id}`
      );
    }
    if (membacaJurnal.includes(data.activity_data.id)) {
      this.props.history.push(
        `/admin-p2kb/membaca-jurnal/${data.activity_data.id}`
      );
    }
    if (penelusuranInformasi.includes(data.activity_data.id)) {
      this.props.history.push(
        `/admin-p2kb/penelusuran-informasi/${data.activity_data.id}`
      );
    }
    if (acaraIlmiah.includes(data.activity_data.id)) {
      this.props.history.push(
        `/admin-p2kb/acara-ilmiah/${data.activity_data.id}`
      );
    }
    if (kajianIlmiah.includes(data.activity_data.id)) {
      this.props.history.push(
        `/admin-p2kb/kinerja-ilmiah/${data.activity_data.id}`
      );
    }
    if (menghadiriKongres.includes(data.activity_data.id)) {
      this.props.history.push(
        `/admin-p2kb/menghadiri-kongres/${data.activity_data.id}`
      );
    }
    if (praktekMahasiswa.includes(data.activity_data.id)) {
      this.props.history.push(
        `/admin-p2kb/praktek-mahasiswa/${data.activity_data.id}`
      );
    }
    if (edukasiPasien.includes(data.activity_data.id)) {
      this.props.history.push(
        `/admin-p2kb/edukasi-pasien/${data.activity_data.id}/${data.activity_data.id}`
      );
    }
    if (menulisIlmiah.includes(data.activity_data.id)) {
      this.props.history.push(
        `/admin-p2kb/menulis-ilmiah/${data.activity_data.id}/${data.activity_data.id}`
      );
    }
    if (menulisBuku.includes(data.activity_data.id)) {
      this.props.history.push(
        `/admin-p2kb/menulis-buku/${data.activity_data.id}/${data.activity_data.id}`
      );
    }
    if (membimbingMahasiswa.includes(data.activity_data.id)) {
      this.props.history.push(
        `/admin-p2kb/membimbing-mahasiswa/${data.activity_data.id}/${data.activity_data.id}`
      );
    }
    if (mengajarMahasiswa.includes(data.activity_data.id)) {
      this.props.history.push(
        `/admin-p2kb/mengajar-mahasiswa/${data.activity_data.id}/${data.activity_data.id}`
      );
    }
    if (mengikutiPelatihan.includes(data.activity_data.id)) {
      this.props.history.push(`/admin-p2kb/pelatihan/${data.activity_data.id}`);
    }
    if (kepengurusanProfesi.includes(data.activity_data.id)) {
      this.props.history.push(
        `/admin-p2kb/kepengurusan-profesi/${data.activity_data.id}`
      );
    }
    if (pengabdianMasyarakat.includes(data.activity_data.id)) {
      this.props.history.push(
        `/admin-p2kb/pengabdian-masyarakat/${data.activity_data.id}`
      );
    }
    if (penelitianDipublikasikan.includes(data.activity_data.id)) {
      this.props.history.push(
        `/admin-p2kb/penelitian-dipublikasikan/${data.activity_data.id}`
      );
    }
    if (penyeliaSupervisor.includes(data.activity_data.id)) {
      this.props.history.push(
        `/admin-p2kb/penyelia-supervisor/${data.activity_data.id}`
      );
    }
    if (wawancaraKajianElektronik.includes(data.activity_data.id)) {
      this.props.history.push(
        `/admin-p2kb/kajian-elektronik/${data.activity_data.id}`
      );
    }
    if (menciptakanAlat.includes(data.activity_data.id)) {
      this.props.history.push(
        `/admin-p2kb/menciptakan-alat/${data.activity_data.id}`
      );
    }
    if (membuatVideo.includes(data.activity_data.id)) {
      this.props.history.push(
        `/admin-p2kb/membuat-video/${data.activity_data.id}`
      );
    }
    if (mengujiMahasiswa.includes(data.activity_data.id)) {
      this.props.history.push(
        `/admin-p2kb/menguji-mahasiswa/${data.activity_data.id}`
      );
    }
  }

  handleRefresh(state) {
    this.setState(state);
    this.setState({ id: null });
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  componentDidMount() {
    let param_memberList = {
      page: 1,
      length: 1,
      search_text: "",
      id: "",
    };
    const {
      match: {
        params: { id, skpid },
      },
    } = this.props;

    param_memberList.id = id;
    this.props.memberList(param_memberList);
    if (this.props.match.params.skpid && this.state.id === null) {
      this.setState({ id: skpid });
    }
    localStorage.setItem("user_id_skp", id);
    let param2 = {
      isFirstLayer: false,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      user_id: id,
      realm_activity_id: JSON.parse(localStorage.getItem("skp_data_admin")).id,
    };
    new Promise((callback) => {
      this.props.p2kbList(param2, callback);
    });
  }

  shouldComponentRender() {
    const { memberList_pending } = this.props;
    if (memberList_pending === false) {
      return false;
    }
    return true;
  }

  render() {
    const { memberList_data, p2kbList_data, p2kbList, match } = this.props;
    const {
      match: {
        params: { serkom_id },
      },
    } = this.props;
    let user_id;
    let isAdminSKP = false;
    let isSerkom = false;
    if (match && match.params.id) {
      user_id = match.params.id;
      isAdminSKP = match.path.includes("admin-p2kb");
      isSerkom = match.path.includes("serkom");
    }
    let data = [];
    let outstanding_skp = 0;
    let current_skp = 0;
    let total_skp_header = 0;
    if (memberList_data && memberList_data.length > 0) {
      data = memberList_data.data[0];
      current_skp = data.member_profile.total_skp;
      outstanding_skp = data.member_profile.outstanding_skp;
    }
    let dataP2kb = [];
    let records_total = 0;
    let length = 0;
    if (p2kbList_data) {
      dataP2kb = p2kbList_data;
      records_total = dataP2kb.records_total;
      length = dataP2kb.data.length;
      p2kbList_data.data.forEach((item, index) => {
        total_skp_header += item.total_skp;
      });
    }
    let dataAdmin = JSON.parse(localStorage.getItem("skp_data_admin"));
    let ranah = dataAdmin.name;
    let total_skp = 0;
    return (
      <ContentContainer>
        <Row className={css(styles.adminP2kbContainer)}>
          <Column className={css(styles.adminP2kbColumn)}>
            <HeaderListP2kbComponent
              current_skp={current_skp}
              ranah={ranah}
              total_skp_header={total_skp_header.toFixed(2)}
              outstanding_skp={outstanding_skp}
            />
            <HeaderDetailP2KB
              handleRefresh={this.handleRefresh}
              user_id={user_id}
              isFirstLayer={false}
              isAdminSKP={isAdminSKP}
              isSerkom={isSerkom}
            />
            <MaterialTable
              tableRef={this.tableRef}
              title="P2kb"
              columns={[
                {
                  title: "No",
                  field: "name",
                  render: (rowData) => {
                    return <>{rowData.no}</>;
                  },
                  width: 10,
                  headerStyle: {
                    paddingLeft: 40,
                  },
                  cellStyle: {
                    paddingLeft: 40,
                  },
                },
                { title: "Nama Kegiatan", field: "activity_data.name" },
                { title: "Jumlah SKP", 
                  // field: "total_skp",
                  render: (rowData) => {
                    return rowData.total_skp.toFixed(2);
                }
              },
                {
                  title: "",
                  render: (data) => {
                    return (
                      <DetailButtonComponent>
                        <MenuItem onClick={() => this.handleApprovalP2kb(data)}>
                          Detail
                        </MenuItem>
                      </DetailButtonComponent>
                    );
                  },
                },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let param = {
                    page: query.page + 1,
                    length: 10,
                    search_text: this.state.search,
                    status: this.state.status,
                    activity_type: this.state.activity_type,
                    id: this.state.id,
                    user_id: this.props.match.params.id,
                    serkom_id,
                    start_date: this.state.start_date,
                    end_date: this.state.end_date,
                    serkom_id: localStorage.getItem("isSerkom")
                      ? localStorage.getItem("id_serkom")
                      : "",
                    realm_activity_id: JSON.parse(
                      localStorage.getItem("skp_data_admin")
                    ).id,
                  };
                  p2kbList(param, resolve, reject);
                })
              }
              options={{
                paginationType: "stepped",
                pageSize: 10,
                pageSizeOptions: [],
                showTitle: false,
                search: false,
                headerStyle: {
                  backgroundColor: "#FFFFFF",
                  zIndex: 0,
                },
              }}
              components={{
                Toolbar: () => <div />,
                Container: (props) => <Paper {...props} elevation={0} />,
                Pagination: (props) => (
                  <PaginationComponent
                    records={length}
                    records_total={records_total}
                    {...props}
                  />
                ),
              }}
            />
          </Column>
        </Row>
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  adminP2kbContainer: {
    width: "100%",
  },
  adminP2kbColumn: {
    width: "100%",
  },
  leftDetailColumn: {
    justifyContent: "center",
    alignItems: "center",
  },
  detailMemberStatusNonactiveID: {
    padding: "10px 20px",
    border: "2px solid #ff3232",
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  detailMemberStatusActiveID: {
    padding: "10px 20px",
    border: "2px solid #2CB96A",
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  detailMemberStatusPensionID: {
    padding: "10px 20px",
    border: "2px solid #ff8c32",
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  detailMemberStatusDepartedID: {
    padding: "10px 20px",
    border: "2px solid #727272",
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  detailMemberStatusNonactiveButton: {
    padding: "10px 20px",
    backgroundColor: "#ff3232",
    border: "2px solid #ff3232",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    height: "100%",
    alignItems: "center",
    ":hover": {
      cursor: "pointer",
    },
  },
  detailMemberStatusActiveButton: {
    padding: "10px 20px",
    backgroundColor: "#2CB96A",
    border: "2px solid #2CB96A",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    height: "100%",
    alignItems: "center",
    ":hover": {
      cursor: "pointer",
    },
  },
  detailMemberStatusPensionButton: {
    padding: "10px 20px",
    backgroundColor: "#ff8c32",
    border: "2px solid #ff8c32",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    height: "100%",
    alignItems: "center",
    ":hover": {
      cursor: "pointer",
    },
  },
  detailMemberStatusDepartedButton: {
    padding: "10px 20px",
    backgroundColor: "#727272",
    border: "2px solid #727272",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    height: "100%",
    alignItems: "center",
    ":hover": {
      cursor: "pointer",
    },
  },
  detailMemberStatusNonactiveIDFont: {
    color: "#ff3232",
  },
  detailMemberStatusActiveIDFont: {
    color: "#2CB96A",
  },
  detailMemberStatusPensionIDFont: {
    color: "#ff8c32",
  },
  detailMemberStatusDepartedIDFont: {
    color: "#727272",
  },
  detailMemberStatusButtonFont: {
    color: "#FFFFFF",
    marginRight: 20,
  },
  changeStatusButton: {
    padding: 0,
    fontSize: 14,
  },
  clickableIcon: {
    ":hover": {
      cursor: "pointer",
    },
  },
});

function mapStateToProps({ memberList, p2kbList }) {
  return {
    memberList_pending: getMemberListPending(memberList),
    memberList_data: getMemberList(memberList),
    memberList_error: getMemberListError(memberList),
    p2kbList_pending: getP2kbListPending(p2kbList),
    p2kbList_data: getP2kbList(p2kbList),
    p2kbList_error: getP2kbListError(p2kbList),
  };
}

export default connect(mapStateToProps, { memberList, p2kbList })(
  withRouter(DetailMemberP2KB)
);
