import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Placeholder from "../../components/p2kb/skp_placeholder";
import FadeIn from "react-fade-in";
import IconRefresh from "../../assets/icon-refresh";
import moment from "moment";
import localization from "moment/locale/id";
import { listKoperasi } from "../../actions/koperasi/koperasi_action";
import {
  getListKoperasiPending,
  getListKoperasi,
  getListKoperasiError,
} from "../../reducers/koperasi/koperasi_reducer";
import { listMembership } from "../../actions/koperasi/membership_action";
import {
  getListMembership,
  getMembershipPending,
} from "../../reducers/koperasi/membership_reducer";
import SelectComponent from "../../components/form/SelectComponent";
import { getUser } from "../../helpers/user";

class HeaderIuranMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      status: "",
      isLoad: false,
      tab_active: 1,
    };
    this.handleYearSelectChange = this.handleYearSelectChange.bind(this);
    this.handleSync = this.handleSync.bind(this);
    this.handleTable = this.handleTable.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }
  handleDateChange(e) {
    if (e) {
      this.setState({ year: e.value });
      this.props.handleRefresh({ year: e.value });
    } else {
      this.setState({ year: null });
      this.props.handleRefresh({ year: null });
    }
  }

  handleYearSelectChange(e) {
    if (e) {
      this.props.handleRefresh({
        year: e.value,
      });
    } else {
      this.props.handleRefresh({
        year: null,
      });
    }
  }

  handleSync() {
    this.setState({ isLoad: true });
    const callback = () => {
      this.setState({ isLoad: false });
      this.props.handleRefresh();
    };
    this.props.listKoperasi(
      { length: 10, user_id: this.props.match?.params?.id },
      callback
    );
  }

  handleTable(tab) {
    let tab_active = tab;
    if (tab_active == 1) {
      this.setState({ tab_active: 1 });
    } else {
      this.setState({ tab_active: 2 });
    }
    this.props.active_tab({
      tab_active: tab_active,
    });
  }

  render() {
    const { listKoperasi_data } = this.props;
    let user = getUser();
    let username = user.name;
    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    let lastUpdate;
    // let idKomposit = listKoperasi_data.id;

    for (let i = currentYear; i >= 2000; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }
    let totalRequiredDeposit,
      totalMainDeposit,
      idKompotis,
      shu = "";
    if (listKoperasi_data) {
      lastUpdate = listKoperasi_data.latest_update;
      totalRequiredDeposit = listKoperasi_data.total_cooperation
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      totalMainDeposit = listKoperasi_data.total_membership
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      shu = listKoperasi_data.total_shu
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      idKompotis = listKoperasi_data.id_kompotis;
    }
    

    return (
      <Column>
        <Column>
          <div className={css(styles.detailHeaderContainer)}>
            <Row>
              <Column className={css(styles.detailHeaderUser)}>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ lineHeight: 2 }}>Nama</td>
                      <td>:</td>
                      <td>{username}</td>
                    </tr>
                    <tr>
                      <td>ID KOMPOTIS</td>
                      <td>:</td>
                      <td>{idKompotis}</td>
                    </tr>
                  </tbody>
                </table>
              </Column>
              <Column
                className={
                  css(styles.detailHeaderBenefitColumn) +
                  " " +
                  css(styles.NavTab) +
                  " " +
                  (this.state.tab_active === 1 && css(styles.activeNavTab))
                }
                onClick={() => this.handleTable("1")}
              >
                <div>
                  <div className={css(styles.detailHeaderTitle)}>
                    Simpanan Wajib
                  </div>
                  <div className={css(styles.detailHeaderValue)}>
                    <span className={css(styles.totalSHUValue)}>
                      Rp {totalRequiredDeposit}
                    </span>
                  </div>
                </div>
              </Column>
              <Column className={css(styles.detailHeaderBenefitColumn)}>
                <div>
                  <div className={css(styles.detailHeaderTitle)}>
                    Simpanan Pokok
                  </div>
                  <div className={css(styles.detailHeaderValue)}>
                    <span className={css(styles.totalSHUValue)}>
                      Rp {totalMainDeposit}
                    </span>
                  </div>
                </div>
              </Column>
              <Column
                className={
                  css(styles.detailHeaderBenefitColumn) +
                  " " +
                  css(styles.NavTab) +
                  " " +
                  (this.state.tab_active === 2 && css(styles.activeNavTab))
                }
                onClick={() => this.handleTable("2")}
              >
                <div>
                  <div className={css(styles.detailHeaderTitle)}>SHU</div>
                  <div className={css(styles.detailHeaderValue)}>
                    <span className={css(styles.totalSHUValue)}>Rp {shu}</span>
                  </div>
                </div>
              </Column>
            </Row>
          </div>
        </Column>
        <Row
          style={{ padding: 24, paddingBottom: 30, backgroundColor: "#F8F9FB" }}
          horizontal={"space-between"}
        >
          {/* {user === "root" &&
                        <Row>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={css(styles.approveButton)}
                                startIcon={<AddIcon />}
                                onClick={() => this.setState({ modalCheckSHUShow: true })}
                            >
                                tambah tagihan
                            </Button>
                        </Row>
                    } */}
          <Row>
            <Button
              variant="contained"
              color="primary"
              className={css(styles.refreshButton)}
              startIcon={<IconRefresh />}
              onClick={this.handleSync}
            >
              Refresh
            </Button>
            <div className={css(styles.syncDiv)}>
              {this.state.isLoad || lastUpdate === null ? (
                <Placeholder childwidth={200} parentwidth={200} title="" />
              ) : (
                <FadeIn>
                  <label className={css(styles.syncLabel)}>
                    {moment(lastUpdate)
                      .locale("id", localization)
                      .format("dddd, DD MMMM YYYY HH:mm")}
                  </label>
                </FadeIn>
              )}
            </div>
          </Row>
          <Row>
            <div className={css(styles.container)}>
              <SelectComponent
                placeholder="Pilih Tahun"
                options={yearOptions}
                onChange={this.handleDateChange}
              />
            </div>
          </Row>
        </Row>
      </Column>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    width: 150,
    marginLeft: 10,
    zIndex: 999,
  },
  refreshButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  syncDiv: {
    paddingTop: 5,
  },
  syncLabel: {
    paddingTop: 7,
  },
  detailHeaderContainer: {
    backgroundColor: "#EFF0F2",
  },
  detailHeaderBenefitColumn: {
    padding: "10px",
    margin: "20px 20px 20px 30px",
    width: "15%",
    borderRadius: "10px",
    right: 0,
  },
  detailHeaderUser: {
    padding: "10px",
    margin: "20px 20px 20px 30px",
    width: "40%",
    fontSize: "16px",
  },
  detailHeaderTitle: {
    color: "#495057",
    opacity: 0.7,
    fontSize: 14,
    marginBottom: 20,
  },
  detailHeaderValue: {
    color: "Black",
    fontSize: 16,
  },
  detailUser: {
    color: "Black",
    fontSize: 16,
    marginBottom: 20,
  },
  detailValue: {
    position: "relative",
    marginLeft: 10,
  },
  // detailTabelUser:{
  //   marginRight:100,
  // },
  totalSHUValue: {
    color: "#43C57C",
  },
  NavTab: {
    cursor: "pointer",
    color: "#2CB96A",
    backgroundColor: "rgba(124,197,163,.1)",
    "&:hover": {
      color: "#2CB96A",
      backgroundColor: "#ECFFF4",
    },
  },
  activeNavTab: {
    backgroundColor: "rgba(124,197,163,.4)",
  },
  detailHeaderUser: {
    padding: "10px",
    margin: "20px 20px 20px 30px",
    width: "40%",
    fontSize: "16px",
  },
});

function mapStateToProps(state) {
  const { listKoperasi, listMembership, tab_active } = state;
  return {
    listKoperasi_pending: getListKoperasiPending(listKoperasi),
    listKoperasi_data: getListKoperasi(listKoperasi),
    listKoperasi_error: getListKoperasiError(listKoperasi),
    listMembership_data: getListMembership(listMembership),
    listMembership_pending: getMembershipPending(listMembership),
    tab_active: tab_active,
  };
}

export default connect(mapStateToProps, { listKoperasi, listMembership })(
  HeaderIuranMember
);
