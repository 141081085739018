import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { listSip, detailSip } from "../../actions/p2kb/sip_action";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import ModalSipAdd from "./ModalSipAdd";
import ModalSipEdit from "./ModalSipEdit";
import {
  getSipPending,
  getListSip,
  getSipError,
} from "../../reducers/p2kb/sip_reducer";
import HeaderSip from "./HeaderSip";
import { getUser } from "../../helpers/user";
import moment from "moment";
import localization from "moment/locale/id";
class ListSip extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    if (getUser().role_codes[0] === "member") {
      this.state = {
        user_id: getUser().id,
        search: "",
        status: null,
        id: null,
      };
    }
    this.detailRecord = this.detailRecord.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleOpenModalAdd = this.handleOpenModalAdd.bind(this);
    this.handleOpenModalEdit = this.handleOpenModalEdit.bind(this);
  }

  handleRefresh(state) {
    this.setState(state);
    this.setState({ id: null });
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  handleOpenModalAdd(id) {
    this.setState({ id, modalAddShow: true });
  }

  handleOpenModalDelete(id) {
    this.setState({ id, modalDeleteShow: true });
  }

  handleOpenModalEdit(id, isEdit = false) {
    const { detailSip } = this.props;
    detailSip(id);
    this.setState({ id, isEdit });
    this.setState({ modalEditShow: true });
  }

  detailRecord(id) {
    this.props.history.push(`/member/sip/detail/${id}`);
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    if (id && this.state.id === null) {
      this.setState({ id: id });
    }
  }

  render() {
    let data = [];
    let records_total = 0;
    const { listSip, listSip_data } = this.props;
    if (listSip_data) {
      data = listSip_data.data;
      records_total = listSip_data.records_total;
    }

    const { id, isEdit, modalEditShow, modalAddShow } = this.state;
    return (
      <ContentContainer>
        <ModalSipAdd
          id={id}
          show={modalAddShow}
          onHide={() => this.setState({ modalAddShow: false })}
          handleRefresh={this.handleRefresh}
        />
        <ModalSipEdit
          id={id}
          show={modalEditShow}
          onHide={() => this.setState({ modalEditShow: false })}
          handleRefresh={this.handleRefresh}
          isEdit={isEdit}
        />
        {/* <ModalPracticeDelete
          id={id}
          show={modalDeleteShow}
          onHide={() => this.setState({ modalDeleteShow: false })}
          handleRefresh={this.handleRefresh}
        /> */}
        <HeaderSip
          handleRefresh={this.handleRefresh}
          handleOpenModalAdd={this.handleOpenModalAdd}
        />

        <MaterialTable
          tableRef={this.tableRef}
          title="Simple Action Preview"
          columns={[
            {
              title: "No",
              field: "name",
              render: (rowData) => {
                return <>{rowData.no}</>;
              },
              width: 20,
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },
            {
              title: "Nomor SIP",
              field: "sip_no",
            },
            {
              title: "Tanggal penetapan",
              render: ({ start_date }) => {
                return start_date
                  ? moment(start_date)
                      .locale("id", localization)
                      .format("DD MMMM YYYY")
                  : "-";
              },
            },
            {
              title: "Tanggal berakhir",
              render: ({ end_date }) => {
                return end_date
                  ? moment(end_date)
                      .locale("id", localization)
                      .format("DD MMMM YYYY")
                  : "-";
              },
            },
            {
              title: "Status",
              field: "status",
              render: ({ status }) => {
                return (
                  <TableStatusComponent
                    status={
                      status === 0
                        ? "Baru"
                        : status === 1
                        ? "Terverifikasi"
                        : "Ditolak"
                    }
                  />
                );
              },
            },
            {
              title: "Status SIP",
              field: "sip_type",
              render: ({ sip_type }) => {
                return (
                  <TableStatusComponent
                    status={
                      sip_type === 0
                        ? "Nonaktif"
                        : sip_type === 1
                        ? "Aktif"
                        : "Nonaktif"
                    }
                    jenisSIP="true"
                  />
                );
              },
            },
            {
              title: "",
              render: ({ id }) => (
                <DetailButtonComponent>
                  <MenuItem onClick={() => this.detailRecord(id)}>
                    Detail
                  </MenuItem>
                </DetailButtonComponent>
              ),
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: this.state.search,
                user_id: this.state.user_id,
                status: this.state.status,
                id: this.state.id,
              };
              listSip(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            headerStyle: {
              backgroundColor: "#F8F9FB",
              zIndex: 0,
              // width: 10,
              // paddingLeft: "40px",
              // paddingRight: "40px",
            }, //change header padding
            // cellStyle: { paddingLeft: "40px", paddingRight: "40px" },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <td style={{ width: "100%", display: "block" }}>
                <Row
                  // vertical="center"
                  horizontal="space-between"
                  style={{
                    paddingRight: 20,
                    paddingLeft: 20,
                    // background: "red",
                  }}
                >
                  <Row
                    style={{
                      // backgroundColor: "red",
                      width: "50%",
                    }}
                    vertical="center"
                  >
                    <p>
                      Menampilkan {data.length} dari {records_total} data
                    </p>
                  </Row>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <TablePagination {...props} />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Row>
              </td>
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ listSip }) {
  return {
    listSip_data: getListSip(listSip),
    listSip_error: getSipError(listSip),
    listSip_pending: getSipPending(listSip),
  };
}

export default connect(mapStateToProps, {
  listSip,
  detailSip,
})(withRouter(ListSip));
