import {
  LIST_SERKOM_PENDING,
  LIST_SERKOM_SUCCESS,
  LIST_SERKOM_ERROR,
  DETAIL_SERKOM_PENDING,
  DETAIL_SERKOM_SUCCESS,
  DETAIL_SERKOM_ERROR,
  EDIT_SERKOM_PENDING,
  EDIT_SERKOM_SUCCESS,
  EDIT_SERKOM_ERROR,
  DELETE_SERKOM_PENDING,
  DELETE_SERKOM_SUCCESS,
  DELETE_SERKOM_ERROR,
  ADD_SERKOM_PENDING,
  ADD_SERKOM_SUCCESS,
  ADD_SERKOM_ERROR,
  APPROVE_SERKOM_PENDING,
  APPROVE_SERKOM_SUCCESS,
  APPROVE_SERKOM_ERROR,
  SET_IS_EDIT_FILE,
  MEMBER_SERKOM_PENDING,
  MEMBER_SERKOM_SUCCESS,
  MEMBER_SERKOM_ERROR,
} from "../../actions/serkom/serkom_action";

const initialState = {
  pending: false,
  error: null,
};

export function listSerkom(
  state = { ...initialState, list_serkom: null },
  action
) {
  switch (action.type) {
    case LIST_SERKOM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_SERKOM_SUCCESS:
      return {
        ...state,
        pending: false,
        list_serkom: action.data,
      };
    case LIST_SERKOM_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function detailSerkom(
  state = { ...initialState, detail_serkom: null },
  action
) {
  switch (action.type) {
    case DETAIL_SERKOM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_SERKOM_SUCCESS:
      return {
        ...state,
        pending: false,
        detail_serkom: action.data,
      };
    case DETAIL_SERKOM_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function editSerkom(
  state = { ...initialState, isEditFile: false },
  action
) {
  switch (action.type) {
    case EDIT_SERKOM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_SERKOM_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case EDIT_SERKOM_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case SET_IS_EDIT_FILE:
      return {
        ...state,
        isEditFile: action.value,
      };
    default:
      return state;
  }
}

export function addSerkom(state = initialState, action) {
  switch (action.type) {
    case ADD_SERKOM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case ADD_SERKOM_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_SERKOM_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function deleteSerkom(state = initialState, action) {
  switch (action.type) {
    case APPROVE_SERKOM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case APPROVE_SERKOM_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case APPROVE_SERKOM_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function approveSerkom(state = initialState, action) {
  switch (action.type) {
    case DELETE_SERKOM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_SERKOM_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case DELETE_SERKOM_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function memberSerkom(
  state = { ...initialState, memberSerkom: null },
  action
) {
  switch (action.type) {
    case MEMBER_SERKOM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case MEMBER_SERKOM_SUCCESS:
      return {
        ...state,
        pending: false,
        memberSerkom: action.data,
      };
    case MEMBER_SERKOM_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getListSerkom = (state) => state.list_serkom;
export const getDetailSerkom = (state) => state.detail_serkom;
export const getSerkomPending = (state) => state.pending;
export const getSerkomError = (state) => state.error;
export const getSerkomIsEditFile = (state) => state.isEditFile;
export const getMemberSerkomPending = (state) => state.pending;
export const getMemberSerkom = (state) => state.memberSerkom;
export const getMemberSerkomError = (state) => state.error;
