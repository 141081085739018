import React, { Component } from "react";
import { connect } from "react-redux";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import SelectComponent from "../../components/form/SelectComponent";
import { StyleSheet, css } from "aphrodite";
import SearchIcon from "@material-ui/icons/Search";
import { branchList } from "../../actions/master-data/branch_action";
import {
  getBranchListPending,
  getBranchList,
  getBranchListError,
} from "../../reducers/master-data/branch_reducer";
import { memberList } from "../../actions/member/member_action";
import {
  getMemberListPending,
  getMemberList,
  getMemberListError,
} from "../../reducers/member/member_reducer";
import { getUser } from "../../helpers/user";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { default_api } from "../../constants/default_api";
const styles = StyleSheet.create({
  container: {
    width: 200,
    margin: 10,
    zIndex: 99,
  },
  searchContainer: {
    width: 200,
    margin: 10,
  },
});
class HeaderMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nama: "",
      branch: "",
      status: "",
    };
    this.handleChangeNama = this.handleChangeNama.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleChangeBranch = this.handleChangeBranch.bind(this);
  }

  handleChangeNama(e) {
    if (e) {
      this.setState({ nama: e.target.value });
    }
    let param_memberList = {
      page: 1,
      length: 10,
      search_text: e.target.value,
      id: "",
      branch: this.state.branch,
      status: this.state.status,
    };
    this.props.memberList(param_memberList);
    this.props.handleRefresh({ search: e.target.value });
  }

  handleChangeBranch(e) {
    if (e !== null) {
      this.props.handleRefresh({ branch_id: e.value });
    } else {
      this.props.handleRefresh({ branch_id: null });
    }
  }

  handleChangeStatus(e) {
    if (e !== null) {
      this.props.handleRefresh({ status: e.value });
    } else {
      this.props.handleRefresh({ status: null });
    }

    let param_memberList = {
      search_text: this.state.nama,
      id: "",
      branch: this.state.branch,
      status: e !== null ? e.value : null,
    };
    this.props.memberList(param_memberList);
  }

  handleDownload() {
    window.open(default_api + "/member/download/member_data.xlsx");
  }

  componentDidMount() {
    let param_branchList = {
      page: 1,
      length: 999,
      search_text: "",
      id: "",
    };
    this.props.branchList(param_branchList);
  }

  render() {
    const { branchList_data } = this.props;
    const role_code = getUser().role_codes[0];
    let branchOption = [];
    let statusOption = [
      { value: 0, label: "Tidak Aktif" },
      { value: 1, label: "Aktif" },
      { value: 2, label: "Pensiun" },
      { value: 3, label: "Wafat" },
    ];
    if (branchList_data) {
      let data = branchList_data;
      data.map((value, index) => {
        const branch = { value: value.id, label: value.name };
        branchOption = [...branchOption, branch];
        return branch;
      });
    }
    return (
      <Row
        style={{ padding: 24, paddingBottom: 30, backgroundColor: "#F8F9FB" }}
        horizontal="space-between"
      >
        <div>
          {getUser().role_codes[0] === "root" && (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={css(styles.approveButton)}
              // startIcon={<AddIcon />}
              onClick={this.handleDownload}
            >
              Export Member PABOI
            </Button>
          )}
        </div>
        <Row>
          <div className={css(styles.searchContainer)}>
            <InputComponent
              onChange={this.handleChangeNama}
              value={this.state.nama}
              placeholder="Cari Nama, ID PABOI"
              startIcon={SearchIcon}
              id="nama"
            />
          </div>
          {role_code !== "admin_paboi_cabang" &&
          role_code !== "ketua_paboi_cabang" ? (
            <div className={css(styles.container)}>
              <SelectComponent
                onChange={this.handleChangeBranch}
                placeholder="Cabang"
                options={branchOption}
                id="cabang"
              />
            </div>
          ) : (
            ""
          )}

          <div className={css(styles.container)}>
            <SelectComponent
              onChange={this.handleChangeStatus}
              placeholder="Status"
              options={statusOption}
              id="status"
            />
          </div>
        </Row>
      </Row>
    );
  }
}

function mapStateToProps({ branchList, memberList }) {
  return {
    branchList_pending: getBranchListPending(branchList),
    branchList_data: getBranchList(branchList),
    branchList_error: getBranchListError(branchList),
    memberList_pending: getMemberListPending(memberList),
    memberList_data: getMemberList(memberList),
    memberList_error: getMemberListError(memberList),
  };
}

export default connect(mapStateToProps, { branchList, memberList })(
  HeaderMember
);
