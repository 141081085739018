import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, reset } from "redux-form";
import {
    renderDatepickerWithLabel, renderInputWithLabel, renderFileForm
} from "../../../components/form/FormComponent";
import { listActivity } from "../../../actions/master-data/activity_action";
import { getListActivity, getActivityPending, getActivityError } from "../../../reducers/master-data/activity_reducer";
import { uploadP2kb, editDetailP2kb } from "../../../actions/p2kb/p2kb_action";
import {
    getP2kbUploadPending,
    getP2kbUpload,
    getP2kbUploadError,
    getP2kbEditDetailPending,
    getP2kbEditDetail,
    getP2kbEditDetailError,
} from "../../../reducers/p2kb/p2kb_reducer";
import DetailContentLihatBerkas from "../../../components/detail/DetailContentLihatBerkas";
import ModalDetailDokumenSKP from "../Modal_document_pdf";
import ModalDetailDokumen from "../Modal_document";
import Loading from "../../../components/global-components/LoadingComponent";

class ModalEditDetailSKP_PertemuanAuditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tempSKPData: JSON.parse(localStorage.getItem("skp_add_data_temp")),
            skp_detail: JSON.parse(localStorage.getItem("skp_detail")),
            modalDetailDokumenSKPShow: false,
            modalDetailDokumenShow: false,
            berkas_hadir: null,
            berkas_hadir_button: null,
            isDoneUploading: true,
            imgUrl: "",
            modal_title: "",
            selected_activity: "",
            spek_berkas_hadir: null
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.updateState = this.updateState.bind(this);
    }

    handleOpenDocument(imgUrl, modal_title) {
        this.setState({ imgUrl: imgUrl })
        this.setState({ modal_title: modal_title })
        if (imgUrl.split('.')[1] === "pdf") {
            this.setState({ modalDetailDokumenSKPShow: true })
        } else {
            this.setState({ modalDetailDokumenShow: true })
        }
    }

    handleUploadFile(values) {
        const formName = values.target.name;
        // if (formName === "berkas_sip") {
        //     this.setState({ berkas_sip: "Berkas SIP berhasil diunggah" })
        //     this.props.change("berkas_sip", "dvorak");
        // } else if (formName === "berkas_hadir") {
        //     this.setState({ berkas_hadir: "Berkas Medis berhasil diunggah" })
        //     this.props.change("berkas_hadir", "qwerty");
        // }
        // if (values.target.files[0].size < 2000000) {
        this.setState({ isDoneUploading: false })
        this.setState({ berkas_hadir_button: true })
        if (formName === "berkas_hadir") {
            this.setState({ berkas_hadir: <Loading /> })
        }
        const callback = () => {
            this.setState({ isDoneUploading: true })
            this.setState({ berkas_hadir_button: false })
            if (this.props.uploadP2kb_data) {
                if (formName === "berkas_hadir") {
                    this.props.change("berkas_hadir", this.props.uploadP2kb_data);
                    this.setState({ berkas_hadir: <Loading done={true} /> })
                    setTimeout(() => this.setState({ berkas_hadir: <DetailContentLihatBerkas onClick={() => this.handleOpenDocument(this.props.formValue.berkas_hadir, "Daftar Hadir")} /> }), 2000)
                }
            }
        }
        const error = () => {
            this.setState({ isDoneUploading: true })
            this.setState({ berkas_hadir_button: false })
            if (this.props.uploadP2kb_error) {
                if (formName === "berkas_hadir") {
                    this.props.change("berkas_hadir", null);
                    this.setState({ berkas_hadir: <Loading done={true} failed={true} /> })
                }
            }
        }
        this.props.uploadP2kb(values.target.files[0], callback, error);
        this.props.change("spek_berkas_hadir", values.target.files[0]);
        // } else {
        //     if (formName === "berkas_hadir") {
        //         this.setState({ berkas_hadir: "Batas maksimal ukuran file 2 MB!" })
        //     }
        // }
    }

    handleSubmit(values) {
        const { onHide, handleRefresh, editDetailP2kb } = this.props;
        const data = JSON.parse(localStorage.getItem("skp_edit_detail_skp_pertemuan_auditor"));
        if (data.status === undefined || data.status === 0) {
            let details_tobesent = {
                activity_date: values.activity_date,
                hasEdit: true,
                meeting_material: values.meeting_material,
                institution_name: values.institution_name,
                meeting_leader_name: values.meeting_leader_name,
                member_p2kb_id : data.member_p2kb_id,
                attachments: [
                    {
                        label: "Penunjukan/Bukti Hadir",
                        filename: values.berkas_hadir
                    }
                ]
            }
            let details_tobeshown = {
                ...details_tobesent,
                skp: 1,
                no: data.no,
                tableData: data.tableData,
            }
            this.props.handleEditDetail(details_tobeshown, details_tobesent);
            this.props.onHide();
        } else {
            const param = {
                id: data.id,
                activity_date: values.activity_date,
                meeting_material: values.meeting_material,
                institution_name: values.institution_name,
                meeting_leader_name: values.meeting_leader_name,
                attachments: [
                    {
                        label: "Penunjukan/Bukti Hadir",
                        filename: values.berkas_hadir
                    }
                ]
            }
            const callback = () => {
                onHide();
                handleRefresh();
                localStorage.removeItem("skp_edit_detail_skp_pertemuan_auditor");
            }
            editDetailP2kb(param, callback);
        }
    }

    updateState() {
        if (this.state.berkas_hadir === null) {
            if (JSON.parse(localStorage.getItem("skp_edit_detail_skp_pertemuan_auditor") !== null)) {
                this.setState({ berkas_hadir: <DetailContentLihatBerkas onClick={() => this.handleOpenDocument(JSON.parse(localStorage.getItem("skp_edit_detail_skp_pertemuan_auditor")).attachments[0].filename, "Bukti Hadir")} /> })
            }
        }
    }

    componentDidUpdate(prevProps) {
        this.updateState();
    }

    componentDidMount() {
        this.props.listActivity();
    }

    render() {
        const { show,
            onHide,
            handleSubmit,
            uploadP2kb_pending
        } = this.props;
        return (
            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <ModalDetailDokumenSKP
                    show={this.state.modalDetailDokumenSKPShow}
                    onHide={() => this.setState({ modalDetailDokumenSKPShow: false })}
                    imgUrl={this.state.imgUrl}
                    modal_title={this.state.modal_title}
                />
                <ModalDetailDokumen
                    show={this.state.modalDetailDokumenShow}
                    onHide={() => this.setState({ modalDetailDokumenShow: false })}
                    imgUrl={this.state.imgUrl}
                    modal_title={this.state.modal_title}
                />
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Edit SKP</p>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(this.handleSubmit)}
                        style={{ width: "100%" }}
                    >
                        <Column
                            vertical="center"
                            horizontal="stretch"
                            className={css(styles.bodyModal)}
                        >
                            <>
                                <Field
                                    name="activity_date"
                                    placeholder="Masukkan Tanggal Kegiatan"
                                    label="Tanggal Kegiatan"
                                    component={renderDatepickerWithLabel}
                                />
                                <Field
                                    name="meeting_material"
                                    placeholder="Masukkan Materi Pertemuan"
                                    label="Materi Pertemuan"
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="institution_name"
                                    placeholder="Masukkan Nama Institusi"
                                    label="Nama Institusi"
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="meeting_leader_name"
                                    placeholder="Masukkan Nama Pimpinan Pertemuan"
                                    label="Nama Pimpinan Pertemuan"
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="skp_total"
                                    placeholder="Jumlah SKP"
                                    label="Jumlah SKP"
                                    disable={true}
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="berkas_hadir"
                                    type="file"
                                    fileType="skp"
                                    title="Bukti Hadir"
                                    disabled={this.state.berkas_hadir_button}
                                    isDone={this.state.isDoneUploading}
                                    message={this.props.formValue.berkas_hadir === undefined ? "Image atau PDF maksimal 1 MB" : this.state.berkas_hadir === null ? "Image atau PDF maksimal 1 MB" : this.state.berkas_hadir}
                                    onChange={this.handleUploadFile}
                                    component={renderFileForm}
                                />
                            </>
                        </Column>
                        <Row
                            horizontal="flex-end"
                            vertical="center"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={uploadP2kb_pending}
                                className={css(styles.approveButton)}
                                startIcon={
                                    uploadP2kb_pending ? (
                                        <CircularProgress size={14} color="secondary" />
                                    ) : (
                                        <CheckIcon />
                                    )
                                }
                            >
                                simpan
                            </Button>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        backgroundColor: "#FBFBFD",
        padding: "20px 40px",
    },
    bodyModal: {
        padding: "0px 40px 20px",
        width: "100%",
    },
})

function validate(values) {
    const maxFileSize = 1000000; //1MB
    const typeImage = /image/
    const fileType = "application/pdf"
    const { activity_date, meeting_material, institution_name, meeting_leader_name, berkas_hadir, spek_berkas_hadir } = values;
    const errors = {}
    if (!activity_date) {
        errors.activity_date = "Tanggal kegiatan wajib diisi"
    }
    if (!meeting_material) {
        errors.meeting_material = "Materi pertemuan wajib diisi"
    }
    if (!institution_name) {
        errors.institution_name = "Nama institusi wajib diisi"
    }
    if (!meeting_leader_name) {
        errors.meeting_leader_name = "Nama pimpinan pertemuan wajib diisi"
    }
    if (!berkas_hadir) {
        errors.berkas_hadir = "Berkas hadir wajib diisi"
    }
    else{
        if (spek_berkas_hadir && (!spek_berkas_hadir.type.match(typeImage) && spek_berkas_hadir.type !== fileType)){
            errors.berkas_hadir = "Format file hanya bisa Image atau PDF"
        }
        else if (spek_berkas_hadir && (spek_berkas_hadir.size > maxFileSize)){
            errors.berkas_hadir = "File lebih dari 1 MB"
        }
    }
    return errors;
}

const afterSubmit = (result, dispatch) => dispatch(reset('ModalAddDetailSKP_PertemuanAuditor'));

ModalEditDetailSKP_PertemuanAuditor = reduxForm({
    form: "ModalEditDetailSKP_PertemuanAuditor",
    validate: validate,
    shouldError: () => true,
    onSubmitSuccess: afterSubmit,
    enableReinitialize: true,
})(ModalEditDetailSKP_PertemuanAuditor)

function mapStateToProps(state) {
    const { listActivity, uploadP2kb, editDetailP2kb } = state;
    const selector = formValueSelector("ModalEditDetailSKP_PertemuanAuditor");
    const formValue = selector(state, "skp_total", "berkas_hadir", "spek_berkas_hadir")
    const editData = JSON.parse(localStorage.getItem("skp_edit_detail_skp_pertemuan_auditor"));
    let initialValues = {};
    if (editData) {
        if (!editData.isDetail) {
            initialValues = {
                activity_date: editData.activity_date,
                meeting_material: editData.meeting_material,
                institution_name: editData.institution_name,
                meeting_leader_name: editData.meeting_leader_name,
                skp_total: editData.skp,
                berkas_hadir: editData.attachments[0].filename
            }
        } else {
            if (editData.form_json) {
                let form_json = JSON.parse(editData.form_json);
                initialValues = {
                    isDetail: true,
                    id: editData.id,
                    status: editData.status,
                    activity_date: editData.activity_date,
                    meeting_material: form_json.meeting_material,
                    institution_name: form_json.institution_name,
                    meeting_leader_name: form_json.meeting_leader_name,
                    skp_total: editData.total_skp,
                    berkas_hadir: editData.attachments[0].filename
                }
            } else {
                initialValues = {
                    activity_date: editData.activity_date,
                    meeting_material: editData.meeting_material,
                    institution_name: editData.institution_name,
                    meeting_leader_name: editData.meeting_leader_name,
                    skp_total: editData.skp,
                    berkas_hadir: editData.attachments[0].filename
                }
            }
        }
    }
    return {
        listActivity_pending: getActivityPending(listActivity),
        listActivity_data: getListActivity(listActivity),
        listActivity_error: getActivityError(listActivity),
        editDetailP2kb_pending: getP2kbEditDetailPending(editDetailP2kb),
        editDetailP2kb_data: getP2kbEditDetail(editDetailP2kb),
        editDetailP2kb_error: getP2kbEditDetailError(editDetailP2kb),
        uploadP2kb_pending: getP2kbUploadPending(uploadP2kb),
        uploadP2kb_data: getP2kbUpload(uploadP2kb),
        uploadP2kb_error: getP2kbUploadError(uploadP2kb),
        formValue,
        initialValues
    }
}

export default connect(mapStateToProps, { listActivity, uploadP2kb, editDetailP2kb })(withRouter(ModalEditDetailSKP_PertemuanAuditor));