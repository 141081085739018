import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import { Column, Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import DetailRegistrationStatusComponent from "../../components/detail/DetailRegistrationStatusComponent";
import DetailMenuComponent from "../../components/detail/DetailMenuComponent";
import IconError from "../../assets/icon-error";
import DetailTitleComponent from "../../components/detail/DetailTitleComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import DetailContentWithEditComponent from "../../components/detail/DetailContentWithEditComponent";
import EditComponent from "../../components/global-components/EditComponent";
import { registrationList } from "../../actions/registrasi_sp2/registrasi_action";
import ModalEditNpaIdi from "./Modal_edit_npa_idi";
import {
  getRegistrationListPending,
  getRegistrationList,
  getRegistrationListError,
} from "../../reducers/registrasi_sp2/registrasi_reducer";
import { connect } from "react-redux";
import moment from "moment";

class DetailRegistrasiInformasiUmum extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalEditNPAIDIShow: false,
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
  }

  informasiUmum(id) {
    this.props.history.push(`/registrasi-sp2/detail/informasi-umum/${id}`);
  }

  pendidikanAsal(id) {
    this.props.history.push(`/registrasi-sp2/detail/pendidikan-asal/${id}`);
  }

  informasiPPDS(id) {
    this.props.history.push(`/registrasi-sp2/detail/informasi-ppds/${id}`);
  }

  componentDidMount() {
    let param_registrationList = {
      page: 1,
      length: 1,
      search_text: "",
      id: "",
    };
    this.props.registrationList(param_registrationList);
    const {
      match: {
        params: { id },
      },
    } = this.props;
    param_registrationList.id = id;
    this.props.registrationList(param_registrationList);
  }

  shouldComponentRender() {
    const { registrationList_pending } = this.props;
    if (registrationList_pending === false) {
      return false;
    }
    return true;
  }

  render() {
    const { modalEditNPAIDIShow } = this.state;
    const { registrationList_data } = this.props;
    let data = [];
    let prodi_tujuan = "";
    let prodi_tujuan_label_modal = "";
    let prodi_tujuan_value_modal = "";
    let status_ppds_label = "";
    let status_registration_label = "";
    let prodi_tujuan_bulan = "";
    let prodi_tujuan_tahun = "";
    let status,
      messageTitle,
      message,
      imgUrl,
      reject_reason = "";
    let email,
      name,
      university_before,
      graduated,
      birthday,
      branch,
      working_hospital_1,
      working_hospital_2,
      working_hospital_3,
      place_of_birth,
      address,
      handphone,
      npa_idi,
      ip_s1,
      ip_profesi,
      ip_cumulation,
      catatan,
      interest,
      kolegium_no = "";
    if (registrationList_data) {
      data = registrationList_data.data[0];
      status = data.status_registration;
      messageTitle =
        status === 0 ? "Baru" : status === 1 ? "Terverifikasi" : "Ditolak";
      message =
        status === 0
          ? "Verifikasi berkas pembayaran"
          : status === 1
          ? "Konfirmasi hasil tes peserta"
          : "Berkas bukti pendaftaran tidak Sesuai. Silahkan upload ulang berkas pembayaran.";
      reject_reason =
        data.ppds_reject_data.length > 0 ? data.ppds_reject_data[0].notes : "";
      imgUrl =
        data.ppds_reject_data.length > 0
          ? data.ppds_reject_data[0].attachment_list[0].attachment_name
          : "";

      prodi_tujuan =
        data.ppds_destination_university_data[0].university_data.abbreviation +
        "/" +
        data.ppds_destination_university_data[0].period_selection_month +
        "/" +
        data.ppds_destination_university_data[0].period_selection_year;
      // img_payment_link = "https://dev.api.paboi.bigio.id/v1/image/show/ppds_payment/" + data.img_payment;
      prodi_tujuan_label_modal =
        data.ppds_destination_university_data[0].university_data.abbreviation +
        " - Fakultas Kedokteran " +
        data.ppds_destination_university_data[0].university_data.name;
      prodi_tujuan_value_modal =
        data.ppds_destination_university_data[0].university_id;
      status_ppds_label =
        data.status_ppds === 0
          ? "New"
          : data.status_ppds === 1
          ? "Disetujui"
          : "Gagal";
      status_registration_label =
        data.status_registration === 0
          ? "New"
          : data.status_registration === 1
          ? "Disetujui"
          : "Gagal";
      prodi_tujuan_bulan =
        data.ppds_destination_university_data[0].period_selection_month;
      prodi_tujuan_tahun =
        data.ppds_destination_university_data[0].period_selection_year;
      interest = data.ppds_destination_university_data[0].interest;
      email = data.email;
      name = data.name;
      university_before = data.university_before;
      branch = data.user_data.branch_data
        ? data.user_data.branch_data.name
        : "-";
      working_hospital_1 = data.working_hospital_1;
      working_hospital_2 = data.working_hospital_2;
      working_hospital_3 = data.working_hospital_3;
      graduated = data.graduated;
      birthday = data.birthday;
      place_of_birth = data.place_of_birth;
      address = data.address;
      handphone = data.handphone;
      ip_s1 = data.ip_s1;
      ip_profesi = data.ip_profesi;
      ip_cumulation = data.ip_cumulation;
      npa_idi = data.npa_idi;
      kolegium_no =
        data.kolegium_no == null || data.kolegium_no == ""
          ? "-"
          : data.kolegium_no;
      catatan = data.notes;
    }
    return (
      <ContentContainer>
        <Row>
          <ModalEditNpaIdi
            show={modalEditNPAIDIShow}
            onHide={() => this.setState({ modalEditNPAIDIShow: false })}
            id={data.id}
            prodi={prodi_tujuan_value_modal}
            bulan={prodi_tujuan_bulan}
            tahun={prodi_tujuan_tahun}
            interest={interest}
            email={email}
            branch={branch}
            name={name}
            university_before={university_before}
            graduated={graduated}
            birthday={birthday}
            place_of_birth={place_of_birth}
            address={address}
            handphone={handphone}
            npa_idi={npa_idi}
            notes={catatan}
            kolegium_no={kolegium_no}
            ip_cumulation={ip_cumulation}
            ip_s1={ip_s1}
            ip_profesi={ip_profesi}
            working_hospital_1={working_hospital_1}
            working_hospital_2={working_hospital_2}
            working_hospital_3={working_hospital_3}
          />
          <Row className={css(styles.leftDetailContainer)}>
            <Column>
              <DetailNameComponent name={data.name} />
              <DetailRegistrationStatusComponent
                icon={IconError}
                status={data.status_registration}
                messageTitle={messageTitle}
                message={message}
                imgUrl={imgUrl}
                reason={reject_reason}
              />
              <DetailMenuComponent menuName="Informasi Umum" isActive={true} />
              <DetailMenuComponent
                menuName="Pendidikan Asal"
                onClick={() => this.pendidikanAsal(data.id)}
              />
              <DetailMenuComponent
                menuName="Informasi PPDS"
                onClick={() => this.informasiPPDS(data.id)}
              />
            </Column>
          </Row>
          <Row className={css(styles.rightDetailContainer)}>
            <Column className={css(styles.rightDetailColumn)}>
              <DetailTitleComponent title="Informasi Umum" />
              <DetailContentWithEditComponent
                label="NPA ID"
                color="blue"
                value={data.npa_idi}
              >
                <EditComponent
                  onClick={() => this.setState({ modalEditNPAIDIShow: true })}
                />
              </DetailContentWithEditComponent>
              <DetailContentComponent label="Nama Lengkap" value={data.name} />
              <DetailContentComponent
                label="Cabang"
                value={
                  data.user_data.branch_id !== null
                    ? data.user_data.branch_data.name
                    : "-"
                }
              />
              <DetailContentComponent label="Email" value={data.email} />
              <DetailContentComponent label="Alamat" value={data.address} />
              <DetailContentComponent
                label="Tempat Lahir"
                value={data.place_of_birth}
              />
              <DetailContentComponent
                label="Tanggal Lahir"
                value={moment(data.birthday).format("DD MMMM YYYY")}
              />
              <DetailContentComponent
                label="No Telepon"
                value={data.handphone}
              />
              <DetailContentComponent
                label="RS Tempat Bekerja 1"
                value={data.working_hospital_1 ? data.working_hospital_1 : "-"}
              />
              <DetailContentComponent
                label="RS Tempat Bekerja 2"
                value={data.working_hospital_2 ? data.working_hospital_2 : "-"}
              />
              <DetailContentComponent
                label="RS Tempat Bekerja 3"
                value={data.working_hospital_3 ? data.working_hospital_3 : "-"}
              />
            </Column>
          </Row>
        </Row>
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  leftDetailContainer: {
    width: "30%",
    background: "#F8F9FB 0% 0% no-repeat padding-box",
    borderRight: "2px solid rgba(192,192,192, 0.5)",
    opacity: 1,
    padding: 40,
  },
  rightDetailContainer: {
    padding: 40,
    width: "70%",
  },
  rightDetailColumn: {
    width: "100%",
  },
});

function mapStateToProps({ registrationList }) {
  return {
    registrationList_pending: getRegistrationListPending(registrationList),
    registrationList_data: getRegistrationList(registrationList),
    registrationList_error: getRegistrationListError(registrationList),
  };
}

export default connect(mapStateToProps, { registrationList })(
  withRouter(DetailRegistrasiInformasiUmum)
);
