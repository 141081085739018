import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import { toastSuccess, toastError } from "../../components/toast/Toast";
import getHeader from "../header";

export const LIST_SERKOM_PENDING = "LIST_SERKOM_PENDING";
export const LIST_SERKOM_SUCCESS = "LIST_SERKOM_SUCCESS";
export const LIST_SERKOM_ERROR = "LIST_SERKOM_ERROR";
export const EDIT_SERKOM_PENDING = "EDIT_SERKOM_PENDING";
export const EDIT_SERKOM_SUCCESS = "EDIT_SERKOM_SUCCESS";
export const EDIT_SERKOM_ERROR = "EDIT_SERKOM_ERROR";
export const ADD_SERKOM_PENDING = "ADD_SERKOM_PENDING";
export const ADD_SERKOM_SUCCESS = "ADD_SERKOM_SUCCESS";
export const ADD_SERKOM_ERROR = "ADD_SERKOM_ERROR";
export const DETAIL_SERKOM_PENDING = "DETAIL_SERKOM_PENDING";
export const DETAIL_SERKOM_SUCCESS = "DETAIL_SERKOM_SUCCESS";
export const DETAIL_SERKOM_ERROR = "DETAIL_SERKOM_ERROR";
export const DELETE_SERKOM_PENDING = "DELETE_SERKOM_PENDING";
export const DELETE_SERKOM_SUCCESS = "DELETE_SERKOM_SUCCESS";
export const DELETE_SERKOM_ERROR = "DELETE_SERKOM_ERROR";
export const SET_IS_EDIT_FILE = "SET_IS_EDIT_FILE_SERKOM";

export const APPROVE_SERKOM_PENDING = "APPROVE_SERKOM_PENDING";
export const APPROVE_SERKOM_SUCCESS = "APPROVE_SERKOM_SUCCESS";
export const APPROVE_SERKOM_ERROR = "APPROVE_SERKOM_ERROR";

export const MEMBER_SERKOM_PENDING = "MEMBER_SERKOM_PENDING";
export const MEMBER_SERKOM_SUCCESS = "MEMBER_SERKOM_SUCCESS";
export const MEMBER_SERKOM_ERROR = "MEMBER_SERKOM_ERROR";

const LIST_SERKOM_URL = "member/serkom/find";
const EDIT_SERKOM_URL = "member/serkom/edit";
const ADD_SERKOM_URL = "member/serkom/add";
const DELETE_SERKOM_URL = "member/serkom/delete";
const APPROVE_SERKOM_URL = "member/serkom/approve";
const MEMBER_SERKOM_URL = "serkom/find_is_serkom";

export function listSerkom(param = {}, resolve) {
  return (dispatch) => {
    dispatch(actionPending(LIST_SERKOM_PENDING));
    API.post(LIST_SERKOM_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_SERKOM_SUCCESS, data));
        if (resolve) {
          const { records_total, data: response } = data;
          const result = response.map((item, i) => ({
            no: i + 1 + (param.page - 1) * 10,
            ...item,
          }));
          resolve({
            data: result,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
      })
      .catch((error) => {
        dispatch(actionError(LIST_SERKOM_ERROR, error));
        toastError(error, "Gagal mendapatkan list SERKOM, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}

export function editSerkom(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(EDIT_SERKOM_PENDING));
    API.post(EDIT_SERKOM_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(EDIT_SERKOM_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah SERKOM");

        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_SERKOM_ERROR, error));
        toastError(error, "Gagal mengubah SERKOM, ");
      });
  };
}

export function addSerkom(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_SERKOM_PENDING));
    API.post(ADD_SERKOM_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(ADD_SERKOM_SUCCESS, res.data.data));
        toastSuccess("Berhasil menambahkan SERKOM");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_SERKOM_ERROR, error));
        toastError(error, "Gagal menambahkan SERKOM, ");
      });
  };
}

export function deleteSerkom(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(DELETE_SERKOM_PENDING));
    API.post(DELETE_SERKOM_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(DELETE_SERKOM_SUCCESS, res.data.data));
        toastSuccess("Berhasil menghapus SERKOM");

        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DELETE_SERKOM_ERROR, error));
        toastError(error, "Gagal menghapus SERKOM, ");
      });
  };
}

export function detailSerkom(id = null) {
  const param = {
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_SERKOM_PENDING));
    API.post(LIST_SERKOM_URL, param, getHeader())
      .then((res) => {
        let idx = 0;
        let dataArray = [];
        if (id !== null || id !== "") {
          idx = res.data.data
            .map(function (e) {
              return e.id;
            })
            .indexOf(id);
          dataArray.push(res.data.data[idx]);
          res.data.data = dataArray;
          dispatch(actionSuccess(DETAIL_SERKOM_SUCCESS, dataArray));
        } else {
          dispatch(actionSuccess(DETAIL_SERKOM_SUCCESS, res.data.data));
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_SERKOM_ERROR, error));
        toastError(error, "Gagal mendapatkan list SERKOM, ");
      });
  };
}

export function setIsEditFile(value) {
  return {
    type: SET_IS_EDIT_FILE,
    value: value,
  };
}

export function approveSerkom(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(APPROVE_SERKOM_PENDING));
    API.post(APPROVE_SERKOM_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(APPROVE_SERKOM_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah status SERKOM");
        callback();
        return res;
      })
      .catch((error) => {
        dispatch(actionError(APPROVE_SERKOM_ERROR, error));
        toastError(error, "Gagal mengubah status SERKOM, ");
      });
  };
}

export function memberSerkom(param = {}, resolve) {
  return (dispatch) => {
    dispatch(actionPending(MEMBER_SERKOM_PENDING));
    API.post(MEMBER_SERKOM_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(MEMBER_SERKOM_SUCCESS, data));
        if (resolve) {
          resolve({
            data,
          });
        }
      })
      .catch((error) => {
        dispatch(actionError(MEMBER_SERKOM_ERROR, error));
        toastError(error, "Gagal mendapatkan list SERKOM, ");
        if (resolve) {
          resolve({ data: [] });
        }
      });
  };
}
