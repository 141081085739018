import React, { useEffect } from "react";
import { string } from "prop-types";
import { Row, Column } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { getUser } from "../../helpers/user";
import DetailButtonComponent from "../global-components/DetailButtonComponent";
import MenuItem from "@material-ui/core/MenuItem";
import IconShutdown from "../../assets/icon-shutdown";
import IconKey from "../../assets/icon-key";
import IconNotification from "../../assets/icon-notification";
import ModalChangePassword from "../../pages/Modal_change_password";
import { useHistory } from "react-router-dom";
import AvatarComponent from "./AvatarComponent";
import { useSelector } from "react-redux";
import Hidden from "@material-ui/core/Hidden";
import API from "../../actions/API";
import getHeader from "../../actions/header";

function HeaderComponent(props) {
  const [modalChangePassword, setModalChangePassword] = React.useState(false);
  const [unreadCounter, setUnreadCounter] = React.useState(0);
  const head = useSelector((state) => state.setSelectedHeader);
  const link = useSelector((state) => state.setSelectedLinkHeader);

  useEffect(() => {
    API.post("notification/find", { length: 999999999 }, getHeader()).then(
      (res) => {
        const unread = res.data.data.reduce(
          (acc, data) => acc + (data.is_read === 0 ? 1 : 0),
          0
        );
        setUnreadCounter(unread);
      }
    );
  }, []);

  const { icon, title, ...otherProps } = props;
  const history = useHistory();
  let img_profile;
  let user = getUser();
  if (user) {
    if (user.role_codes[0] === "PPDS") {
      img_profile = user.profile_img;
    } else {
      ({ img_profile } = user);
    }
  }
  let titleComponent;
  const { match } = props;
  const isSerkom = localStorage.getItem("isSerkom") ? true : false;
  titleComponent = head.map((item, i) => {
    if (i === head.length - 1) {
      return null;
    }
    if (isSerkom) {
      if (link[i] === "/admin-p2kb/member/:id") {
        link[i] =
          "/admin/serkom/detail/" +
          localStorage.getItem("id_member") +
          "/" +
          localStorage.getItem("id_serkom");
      }
      if (link[i] === "/admin-p2kb/:id") {
        link[i] = "/admin-p2kb/" + localStorage.getItem("id_member");
      }
    } else {
      if (link[i] === "/admin-p2kb/member/:id") {
        link[i] = "/admin-p2kb/member/" + localStorage.getItem("id_member");
      }
      if (link[i] === "/admin-p2kb/:id") {
        link[i] = "/admin-p2kb/" + localStorage.getItem("id_member");
      }
    }

    return (
      <Row key={i}>
        <span
          className={css(styles.breadcrumb)}
          onClick={() => {
            history.push(link[i]);
          }}
        >
          {item}
        </span>
        <span className={css(styles.breadcrumbSeparator)}>&gt;</span>
      </Row>
    );
  });
  if (head[head.length - 1] === "SKP Nama Member") {
    head[head.length - 1] = "SKP " + localStorage.getItem("nama_member");
  }
  let titleBig = head[head.length - 1];
  const windowWidth = window.innerWidth;
  return (
    <Row
      className={css(styles.container)}
      vertical="center"
      horizontal={windowWidth > 959 ? "space-between" : "flex-end"}
      {...otherProps}
    >
      <ModalChangePassword
        show={modalChangePassword}
        onHide={() => setModalChangePassword(false)}
      />
      <Hidden only={["xs", "sm"]}>
        <Column vertical="center" style={{ marginTop: 30 }}>
          <div style={{ display: "flex" }}>{titleComponent}</div>
          <div className={css(styles.title)}>{titleBig}</div>
        </Column>
      </Hidden>

      <Row vertical="center">
        <Column>
          <span className={css(styles.name, styles.cursorPointer)}>
            {getUser() && getUser().name}
          </span>
          <span className={css(styles.role, styles.cursorPointer)}>
            {getUser() && getUser().role_names[0]}
          </span>
        </Column>
        <div className={css(styles.separator)}></div>
        <DetailButtonComponent
          button={(props) => (
            <Row onClick={props.onClick} style={{ width: 50, height: 50 }}>
              <AvatarComponent image={img_profile} />
            </Row>
          )}
          horizontal="center"
        >
          {getUser() && getUser().role_names[0] !== "PPDS" && (
            <>
              <MenuItem
                className={css(styles.dropdownProfile)}
                onClick={() => setModalChangePassword(true)}
              >
                <IconKey />{" "}
                <span className={css(styles.menuList)}>Ubah Password</span>
              </MenuItem>
            </>
          )}

          <MenuItem
            className={css(styles.dropdownProfile)}
            onClick={() => history.push("/notification")}
          >
            <IconNotification />
            <span className={css(styles.menuList)}>Notification </span>{" "}
            <span className={css(styles.menuNotificationBadge)}>
              <b>{unreadCounter >= 99 ? " 99+" : unreadCounter}</b>
            </span>
          </MenuItem>
          <MenuItem
            className={css(styles.dropdownProfile)}
            onClick={() => {
              history.push("/login");
              localStorage.removeItem("start_date");
              localStorage.removeItem("end_date");
            }}
          >
            <IconShutdown />{" "}
            <span className={css(styles.menuList)}>Logout</span>
          </MenuItem>
        </DetailButtonComponent>
      </Row>
    </Row>
  );
}

HeaderComponent.propTypes = {
  title: string,
};

const styles = StyleSheet.create({
  avatar: {
    height: 50,
    width: 50,
    borderRadius: 50,
    border: "1px solid #DFE0EB",
  },
  container: {
    height: 40,
  },
  cursorPointer: {
    cursor: "pointer",
  },
  name: {
    fontFamily: "Muli",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "20px",
    textAlign: "right",
    letterSpacing: 0.2,
    position: "relative",
    color: "#6A6A6A",
    // "@media (max-width: 768px)": {
    //   display: "none",
    // },
  },
  role: {
    fontFamily: "Muli",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    lineHeight: "20px",
    textAlign: "right",
    letterSpacing: 0.2,
    color: "#495057",
    opacity: 0.55,
    position: "relative",
    marginTop: 5,
    // "@media (max-width: 768px)": {
    //   display: "none",
    // },
  },
  separator: {
    borderLeft: "1px solid #DFE0EB",
    marginLeft: 20,
    marginRight: 20,
    height: 50,
    width: 2,
    // "@media (max-width: 768px)": {
    //   display: "none"
    // },
  },
  title: {
    fontFamily: "Muli",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 30,
    lineHeight: "30px",
    letterSpacing: 0.3,
    marginTop: 10,
    // position: "relative",
    // top: 40,
    // left: -88,
    color: "#495057",
    // "@media (max-width: 768px)": {
    //   marginLeft: 36,
    // },
    // "@media (max-width: 468px)": {
    //   fontSize: 20,
    // },
  },
  breadcrumb: {
    fontFamily: "Muli",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 14,
    cursor: "pointer",
    color: "#495057",
    opacity: 0.7,
    "@media (max-width: 768px)": {
      marginLeft: 36,
    },
    "@media (max-width: 468px)": {
      fontSize: 10,
    },
  },
  breadcrumbSeparator: {
    fontFamily: "Muli",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 14,
    color: "#495057",
    opacity: 0.7,
    marginLeft: 20,
    marginRight: 20,
    "@media (max-width: 768px)": {
      marginLeft: 36,
    },
    "@media (max-width: 468px)": {
      fontSize: 10,
    },
  },
  iconStyles: {
    cursor: "pointer",
    marginLeft: 25,
    "@media (max-width: 768px)": {
      marginLeft: 12,
    },
  },
  navLink: {
    textDecoration: "none",
  },
  dropdownProfile: {
    color: "#495057",
    ":hover": {
      textDecoration: "none",
    },
  },
  menuList: {
    marginLeft: 5,
  },
  menuNotificationBadge: {
    position: "absolute",
    right: "10px",
  },
});

export default HeaderComponent;
