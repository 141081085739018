import React, { Component } from "react";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import { detailPpds } from "../../actions/member/ppds_action";
import { withRouter } from "react-router-dom";

import {
  getPpdsPending,
  getDetailPpds,
  getPpdsError,
} from "../../reducers/member/ppds_reducer";
import { connect } from "react-redux";

class DetailPPDS extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedItem: "umum" };
  }

  render() {
    const { detailPpds_data } = this.props;
    let result = {
      name: null,
      npa_idi: null,
      email: null,
      address: null,
      place_of_birth: null,
      birthday: null,
      handphone: null,
      branch: null,
      working_hospital_1: null,
      working_hospital_2: null,
      working_hospital_3: null,
    };
    if (detailPpds_data && detailPpds_data.data.length !== 0) {
      const data = detailPpds_data.data[0];
      result.name = data.name;
      result.npa_idi = data.npa_idi;
      result.email = data.email;
      result.address = data.address;
      result.place_of_birth = data.place_of_birth;
      result.birthday = data.birthday;
      result.handphone = data.handphone;
      result.branch = data.user_data.branch_data.name;
      result.working_hospital_1 = data.working_hospital_1
        ? data.working_hospital_1
        : "-";
      result.working_hospital_2 = data.working_hospital_2
        ? data.working_hospital_2
        : "-";
      result.working_hospital_3 = data.working_hospital_3
        ? data.working_hospital_3
        : "-";
    }

    const {
      name,
      npa_idi,
      email,
      address,
      place_of_birth,
      birthday,
      handphone,
      branch,
      working_hospital_1,
      working_hospital_2,
      working_hospital_3,
    } = result;
    return (
      <>
        <DetailNameComponent name="Informasi Umum" value={npa_idi} />
        <DetailContentComponent label="Nama Lengkap" value={name} />
        <DetailContentComponent label="NPA IDI" value={npa_idi} />
        <DetailContentComponent label="Cabang" value={branch} />
        <DetailContentComponent label="Email" value={email} />
        <DetailContentComponent label="Alamat" value={address} />
        <DetailContentComponent label="Tempat Lahir" value={place_of_birth} />
        <DetailContentComponent label="Tanggal Lahir" value={birthday} />
        <DetailContentComponent label="No Telepon" value={handphone} />
        <DetailContentComponent
          label="RS Tempat Bekerja 1 "
          value={working_hospital_1}
        />
        <DetailContentComponent
          label="RS Tempat Bekerja 2 "
          value={working_hospital_2}
        />
        <DetailContentComponent
          label="RS Tempat Bekerja 3 "
          value={working_hospital_3}
        />
      </>
    );
  }
}

function mapStateToProps({ detailPpds }) {
  return {
    detailPpds_data: getDetailPpds(detailPpds),
    detailPpds_error: getPpdsError(detailPpds),
    detailPpds_pending: getPpdsPending(detailPpds),
  };
}

export default connect(mapStateToProps, { detailPpds })(withRouter(DetailPPDS));
