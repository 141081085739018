import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { addSerkom, memberSerkom } from "../../actions/serkom/serkom_action";
import {
  renderInput,
  renderSelect,
  renderDate,
  renderFile,
} from "../../components/form/FormComponent";
import {
  getSerkomPending,
  getMemberSerkom,
  getMemberSerkomPending,
  getMemberSerkomError,
} from "../../reducers/serkom/serkom_reducer";
import { memberList } from "../../actions/member/member_action";
import {
  getMemberList,
  getMemberPending,
} from "../../reducers/member/member_reducer";

class ModalSerkomAdd extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show === true) {
      this.props.reset();
    }
  }

  handleSubmit(values) {
    const { onHide, handleRefresh, addSerkom } = this.props;

    const { sk_no, start_date, end_date, berkas, member } = values;
    let formData = new FormData();
    formData.append("user_id", member.value);
    formData.append("sk_no", sk_no);
    formData.append("start_date", start_date);
    formData.append("end_date", end_date);
    formData.append("file", berkas);

    const callback = () => {
      onHide();
      handleRefresh();
    };
    addSerkom(formData, callback);
  }

  componentDidMount() {
    // this.props.memberList();
    this.props.memberSerkom();
  }

  render() {
    const {
      handleSubmit,
      show,
      onHide,
      addSerkom_pending,
      listMemberSerkom_data,
    } = this.props;
    let memberOptions = [];
    if (listMemberSerkom_data) {
      memberOptions = listMemberSerkom_data.data.map(({ id, name }) => ({
        label: name,
        value: id,
      }));
    }
    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }
    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Tambah Serkom</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              <Field
                name="member"
                placeholder="Pilih Member"
                options={memberOptions}
                isAsync
                asyncUrl="/serkom/find_is_serkom"
                component={renderSelect}
              />
              <Field
                name="sk_no"
                placeholder="Masukkan No Serkom"
                component={renderInput}
              />

              <Row
                style={{ width: "100%", margin: "10px 0" }}
                horizontal="space-between"
                vertical="center"
              >
                <Column style={{ width: "48%" }}>
                  <Field
                    name="start_date"
                    label="Tanggal Penetapan"
                    component={renderDate}
                  />
                </Column>
                <Column style={{ width: "48%" }}>
                  <Field
                    name="end_date"
                    label="Tanggal Berakhir"
                    component={renderDate}
                  />
                </Column>
              </Row>

              <Field
                name="berkas"
                type="file"
                fileType="image" //image or pdf
                title="upload berkas"
                message="PNG atau JPG maksimal 2 Mb"
                component={renderFile}
              />
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={addSerkom_pending}
                className={css(styles.approveButton)}
                startIcon={
                  addSerkom_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const maxFileSize = 2000000; //2mb
  const imageType = ["image/png", "image/jpeg"];
  const { sk_no, start_date, end_date, berkas, member } = values;
  const errors = {};
  if (!member) {
    errors.member = "Member wajib diisi";
  }
  if (!sk_no) {
    errors.sk_no = "Nomor surat serkom wajib diisi";
  }
  if (!start_date) {
    errors.start_date = "Tanggal penetapan wajib diisi";
  }
  if (!end_date) {
    errors.end_date = "Tanggal berakhir wajib diisi";
  }
  if (!berkas) {
    errors.berkas = "Berkas wajib diisi";
  } else {
    if (
      values.berkas &&
      values.berkas.type !== imageType[0] &&
      values.berkas.type !== imageType[1]
    ) {
      errors.berkas = "Format file hanya bisa PNG/JPG/JPEG";
    } else if (values.berkas && values.berkas.size > maxFileSize) {
      errors.berkas = "File lebih dari 2 MB";
    }
  }
  return errors;
}

ModalSerkomAdd = reduxForm({
  // a unique name for the form
  form: "ModalSerkomAdd",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(ModalSerkomAdd);

function mapStateToProps({ addSerkom, memberList, memberSerkom }) {
  return {
    addSerkom_pending: getSerkomPending(addSerkom),
    listMember_pending: getMemberPending(memberList),
    listMember_data: getMemberList(memberList),
    listMemberSerkom_data: getMemberSerkom(memberSerkom),
  };
}

export default connect(mapStateToProps, {
  addSerkom,
  memberList,
  memberSerkom,
})(withRouter(ModalSerkomAdd));
