import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, reset } from "redux-form";
import {
  renderSelectWithLabel,
  renderDatepickerWithLabel,
  renderInputWithLabel,
  renderFileForm,
} from "../../../components/form/FormComponent";
import { listActivity } from "../../../actions/master-data/activity_action";
import {
  getListActivity,
  getActivityPending,
  getActivityError,
} from "../../../reducers/master-data/activity_reducer";
import { uploadP2kb, editDetailP2kb } from "../../../actions/p2kb/p2kb_action";
import {
  getP2kbUploadPending,
  getP2kbUpload,
  getP2kbUploadError,
  getP2kbEditDetailPending,
  getP2kbEditDetail,
  getP2kbEditDetailError,
} from "../../../reducers/p2kb/p2kb_reducer";
// import moment from "moment";
import DetailContentLihatBerkas from "../../../components/detail/DetailContentLihatBerkas";
import ModalDetailDokumenSKP from "../Modal_document_pdf";
import ModalDetailDokumen from "../Modal_document";
import Loading from "../../../components/global-components/LoadingComponent";

class ModalEditDetailSKP_PengabdianMasyarakat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempSKPData: JSON.parse(localStorage.getItem("skp_add_data_temp")),
      skp_detail: JSON.parse(localStorage.getItem("skp_detail")),
      modalDetailDokumenSKPShow: false,
      modalDetailDokumenShow: false,
      last_activity_skp_detail_id: "",
      values_id: "",
      berkas_sip: "",
      upload_berkas: "",
      skp: "",
      imgUrl: "",
      modal_title: "",
      jenisOptions: null,
      tingkatan: null,
      isDoneUploading: true,
      upload_berkas_button: null,
      spek_upload_berkas: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeTingkatan = this.handleChangeTingkatan.bind(this);
    this.handleChangeJenisTingkatan = this.handleChangeJenisTingkatan.bind(
      this
    );
    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ imgUrl: imgUrl })
    this.setState({ modal_title: modal_title })
    if (imgUrl.split('.')[1] === "pdf") {
      this.setState({ modalDetailDokumenSKPShow: true })
    } else {
      this.setState({ modalDetailDokumenShow: true })
    }
  }

  handleChangeTingkatan(values) {
    let listActivity_selected = "";
    if (this.state.tempSKPData !== null) {
      listActivity_selected = this.props.listActivity_data.data.find(
        (item) => item.id === this.state.tempSKPData.activity.value
      );
    } else {
      listActivity_selected = this.props.listActivity_data.data.find(
        (item) => item.id === this.state.skp_detail.activity_id
      );
    }

    let childrens_selected = listActivity_selected.childrens.find(
      (item) => item.id === values.value
    );

    this.props.change("jenisTingkatan", null);
    this.props.change("skp_total", null);

    this.setState({
      jenisOptions: childrens_selected.values.map((item) => ({
        label: item.label,
        value: item.id,
      })),
    });

    this.setState({ last_activity_skp_detail_id: childrens_selected.id });
  }

  handleChangeJenisTingkatan(values) {
    let listActivity_selected = "";
    if (this.state.tempSKPData !== null) {
      listActivity_selected = this.props.listActivity_data.data.find(
        (item) => item.id === this.state.tempSKPData.activity.value
      );
    } else {
      listActivity_selected = this.props.listActivity_data.data.find(
        (item) => item.id === this.state.skp_detail.activity_id
      );
    }

    let childrens_selected = listActivity_selected.childrens.find(
      (item) => item.id === this.state.last_activity_skp_detail_id
    );

    let values_selected = childrens_selected.values.find(
      (item) => item.id === values.value
    );

    this.setState({ last_activity_skp_detail_id: childrens_selected.id });
    this.setState({ values_id: values_selected.id });
    let skp_must_be_multipied = values_selected.skp_must_be_multipied;
    if (skp_must_be_multipied === 0) {
      this.props.change("skp_total", values_selected.value_skp);
      this.setState({ skp: values_selected.value_skp });
    }
  }

  handleUploadFile(values) {
    const formName = values.target.name;
    this.setState({ upload_berkas_button: true })
    this.setState({ isDoneUploading: false })
    if (formName === "upload_berkas") {
      this.setState({ upload_berkas: <Loading /> })
    }
    const callback = () => {
      this.setState({ isDoneUploading: true })
      this.setState({ upload_berkas_button: false })
      if (this.props.uploadP2kb_data) {
        if (formName === "upload_berkas") {
          this.props.change("upload_berkas", this.props.uploadP2kb_data);
          this.setState({ upload_berkas: <Loading done={true} /> })
          setTimeout(() => this.setState({ upload_berkas: <DetailContentLihatBerkas onClick={() => this.handleOpenDocument(this.props.formValue.upload_berkas, "Medis")} /> }), 2000)
        }
      }
    };
    const error = () => {
      this.setState({ isDoneUploading: true })
      this.setState({ upload_berkas_button: false })
      if (this.props.uploadP2kb_error) {
        if (formName === "upload_berkas") {
          this.props.change("upload_berkas", null);
          this.setState({ upload_berkas: <Loading done={true} failed={true} /> })
        }
      }
    }
    this.props.uploadP2kb(values.target.files[0], callback, error);
    this.props.change("spek_upload_berkas", values.target.files[0]);
  }

  handleSubmit(values) {
    const { handleEditDetail, onHide, handleRefresh, editDetailP2kb, formValue } = this.props;
    if (values.status === undefined || values.status === 0) {
      let details_tobesent = {
        activity_date: values.activity_date,
        activity_name: values.activity_name,
        activity_place: values.activity_place,
        hasEdit: true,
        city_name: values.city_name,
        total_for_calculate_skp: this.state.skp,
        last_activity_skp_detail_id: this.state.last_activity_skp_detail_id,
        values_id: this.state.values_id,
        no: JSON.parse(
          localStorage.getItem("skp_edit_detail_skp_pengabdian_masyarakat")
        ).no,
        member_p2kb_id : JSON.parse(
          localStorage.getItem("skp_edit_detail_skp_pengabdian_masyarakat")
        ).member_p2kb_id,
        attachments: [
          {
            label: "Keterangan / Sertifikat Kegiatan",
            filename: values.upload_berkas,
          },
        ],
      };
      let details_tobeshown = {
        ...details_tobesent,
        tingkatan: values.tingkatan.label,
        jenisTingkatan: values.jenisTingkatan.label,
        skp: values.skp_total,
        no: JSON.parse(
          localStorage.getItem("skp_edit_detail_skp_pengabdian_masyarakat")
        ).no,
        tableData: JSON.parse(
          localStorage.getItem("skp_edit_detail_skp_pengabdian_masyarakat")
        ).tableData,
      };
      handleEditDetail(details_tobeshown, details_tobesent);
      localStorage.removeItem("skp_edit_detail_skp_pengabdian_masyarakat");
      onHide();
    } else {
      const param = {
        id: JSON.parse(
          localStorage.getItem("skp_edit_detail_skp_pengabdian_masyarakat")
        ).id,
        activity_date: values.activity_date,
        activity_name: values.activity_name,
        activity_place: values.activity_place,
        city_name: values.city_name,
        total_for_calculate_skp: this.state.skp,
        last_activity_skp_detail_id: this.state.last_activity_skp_detail_id,
        values_id: this.state.values_id,
        no: JSON.parse(
          localStorage.getItem("skp_edit_detail_skp_pengabdian_masyarakat")
        ).no,
        attachments: [
          {
            label: "Keterangan / Sertifikat Kegiatan",
            filename: values.upload_berkas,
          },
        ],
      }
      const callback = () => {
        onHide();
        handleRefresh();
      }
      editDetailP2kb(param, callback);
    }
  }

  updateState() {
    if (this.state.last_activity_skp_detail_id === "") {
      if (
        JSON.parse(
          localStorage.getItem("skp_edit_detail_skp_pengabdian_masyarakat") !==
          null
        )
      ) {
        this.setState({
          last_activity_skp_detail_id: JSON.parse(
            localStorage.getItem("skp_edit_detail_skp_pengabdian_masyarakat")
          ).last_activity_skp_detail_id,
        });
        this.setState({
          values_id: JSON.parse(
            localStorage.getItem("skp_edit_detail_skp_pengabdian_masyarakat")
          ).values_id,
        });
        this.setState({
          upload_berkas: (
            <DetailContentLihatBerkas
              onClick={() =>
                this.handleOpenDocument(
                  JSON.parse(
                    localStorage.getItem(
                      "skp_edit_detail_skp_pengabdian_masyarakat"
                    )
                  ).attachments[1].filename,
                  "Berkas"
                )
              }
            />
          ),
        });
        this.setState({
          skp: JSON.parse(
            localStorage.getItem("skp_edit_detail_skp_pengabdian_masyarakat")
          ).total_skp,
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    this.updateState();
  }

  componentDidMount() {
    this.props.listActivity({ length: 999 });
  }

  render() {
    const {
      show,
      onHide,
      listActivity_data,
      handleSubmit,
      uploadP2kb_pending,
    } = this.props;
    let tingkatanOptions = [];
    let jenisOptions = [];
    let activity_id_skp =
      this.state.tempSKPData !== null && this.state.tempSKPData.activity !== null
        ? this.state.tempSKPData.activity.value
        : this.state.skp_detail.activity_id;
    activity_id_skp = activity_id_skp === undefined ? this.state.skp_detail.activity_id : activity_id_skp
    if (listActivity_data) {
      let listActivity_selected = listActivity_data.data.find(
        (item) => item.id === activity_id_skp
      );
      tingkatanOptions = listActivity_selected?.childrens.map((item) => ({
        label: item.name,
        value: item.id,
      }));
    }

    //pastikan local storage edit ada isinya
    if (
      JSON.parse(
        localStorage.getItem("skp_edit_detail_skp_pengabdian_masyarakat")
      )
    ) {
      let tingkatan =
        this.state.jenisTingkatan == null
          ? JSON.parse(
            localStorage.getItem("skp_edit_detail_skp_pengabdian_masyarakat")
          ).last_activity_skp_detail_id
          : this.state.jenisTingkatan;
      //conditional waktu diatas untuk milih antara pake initial value (local storage) atau state tingkatan (set state tingkatan sata tingkatan diubah saat edit detail skp (handlechangetingkatan))

      //pastikan data listactivity sudah dapet responnya
      if (listActivity_data) {
        if (tingkatan) {
          let listActivity_selected = listActivity_data.data.find(
            (item) => item.id === activity_id_skp
          );

          let listTingkatan_selected = listActivity_selected.childrens.find(
            (item) => item.id === tingkatan
          );

          jenisOptions = listTingkatan_selected.values.map((item) => ({
            label: item.label,
            value: item.id,
          }));
        }
      }
    }

    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalDetailDokumenSKP
          show={this.state.modalDetailDokumenSKPShow}
          onHide={() => this.setState({ modalDetailDokumenSKPShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
        />
        <ModalDetailDokumen
          show={this.state.modalDetailDokumenShow}
          onHide={() => this.setState({ modalDetailDokumenShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
        />
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Edit SKP</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              <>
                <Field
                  name="activity_date"
                  placeholder="Masukkan Tanggal Kegiatan"
                  label="Tanggal Kegiatan"
                  component={renderDatepickerWithLabel}
                />
                <Field
                  name="activity_name"
                  placeholder="Nama Kegiatan"
                  label="Nama Kegiatan"
                  component={renderInputWithLabel}
                />
                <Field
                  name="activity_place"
                  placeholder="Tempat Kegiatan"
                  label="Tempat Kegiatan"
                  component={renderInputWithLabel}
                />
                <Field
                  name="city_name"
                  placeholder="Kota Penyelenggara"
                  label="Kota Penyelenggara"
                  component={renderInputWithLabel}
                />
                <Field
                  name="tingkatan"
                  placeholder="Masukkan Skala Kegiatan"
                  label="Skala Kegiatan"
                  onChange={this.handleChangeTingkatan}
                  isClearable={false}
                  options={tingkatanOptions}
                  component={renderSelectWithLabel}
                />
                <Field
                  name="jenisTingkatan"
                  placeholder="Masukkan Jenis Kegiatan"
                  label="Jenis Kegiatan"
                  onChange={this.handleChangeJenisTingkatan}
                  isClearable={false}
                  options={
                    this.state.jenisOptions == null
                      ? jenisOptions
                      : this.state.jenisOptions
                  }
                  component={renderSelectWithLabel}
                />
                <Field
                  name="skp_total"
                  placeholder="Jumlah SKP"
                  label="Jumlah SKP"
                  disable={true}
                  component={renderInputWithLabel}
                />
                <Field
                  name="upload_berkas"
                  type="file"
                  fileType="skp"
                  title="Upload Berkas"
                  disabled={this.state.upload_berkas_button}
                  isDone={this.state.isDoneUploading}
                  message={
                    this.props.formValue.upload_berkas === undefined
                      ? "Image atau PDF maksimal 1 MB"
                      : this.state.upload_berkas === null
                        ? "Image atau PDF maksimal 1 MB"
                        : this.state.upload_berkas
                  }
                  onChange={this.handleUploadFile}
                  component={renderFileForm}
                />
              </>
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={uploadP2kb_pending}
                className={css(styles.approveButton)}
                startIcon={
                  uploadP2kb_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const maxFileSize = 1000000; //1MB
  const typeImage = /image/
  const fileType = "application/pdf"
  const {
    activity_date,
    activity_name,
    activity_place,
    city_name,
    tingkatan,
    upload_berkas,
    jenisTingkatan,
    spek_upload_berkas
  } = values;
  const errors = {};
  if (!activity_date) {
    errors.activity_date = "Tanggal kegiatan wajib diisi";
  }
  if (!activity_name) {
    errors.activity_name = "Nama Kegiatan wajib diisi";
  }
  if (!tingkatan) {
    errors.tingkatan = "Skala Kegiatan wajib diisi";
  }
  if (!jenisTingkatan) {
    errors.jenisTingkatan = "Jenis Kegiatan wajib diisi";
  }
  if (!activity_place) {
    errors.activity_place = "Tempat Kegiatan wajib diisi";
  }
  if (!city_name) {
    errors.city_name = "Kota Kegiatan wajib diisi";
  }
  if (!upload_berkas) {
    errors.upload_berkas = "Bukti Kegiatan wajib diisi";
  }
  else{
    if (spek_upload_berkas && (!spek_upload_berkas.type.match(typeImage) && spek_upload_berkas.type !== fileType)){
      errors.upload_berkas = "Format file hanya bisa Image atau PDF"
    }
    else if (spek_upload_berkas && (spek_upload_berkas.size > maxFileSize)){
      errors.upload_berkas = "File lebih dari 1 MB"
    }
  }
  return errors;
}

const afterSubmit = (result, dispatch) =>
  dispatch(reset("ModalAddDetailSKP_PengabdianMasyarakat"));

ModalEditDetailSKP_PengabdianMasyarakat = reduxForm({
  form: "ModalEditDetailSKP_PengabdianMasyarakat",
  validate: validate,
  shouldError: () => true,
  onSubmitSuccess: afterSubmit,
  enableReinitialize: true,
})(ModalEditDetailSKP_PengabdianMasyarakat);

function mapStateToProps(state) {
  const { listActivity, uploadP2kb, editDetailP2kb } = state;
  const selector = formValueSelector("ModalEditDetailSKP_PengabdianMasyarakat");
  const formValue = selector(
    state,
    "tingkatan",
    "total_for_calculate_skp",
    "berkas_sip",
    "upload_berkas",
    "isDetail",
    "spek_upload_berkas"
  );
  const editData = JSON.parse(
    localStorage.getItem("skp_edit_detail_skp_pengabdian_masyarakat")
  );
  let initialValues = {};

  if (editData) {
    if (!editData.isDetail) {
      initialValues = {
        activity_date: editData.activity_date,
        activity_name: editData.activity_name,
        activity_place: editData.activity_place,
        city_name: editData.city_name,
        tingkatan: {
          label: editData.tingkatan,
          value: editData.last_activity_skp_detail_id,
        },
        jenisTingkatan: {
          label: editData.jenisTingkatan,
          value: editData.values_id,
        },
        total_for_calculate_skp: parseFloat(editData.total_for_calculate_skp),
        skp_total: editData.skp,
        upload_berkas: editData.attachments[0].filename,
      };
    } else {
      if (editData.form_json) {
        let result = JSON.parse(editData.form_json);
        initialValues = {
          isDetail: true,
          id: editData.id,
          activity_date: editData.activity_date,
          activity_name: result.activity_name,
          activity_place: result.activity_place,
          city_name: result.city_name,
          tingkatan: {
            label: editData.mst_activity_skp_type_detail_data.name,
            value: editData.last_activity_skp_detail_id,
          },
          jenisTingkatan: {
            label: editData.activity_skp_type_value_data.label,
            value: editData.activity_skp_type_value_data.id,
          },
          total_for_calculate_skp: parseFloat(editData.total_for_calculate_skp),
          skp_total: editData.total_skp,
          upload_berkas: editData.attachments[0].filename,
        };
      } else {
        initialValues = {
          isDetail: true,
          id: editData.id,
          activity_date: editData.activity_date,
          activity_name: editData.activity_name,
          activity_place: editData.activity_place,
          city_name: editData.city_name,
          tingkatan: {
            label: editData.tingkatan,
            value: editData.last_activity_skp_detail_id,
          },
          jenisTingkatan: {
            label: editData.jenisTingkatan,
            value: editData.values_id,
          },
          total_for_calculate_skp: parseFloat(editData.total_for_calculate_skp),
          skp_total: editData.total_skp,
          upload_berkas: editData.attachments[0].filename,
        };

      }
    }
  }
  return {
    listActivity_pending: getActivityPending(listActivity),
    listActivity_data: getListActivity(listActivity),
    listActivity_error: getActivityError(listActivity),
    uploadP2kb_pending: getP2kbUploadPending(uploadP2kb),
    uploadP2kb_data: getP2kbUpload(uploadP2kb),
    uploadP2kb_error: getP2kbUploadError(uploadP2kb),
    editDetailP2kb_pending: getP2kbEditDetailPending(editDetailP2kb),
    editDetailP2kb_data: getP2kbEditDetail(editDetailP2kb),
    editDetailP2kb_error: getP2kbEditDetailError(editDetailP2kb),
    formValue,
    initialValues,
  };
}

export default connect(mapStateToProps, { listActivity, uploadP2kb, editDetailP2kb })(
  withRouter(ModalEditDetailSKP_PengabdianMasyarakat)
);
