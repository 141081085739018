import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, reset } from "redux-form";
import {
  renderSelectWithLabel,
  renderDatepickerWithLabel,
  renderInputWithLabel,
  renderFileForm,
} from "../../../components/form/FormComponent";
import { listActivity } from "../../../actions/master-data/activity_action";
import {
  getListActivity,
  getActivityPending,
  getActivityError
} from "../../../reducers/master-data/activity_reducer";
import { uploadP2kb, editDetailP2kb } from "../../../actions/p2kb/p2kb_action";
import {
  getP2kbUploadPending,
  getP2kbUpload,
  getP2kbUploadError,
} from "../../../reducers/p2kb/p2kb_reducer";
import DetailContentLihatBerkas from "../../../components/detail/DetailContentLihatBerkas";
import ModalDetailDokumenSKP from "../Modal_document_pdf";
import ModalDetailDokumen from "../Modal_document";
import Loading from "../../../components/global-components/LoadingComponent";

class ModalEditDetailSKP_MengujiMahasiswa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempSKPData: JSON.parse(localStorage.getItem("skp_add_data_temp")),
      skp_detail: JSON.parse(localStorage.getItem("skp_detail")),
      modalDetailDokumenSKPShow: false,
      berkas: "",
      berkas_button: false,
      isDoneUploading: true,
      imgUrl: "",
      modal_title: "",
      selected_activity: null,
      selected_scale: null,
      selected_peran: null,
      selected_duration: null,
      last_activity_skp_detail_id: null,
      values_id: null,
      spek_berkas: null
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.handleChangeScale = this.handleChangeScale.bind(this);
    this.updateState = this.updateState.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ imgUrl: imgUrl });
    this.setState({ modal_title: modal_title });
    if (imgUrl.split(".")[1] === "pdf") {
      this.setState({ modalDetailDokumenSKPShow: true });
    } else {
      this.setState({ modalDetailDokumenShow: true });
    }
  }

  handleChangeScale(values) {
    let listActivity_selected = "";
    if (this.state.skp_detail) {
      listActivity_selected = this.props.listActivity_data.data.find(
        (item) => item.id === this.state.skp_detail.activity_id
      );
    } else {
      listActivity_selected = this.props.listActivity_data.data.find(
        (item) => item.id === this.state.tempSKPData.activity.value
      );
    }
    let childrens_selected = listActivity_selected.childrens.find(
      (item) => item.id === values.value
    );

    this.setState({ last_activity_skp_detail_id: childrens_selected.id });
    this.setState({ values_id: childrens_selected.values[0].id });
    let skp_must_be_multipied =
      childrens_selected.values[0].skp_must_be_multipied;
    let value_multipied = childrens_selected.values[0].value_multipied;
    if (skp_must_be_multipied === 0) {
      this.props.change("skp_total", childrens_selected.values[0].value_skp);
      this.setState({ skp: childrens_selected.values[0].value_skp });
    } else {
      this.props.change(
        "skp_total",
        Math.round(
          this.props.formValue.total_for_calculate_skp * value_multipied
        )
      );
      this.setState({
        skp: Math.round(
          this.props.formValue.total_for_calculate_skp * value_multipied
        ),
      });
    }
  }

  handleUploadFile(values) {
    const formName = values.target.name;
    // if (formName === "berkas_sip") {
    //     this.setState({ berkas_sip: "Berkas SIP berhasil diunggah" })
    //     this.props.change("berkas_sip", "dvorak");
    // } else if (formName === "berkas") {
    //     this.setState({ berkas: "Berkas Medis berhasil diunggah" })
    //     this.props.change("berkas", "qwerty");
    // }
    // if (values.target.files[0].size < 2000000) {
    this.setState({ isDoneUploading: false });
    this.setState({ berkas_button: true });
    if (formName === "berkas") {
      this.setState({ berkas: <Loading /> });
    }
    const callback = () => {
      this.setState({ isDoneUploading: true });
      this.setState({ berkas_button: false });
      if (this.props.uploadP2kb_data) {
        if (formName === "berkas") {
          this.props.change("berkas", this.props.uploadP2kb_data);
          this.setState({ berkas: <Loading done={true} /> });
          setTimeout(
            () =>
              this.setState({
                berkas: (
                  <DetailContentLihatBerkas
                    onClick={() =>
                      this.handleOpenDocument(
                        this.props.formValue.berkas,
                        "Keterangan Dokumen"
                      )
                    }
                  />
                ),
              }),
            2000
          );
        }
      }
    };
    const error = () => {
      this.setState({ isDoneUploading: true });
      this.setState({ berkas_button: false });
      if (this.props.uploadP2kb_error) {
        if (formName === "berkas") {
          this.props.change("berkas", null);
          this.setState({ berkas: <Loading done={true} failed={true} /> });
        }
      }
    };
    this.props.uploadP2kb(values.target.files[0], callback, error);
    this.props.change("spek_berkas", values.target.files[0]);
    // } else {
    //     if (formName === "berkas") {
    //         this.setState({ berkas: "Batas maksimal ukuran file 2 MB!" })
    //     }
    // }
  }

  handleSubmit(values) {
    const {
      onHide,
      handleRefresh,
      selectedSKP,
    } = this.props;

    if (selectedSKP.status) {
      let details_tobesent = {
        id: selectedSKP.id,
        activity_date: values.activity_date,
        last_activity_skp_detail_id: values.scale.value.value
          ? values.scale.value.value
          : values.scale.value,
        values_id: values.scale.value.value
          ? values.scale.value.value
          : values.scale.value,
        institution_name: values.institution_name,
        letter_number: values.letter_number,
        attachments: [
          {
            label: "Keterangan Dokumen",
            filename: values.berkas,
          },
        ],
      };
      const callback = () => {
        onHide();
        handleRefresh();
      };
      this.props.editDetailP2kb(details_tobesent, callback);
    } else {
      let details_tobesent = {
        activity_date: values.activity_date,
        hasEdit: true,
        last_activity_skp_detail_id: values.scale.value.value
          ? values.scale.value.value
          : values.scale.value,
        values_id: values.scale.value.value
          ? values.scale.value.value
          : values.scale.value,
        institution_name: values.institution_name,
        letter_number: values.letter_number,
        member_p2kb_id : JSON.parse(localStorage.getItem("skp_edit_detail_skp_menguji_mahasiswa")).member_p2kb_id,
        attachments: [
          {
            label: "Keterangan Dokumen",
            filename: values.berkas,
          },
        ],
      };
      let details_tobeshown = {
        ...details_tobesent,
        skp: values.skp_total,
        scale: values.scale.label,
        no: this.props.formValue.no,
        total_skp: values.skp_total,
        activity_id: this.props.formValue.activity_id,
      };
      this.props.handleEditDetail(details_tobeshown, details_tobesent);
      this.props.onHide();
    }
  }

  updateState() {
    if (this.state.last_activity_skp_detail_id == null) {
      if (this.props.formValue.scale) {
        this.setState({
          last_activity_skp_detail_id: this.props.formValue.scale.value,
        });
        this.setState({
          values_id: this.props.formValue.scale.value,
        });
      }
    }
    if (this.state.berkas === "") {
      if (this.props.formValue.berkas) {
        this.setState({
          berkas: (
            <DetailContentLihatBerkas
              onClick={() =>
                this.handleOpenDocument(
                  this.props.formValue.berkas,
                  "Keterangan Dokumen"
                )
              }
            />
          ),
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    this.updateState();
  }

  componentDidMount() {
    this.props.listActivity({ length: 999 });
  }

  render() {
    const {
      show,
      onHide,
      handleSubmit,
      uploadP2kb_pending,
      listActivity_data,
      formValue,
    } = this.props;
    let scaleOptions,
      yearOptions = [];
    let currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= 2000; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }
    if (listActivity_data) {
      if (formValue.activity_id) {
        let selected_activity = listActivity_data.data.find(
          (item) => item.id === formValue.activity_id
        );
        scaleOptions = selected_activity.childrens.map((item) => ({
          label: item.name,
          value: item.id,
        }));
      }
    }
    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalDetailDokumenSKP
          show={this.state.modalDetailDokumenSKPShow}
          onHide={() => this.setState({ modalDetailDokumenSKPShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
        />
        <ModalDetailDokumen
          show={this.state.modalDetailDokumenShow}
          onHide={() => this.setState({ modalDetailDokumenShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
        />
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Edit SKP</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              <>
                <Field
                  name="activity_date"
                  placeholder="Masukkan Tanggal Kegiatan"
                  label="Tanggal Kegiatan"
                  component={renderDatepickerWithLabel}
                />
                <Field
                  name="institution_name"
                  placeholder="Masukkan Nama Institusi"
                  label="Nama Institusi"
                  component={renderInputWithLabel}
                />
                <Field
                  name="scale"
                  placeholder="Pilih Mahasiswa Bimbingan"
                  label="Mahasiswa Bimbingan"
                  options={scaleOptions}
                  onChange={this.handleChangeScale}
                  isClearable={false}
                  component={renderSelectWithLabel}
                />
                <Field
                  name="letter_number"
                  placeholder="Masukkan Nomor Surat"
                  label="Nomor Surat"
                  component={renderInputWithLabel}
                />
                <Field
                  name="skp_total"
                  placeholder="Jumlah SKP"
                  label="Jumlah SKP"
                  disable={true}
                  component={renderInputWithLabel}
                />
                <Field
                  name="berkas"
                  type="file"
                  fileType="skp"
                  title="Keterangan Dokumen"
                  disabled={this.state.berkas_button}
                  isDone={this.state.isDoneUploading}
                  message={
                    this.props.formValue.berkas === undefined
                      ? "Image atau PDF maksimal 1 MB"
                      : this.state.berkas === null
                        ? "Image atau PDF maksimal 1 MB"
                        : this.state.berkas
                  }
                  onChange={this.handleUploadFile}
                  component={renderFileForm}
                />
              </>
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={uploadP2kb_pending}
                className={css(styles.approveButton)}
                startIcon={
                  uploadP2kb_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const maxFileSize = 1000000; //1MB
  const typeImage = /image/
  const fileType = "application/pdf"
  const {
    activity_date,
    institution_name,
    letter_number,
    scale,
    berkas,
    spek_berkas
  } = values;
  const errors = {};
  if (!activity_date) {
    errors.activity_date = "Tanggal kegiatan wajib diisi";
  }
  if (!institution_name) {
    errors.institution_name = "Nama Institusi wajib diisi";
  }
  if (!letter_number) {
    errors.letter_number = "Nomor Surat wajib diisi";
  }
  if (!scale) {
    errors.scale = "Mahasiswa Bimbingan wajib diisi";
  }
  if (!berkas) {
    errors.berkas = "Berkas Pendukung wajib diisi";
  }
  else{
    if (spek_berkas && (!spek_berkas.type.match(typeImage) && spek_berkas.type !== fileType)){
      errors.berkas = "Format file hanya bisa Image atau PDF"
    }
    else if (spek_berkas && (spek_berkas.size > maxFileSize)){
      errors.berkas = "File lebih dari 1 MB"
    }
  }
  return errors;
}

const afterSubmit = (result, dispatch) =>
  dispatch(reset("ModalAddDetailSKP_MengujiMahasiswa"));

ModalEditDetailSKP_MengujiMahasiswa = reduxForm({
  form: "ModalEditDetailSKP_MengujiMahasiswa",
  validate: validate,
  shouldError: () => true,
  onSubmitSuccess: afterSubmit,
  enableReinitialize: true,
})(ModalEditDetailSKP_MengujiMahasiswa);

function mapStateToProps(state) {
  const { listActivity, uploadP2kb, selectedSKP } = state;
  const selector = formValueSelector("ModalEditDetailSKP_MengujiMahasiswa");
  const formValue = selector(
    state,
    "skp_total",
    "scale",
    "last_activity_skp_detail_id",
    "values_id",
    "berkas",
    "no",
    "activity_id",
    "spek_berkas"
  );
  let initialValues = {};
  let form_json = null;
  if (selectedSKP) {
    if (selectedSKP.isEditProfesi) {
      if (!selectedSKP.isDetail) {
        initialValues = {
          activity_date: selectedSKP.activity_date,
          institution_name: selectedSKP.institution_name,
          letter_number: selectedSKP.letter_number,
          scale: {
            label: selectedSKP.scale,
            value: selectedSKP.last_activity_skp_detail_id,
          },
          skp_total: selectedSKP.skp ? selectedSKP.skp : selectedSKP.total_skp,
          berkas: selectedSKP.attachments[0].filename,
          no: selectedSKP.no,
        };
      } else {
        if (selectedSKP.form_json) {
          form_json = JSON.parse(selectedSKP.form_json);
          initialValues = {
            activity_id: selectedSKP.mst_activity_skp_type_detail_data
              ? selectedSKP.mst_activity_skp_type_detail_data.activity_id
              : selectedSKP.activity_id,
            activity_date: selectedSKP.activity_date,
            institution_name: form_json.institution_name,
            letter_number: form_json.letter_number,
            scale: {
              label: selectedSKP.mst_activity_skp_type_detail_data.name,
              value: selectedSKP.mst_activity_skp_type_detail_data.id,
            },
            skp_total: selectedSKP.skp
              ? selectedSKP.skp
              : selectedSKP.total_skp,
            berkas: selectedSKP.attachments[0].filename,
            no: selectedSKP.no,
          };
        } else {
          initialValues = {
            activity_id: selectedSKP.mst_activity_skp_type_detail_data
              ? selectedSKP.mst_activity_skp_type_detail_data.activity_id
              : selectedSKP.activity_id,
            activity_date: selectedSKP.activity_date,
            institution_name: selectedSKP.institution_name,
            letter_number: selectedSKP.letter_number,
            scale: {
              label: selectedSKP.scale,
              value: selectedSKP.values_id,
            },
            skp_total: selectedSKP.skp
              ? selectedSKP.skp
              : selectedSKP.total_skp,
            berkas: selectedSKP.attachments[0].filename,
            no: selectedSKP.no,
          };
        }
      }
    }
  }
  return {
    listActivity_pending: getActivityPending(listActivity),
    listActivity_data: getListActivity(listActivity),
    listActivity_error: getActivityError(listActivity),
    uploadP2kb_pending: getP2kbUploadPending(uploadP2kb),
    uploadP2kb_data: getP2kbUpload(uploadP2kb),
    uploadP2kb_error: getP2kbUploadError(uploadP2kb),
    selectedSKP,
    formValue,
    initialValues,
  };
}

export default connect(mapStateToProps, {
  listActivity,
  uploadP2kb,
  editDetailP2kb,
})(withRouter(ModalEditDetailSKP_MengujiMahasiswa));
