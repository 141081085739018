import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, reset } from "redux-form";
import {
  renderSelectWithLabel,
  renderDatepickerWithLabel,
  renderInputWithLabel,
  renderFileForm,
} from "../../../components/form/FormComponent";
import { listActivity } from "../../../actions/master-data/activity_action";
import {
  getListActivity,
  getActivityPending,
  getActivityError,
} from "../../../reducers/master-data/activity_reducer";
import { uploadP2kb, editDetailP2kb } from "../../../actions/p2kb/p2kb_action";
import {
  getP2kbUploadPending,
  getP2kbUpload,
  getP2kbUploadError,
  getP2kbEditDetailPending,
  getP2kbEditDetail,
  getP2kbEditDetailError,
} from "../../../reducers/p2kb/p2kb_reducer";
import ModalDetailDokumenSKP from "../Modal_document_pdf";
import ModalDetailDokumen from "../Modal_document";
import Loading from "../../../components/global-components/LoadingComponent";
import DetailContentLihatBerkas from "../../../components/detail/DetailContentLihatBerkas";

class ModalEditDetailSKP_MembacaJurnal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempSKPData: JSON.parse(localStorage.getItem("skp_add_data_temp")),
      skp_detail: JSON.parse(localStorage.getItem("skp_detail")),
      modalDetailDokumenSKPShow: false,
      modalDetailDokumenShow: false,
      last_activity_skp_detail_id: "",
      values_id: "",
      berkas_sip: "",
      berkas_medis: "",
      skp: 1,
      imgUrl: "",
      modal_title: "",
      isDoneUploading: true,
      berkas_medis_button: null,
      spek_berkas_medis: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeTingkatan = this.handleChangeTingkatan.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ imgUrl: imgUrl })
    this.setState({ modal_title: modal_title })
    if (imgUrl.split('.')[1] === "pdf") {
      this.setState({ modalDetailDokumenSKPShow: true })
    } else {
      this.setState({ modalDetailDokumenShow: true })
    }
  }

  handleChangeTingkatan(values) {
    this.props.change("skp_total", 1);
    this.setState({ skp: 1 });
  }

  handleUploadFile(values) {
    const formName = values.target.name;
    this.setState({ berkas_medis_button: true })
    this.setState({ isDoneUploading: false })
    if (formName === "berkas_medis") {
      this.setState({ berkas_medis: <Loading /> })
    }
    const callback = () => {
      this.setState({ isDoneUploading: true })
      this.setState({ berkas_medis_button: false })
      if (this.props.uploadP2kb_data) {
        if (formName === "berkas_medis") {
          this.props.change("berkas_medis", this.props.uploadP2kb_data);
          this.setState({ berkas_medis: <Loading done={true} /> })
          setTimeout(() => this.setState({ berkas_medis: <DetailContentLihatBerkas onClick={() => this.handleOpenDocument(this.props.formValue.berkas_medis, "Berkas")} /> }), 2000)
        }
      }
    };
    const error = () => {
      this.setState({ isDoneUploading: true })
      this.setState({ berkas_medis_button: false })
      if (this.props.uploadP2kb_error) {
        if (formName === "berkas_medis") {
          this.props.change("berkas_medis", null);
          this.setState({ berkas_medis: <Loading done={true} failed={true} /> })
        }
      }
    }
    this.props.uploadP2kb(values.target.files[0], callback, error);
    this.props.change("spek_berkas_medis", values.target.files[0]);
  }


  handleSubmit(values) {
    const { handleEditDetail, onHide, handleRefresh, editDetailP2kb, formValue } = this.props;
    if (formValue.isDetail && formValue.status && formValue.status !== 0) {
      const param = {
        id: values.id,
        activity_date: values.activity_date,
        material_title: values.material_title,
        magazine_name: values.magazine_name,
        number: values.number,
        publication_year: values.publication_year.value,
        issn_number: values.issn_number,
        publisher: values.publisher,
        type_of_magazine: values.type_of_magazine.value,
        no: JSON.parse(localStorage.getItem("skp_edit_detail_skp_membaca_jurnal"))
          .no,
        attachments: [
          {
            label: "Pernyataan Lulus Dari Jurnal",
            filename: values.berkas_medis,
          },
        ],
      }
      const callback = () => {
        onHide();
        handleRefresh();
      }
      editDetailP2kb(param, callback);
    } else {
      let details_tobesent = {
        activity_date: values.activity_date,
        material_title: values.material_title,
        magazine_name: values.magazine_name,
        number: values.number,
        hasEdit: true,
        publication_year: values.publication_year.value,
        issn_number: values.issn_number,
        publisher: values.publisher,
        type_of_magazine: values.type_of_magazine.value,
        no: JSON.parse(localStorage.getItem("skp_edit_detail_skp_membaca_jurnal"))
          .no,
        member_p2kb_id : JSON.parse(localStorage.getItem("skp_edit_detail_skp_membaca_jurnal")).member_p2kb_id,
        attachments: [
          {
            label: "Pernyataan Lulus Dari Jurnal",
            filename: values.berkas_medis,
          },
        ],
      };
      let details_tobeshown = {
        ...details_tobesent,
        type_of_magazine_label: values.type_of_magazine.label,
        skp: values.skp_total,
        no: JSON.parse(localStorage.getItem("skp_edit_detail_skp_membaca_jurnal"))
          .no,
        tableData: JSON.parse(
          localStorage.getItem("skp_edit_detail_skp_membaca_jurnal")
        ).tableData,
      };
      handleEditDetail(details_tobeshown, details_tobesent);
      localStorage.removeItem("skp_edit_detail_skp_membaca_jurnal");
      onHide();
    }
  }

  updateState() {
    if (this.state.last_activity_skp_detail_id === "") {
      if (
        JSON.parse(
          localStorage.getItem("skp_edit_detail_skp_membaca_jurnal") !== null
        )
      ) {
        this.setState({
          last_activity_skp_detail_id: JSON.parse(
            localStorage.getItem("skp_edit_detail_skp_membaca_jurnal")
          ).last_activity_skp_detail_id,
        });
        this.setState({
          values_id: JSON.parse(
            localStorage.getItem("skp_edit_detail_skp_membaca_jurnal")
          ).values_id,
        });
        this.setState({
          berkas_medis: (
            <DetailContentLihatBerkas
              onClick={() =>
                this.handleOpenDocument(
                  JSON.parse(
                    localStorage.getItem("skp_edit_detail_skp_membaca_jurnal")
                  ).attachments[0].filename,
                  "Berkas"
                )
              }
            />
          ),
        });
        this.setState({
          skp: JSON.parse(
            localStorage.getItem("skp_edit_detail_skp_membaca_jurnal")
          ).total_skp,
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    this.updateState();
  }

  componentDidMount() {
    this.props.listActivity();
  }

  render() {
    const {
      show,
      onHide,
      handleSubmit,
      uploadP2kb_pending,
    } = this.props;
    let jenisOptions = [];
    jenisOptions = [
      {
        label: "Online",
        value: "online",
      },
      {
        label: "Offline",
        value: "offline",
      },
    ];

    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }
    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalDetailDokumenSKP
          show={this.state.modalDetailDokumenSKPShow}
          onHide={() => this.setState({ modalDetailDokumenSKPShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
        />
        <ModalDetailDokumen
          show={this.state.modalDetailDokumenShow}
          onHide={() => this.setState({ modalDetailDokumenShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
        />
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Edit SKP</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              <>
                <Field
                  name="activity_date"
                  placeholder="Masukkan Tanggal Kegiatan"
                  label="Tanggal Kegiatan"
                  component={renderDatepickerWithLabel}
                />
                <Field
                  name="material_title"
                  placeholder="Judul Materi"
                  label="Judul Materi"
                  component={renderInputWithLabel}
                />
                <Field
                  name="magazine_name"
                  placeholder="Nama Majalah"
                  label="Nama Majalah"
                  component={renderInputWithLabel}
                />
                <Field
                  name="number"
                  placeholder="Volume atau Nomor"
                  label="Volume atau Nomor"
                  component={renderInputWithLabel}
                />
                <Field
                  name="publication_year"
                  placeholder="Tahun Terbit"
                  label="Tahun Terbit"
                  isClearable={false}
                  options={yearOptions}
                  component={renderSelectWithLabel}
                />
                <Field
                  name="issn_number"
                  placeholder="Nomor ISSN"
                  label="Nomor ISSN"
                  component={renderInputWithLabel}
                />
                <Field
                  name="publisher"
                  placeholder="Penerbit"
                  label="Penerbit"
                  component={renderInputWithLabel}
                />
                <Field
                  name="type_of_magazine"
                  placeholder="Jenis Majalah"
                  label="Jenis Majalah"
                  onChange={this.handleChangeTingkatan}
                  isClearable={false}
                  options={jenisOptions}
                  component={renderSelectWithLabel}
                />
                <Field
                  name="skp_total"
                  placeholder="Jumlah SKP"
                  label="Jumlah SKP"
                  disable={true}
                  component={renderInputWithLabel}
                />
                <Field
                  name="berkas_medis"
                  type="file"
                  fileType="skp"
                  title="Upload Berkas"
                  disabled={this.state.berkas_medis_button}
                  isDone={this.state.isDoneUploading}
                  message={
                    this.props.formValue.berkas_medis === undefined
                      ? "Image atau PDF maksimal 1 MB"
                      : this.state.berkas_medis === null
                        ? "Image atau PDF maksimal 1 MB"
                        : this.state.berkas_medis
                  }
                  onChange={this.handleUploadFile}
                  component={renderFileForm}
                />
              </>
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={uploadP2kb_pending}
                className={css(styles.approveButton)}
                startIcon={
                  uploadP2kb_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const maxFileSize = 1000000; //1MB
  const typeImage = /image/
  const fileType = "application/pdf"
  const {
    activity_date,
    material_title,
    magazine_name,
    number,
    publication_year,
    issn_number,
    publisher,
    type_of_magazine,
    berkas_medis,
    spek_berkas_medis,
  } = values;
  const errors = {};
  const success = {};
  if (values.issn_number !== null) {
    var regexWrong = /[a-z]|[A-Z]/;
    var regexOrigin = /^[0-9_-]$/;

    if (regexWrong.test(values.issn_number)) {
      errors.issn_number = "Nomor ISSN Tidak Valid";
    } else if (regexOrigin.test(values.issn_number)) {
      success.issn_number = "Nomor ISSN Tidak Valid";
    }
  }
  if (!activity_date) {
    errors.activity_date = "Tanggal kegiatan wajib diisi";
  }
  if (!material_title) {
    errors.material_title = "Judul Materi wajib diisi";
  }
  if (!magazine_name) {
    errors.magazine_name = "Nama Majalah wajib diisi";
  }
  if (!number) {
    errors.number = "Volume atau Nomor wajib diisi";
  }
  if (!publication_year) {
    errors.publication_year = "Tahun Terbit wajib diisi";
  }
  if (!issn_number) {
    errors.issn_number = "Nomor ISSN wajib diisi";
  }
  if (!publisher) {
    errors.publisher = "Penerbit wajib diisi";
  }
  if (!type_of_magazine) {
    errors.type_of_magazine = "Jenis Majalah wajib diisi";
  }
  if (!berkas_medis) {
    errors.berkas_medis = "Berkas wajib diisi";
  }
  else {
    if (spek_berkas_medis && (!spek_berkas_medis.type.match(typeImage) && spek_berkas_medis.type !== fileType)){
      errors.berkas_medis = "Format file hanya bisa Image atau PDF"
    }
    else if (spek_berkas_medis && (spek_berkas_medis.size > maxFileSize)){
      errors.berkas_medis = "File lebih dari 1 MB"
    }
  }
  return errors;
}

const afterSubmit = (result, dispatch) =>
  dispatch(reset("ModalAddDetailSKP_MembacaJurnal"));

ModalEditDetailSKP_MembacaJurnal = reduxForm({
  form: "ModalEditDetailSKP_MembacaJurnal",
  validate: validate,
  shouldError: () => true,
  onSubmitSuccess: afterSubmit,
  enableReinitialize: true,
})(ModalEditDetailSKP_MembacaJurnal);

function mapStateToProps(state) {
  const { listActivity, uploadP2kb, editDetailP2kb } = state;
  const selector = formValueSelector("ModalEditDetailSKP_MembacaJurnal");
  const formValue = selector(
    state,
    "tingkatan",
    "total_for_calculate_skp",
    "berkas_medis",
    "status",
    "isDetail",
    "id",
    "spek_berkas_medis"
  );
  const editData = JSON.parse(
    localStorage.getItem("skp_edit_detail_skp_membaca_jurnal")
  );

  let initialValues = {};
  if (editData) {
    if (!editData.isDetail) {
      initialValues = {
        activity_date: editData.activity_date,
        material_title: editData.material_title,
        magazine_name: editData.magazine_name,
        number: editData.number,
        publication_year: {
          label: editData.publication_year,
          value: editData.publication_year,
        },
        issn_number: editData.issn_number,
        publisher: editData.publisher,
        type_of_magazine: {
          label: editData.type_of_magazine_label,
          value: editData.type_of_magazine,
        },
        skp_total: editData.skp,
        berkas_medis: editData.attachments[0].filename,
      };
    } else {
      if (editData.form_json) {
        let result = JSON.parse(editData.form_json);
        initialValues = {
          status: editData.status,
          isDetail: true,
          id: editData.id,
          activity_date: editData.activity_date,
          material_title: result.material_title,
          magazine_name: result.magazine_name,
          number: result.number,
          publication_year: {
            label: result.publication_year,
            value: result.publication_year,
          },
          issn_number: result.issn_number,
          publisher: result.publisher,
          type_of_magazine: {
            label: result.type_of_magazine,
            value: result.type_of_magazine,
          },
          skp_total: editData.total_skp,
          berkas_medis: editData.attachments[0].filename,
        };
      } else {
        initialValues = {
          status: editData.status,
          isDetail: true,
          id: editData.id,
          activity_date: editData.activity_date,
          material_title: editData.material_title,
          magazine_name: editData.magazine_name,
          number: editData.number,
          publication_year: {
            label: editData.publication_year,
            value: editData.publication_year,
          },
          issn_number: editData.issn_number,
          publisher: editData.publisher,
          type_of_magazine: {
            label: editData.type_of_magazine_label,
            value: editData.type_of_magazine,
          },
          skp_total: editData.total_skp,
          berkas_medis: editData.attachments[0].filename,
        };

      }
    }
  }
  return {
    listActivity_pending: getActivityPending(listActivity),
    listActivity_data: getListActivity(listActivity),
    listActivity_error: getActivityError(listActivity),
    uploadP2kb_pending: getP2kbUploadPending(uploadP2kb),
    uploadP2kb_data: getP2kbUpload(uploadP2kb),
    uploadP2kb_error: getP2kbUploadError(uploadP2kb),
    editDetailP2kb_pending: getP2kbEditDetailPending(editDetailP2kb),
    editDetailP2kb_data: getP2kbEditDetail(editDetailP2kb),
    editDetailP2kb_error: getP2kbEditDetailError(editDetailP2kb),
    formValue,
    initialValues,
  };
}

export default connect(mapStateToProps, { listActivity, uploadP2kb, editDetailP2kb })(
  withRouter(ModalEditDetailSKP_MembacaJurnal)
);
