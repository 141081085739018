import React, { Component } from "react";
import { Row } from "simple-flexbox";
import ContentContainer from "../../../components/container/ContainerComponent";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import HeaderDetailP2kbComponent from "../../../components/p2kb/HeaderDetailP2kbComponent";
import IconError from "../../../assets/icon-error";
import IconDocumentP2KB from "../../../assets/icon-document-p2kb";
import IconLaunch from "../../../assets/icon-launch";
import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

import moment from "moment";
import localization from "moment/locale/id";

import { StyleSheet, css } from "aphrodite";
import DetailButtonComponent from "../../../components/global-components/DetailButtonComponent";
import ModalDetailDokumenSKP from "../Modal_document_pdf";
import ModalDetailDokumen from "../Modal_document";
import ModalDeleteSKP from "../ModalDeleteSKP";
import {
  p2kbList,
  addP2kb,
  editP2kb,
  editP2kb2,
  deleteP2kb,
  setSelectedSKP,
} from "../../../actions/p2kb/p2kb_action";
import {
  getP2kbListPending,
  getP2kbList,
  getP2kbListError,
  getP2kbDeletePending,
  getP2kbDelete,
  getP2kbDeleteError,
  getP2kbEditPending,
  getP2kbEdit,
  getP2kbEditError,
  getP2kbAddPending,
  getP2kbAdd,
  getP2kbAddError
} from "../../../reducers/p2kb/p2kb_reducer";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import ModalAddDetailSKPMenciptakanAlat from "./Modal_add_detail_skp_menciptakan_alat";
import ModalRejectSKP from "../Modal_reject_skp";
import ModalEditDetailSKPMenciptakanAlat from "./Modal_edit_detail_skp_menciptakan_alat";
import { toast } from "react-toastify";
import ModalDetailSKPMenciptakanAlat from "./Modal_detail_skp_menciptakan_alat";
import TableStatusComponent from "../../../components/global-components/TableStatusComponent";
import CircularProgress from "@material-ui/core/CircularProgress";

class DetailP2kb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalDetailDokumenSKPShow: false,
      modalDetailDokumenShow: false,
      modalRejectSKPShow: false,
      ModalAddDetailSKP_MenciptakanAlatShow: false,
      ModalEditDetailSKP_MenciptakanAlatShow: false,
      ModalDetailSKP_MenciptakanAlatShow: false,
      ModalDeleteSKPShow: false,
      DeletedData: null,
      imgUrl: "",
      hasEdit: false,
      hasAdd:false,
      modal_title: "",
      data: [],
      p2kb_details: [],
      new_p2kb : [],
      skp_total: 0,
      edit_id: "",
      activity_name: '',
      activity_type: '',
      realm_activity_name: '',
      edit_activity_id: "",
      forRefresh: false,
      revisionReason: null,
      revisionImage: null,
      isLoad: true,
      start_date: localStorage.getItem('start_date') ? localStorage.getItem('start_date') : '',
      end_date: localStorage.getItem('end_date') ? localStorage.getItem('end_date') : '',
    };
    this.handleEditSkpDetail = this.handleEditSkpDetail.bind(this);
    this.handleEditSkpDetailModal = this.handleEditSkpDetailModal.bind(this);
    this.handleDeleteSkpDetail = this.handleDeleteSkpDetail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleAddSkpDetail = this.handleAddSkpDetail.bind(this);
  }

  handleAddSkpDetail(values_tobeshown, values_tobesent) {
    let data_tobeshown = {
      ...values_tobeshown,
      no: this.state.data.length + 1,
      tableData: { id: this.state.data.length },
    };
    let data_tobesent = { ...values_tobesent, no: this.state.data.length + 1 };
    let newData_tobeshown = [...this.state.data, data_tobeshown];
    // let newData_tobesent = [...this.state.p2kb_details, data_tobesent];
    let newP2kb_tobesent = [...this.state.new_p2kb, data_tobesent];
    let sum_skp_total = this.state.skp_total + data_tobeshown.skp;
    this.setState({ data: newData_tobeshown });
    // this.setState({ p2kb_details: newData_tobesent });
    this.setState({ new_p2kb : newP2kb_tobesent});
    this.setState({ skp_total: sum_skp_total });
    // this.setState({ hasEdit: true })
    this.setState({ hasAdd: true });
  }

  handleRefresh() {
    const param = {
      activity_id: this.props.match.params.id,
      start_date: this.state.start_date,
      end_date: this.state.end_date
    };

    const callback = () => {
      if (this.props.p2kbList_data.data[0] !== undefined) {
        const newData = this.props.p2kbList_data.data[0].p2kb_details.map((item, index) => ({
          ...item, no: index + 1
        }));
        this.setState({ data: newData })
        this.setState({ p2kb_details: newData })
        this.setState({ edit_id: this.props.p2kbList_data.data[0].id })
        this.setState({ edit_activity_id: this.props.p2kbList_data.data[0].activity_id })
        this.setState({ skp_total: this.props.p2kbList_data.data[0].total_skp })
      }
      else {
        this.props.history.push(`/p2kb/add`);
      }
    };
    this.props.p2kbList(param, callback);
  }

  handleOpenRevision(data) {
    this.setState({ revisionImage: data.last_reject_filename });
    this.setState({ revisionReason: data.last_reject_notes });
    this.setState({ modalRejectSKPShow: true });
  }

  isEqual(oldData, newData) {
    if (oldData.length !== newData.length) {
      return false;
    } else {
      for (var i = 0; i < oldData.length; i++)
        if (JSON.stringify(oldData[i]) !== JSON.stringify(newData[i])) {
          return false;
        }
      return true;
    }
  }

  handleEditSkpDetailModal(data) {
    let dataToBeModified;
    if (data.p2kb_data != null){
        dataToBeModified = { ...data, isDetail: true }
    }else{
        dataToBeModified = data
    }
    localStorage.setItem(
      "skp_edit_detail_skp_menciptakan_alat",
      JSON.stringify(dataToBeModified)
    );
    this.setState({ ModalEditDetailSKP_MenciptakanAlatShow: true });
  }

  handleSubmit() {
    this.setState({ hasSubmit: true })
    let old_p2kb_details = this.props.p2kbList_data.data[0].p2kb_details.map(
      (item, index) => ({
        ...item,
        no: index + 1,
        ...item,
        tableData: { id: index },
      })
    );
    if (this.isEqual(old_p2kb_details, this.state.p2kb_details) && this.state.new_p2kb == null) {
      toast.error(
        "Tidak ada perubahan data! untuk melakukan submit ulang pastikan ubah data terlebih dahulu",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    } else {
      if(this.state.new_p2kb.length !== 0){
        const param_addSKP = {
          activity_id: this.state.edit_activity_id,
          p2kb_details: this.state.new_p2kb
        }
        const callback = () => {
            this.props.history.push(`/skp`)
        }
        this.props.addP2kb(param_addSKP, callback)
      }
      if(!(this.isEqual(old_p2kb_details, this.state.p2kb_details))){
        let newData_tobesent = this.state.p2kb_details.filter(item => item.hasEdit === true);
        const member_p2kb_id = [];
        newData_tobesent.forEach((item) => {
            if (!member_p2kb_id.includes(item.member_p2kb_id)) {
                member_p2kb_id.push(item.member_p2kb_id);
            }
        });
        member_p2kb_id.map((id) => {
            const p2kb_detail = newData_tobesent.filter(item => item.member_p2kb_id === id);
            this.props.editP2kb2({
                id: id,
                activity_id: this.state.edit_activity_id, // You may need to adjust this value
                p2kb_details: p2kb_detail,
              })
        });
        this.props.history.push(`/skp`)
    }
      // const param_editSKP = {
      //   id: this.state.edit_id,
      //   activity_id: this.state.edit_activity_id,
      //   p2kb_details: this.state.p2kb_details,
      // };
      // const callback = () => {
      //   this.props.history.push(`/skp`);
      // };
      // this.props.editP2kb(param_editSKP, callback);
    }
  }

  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ imgUrl: imgUrl });
    this.setState({ modal_title: modal_title });
    if (imgUrl.split(".")[1] === "pdf") {
      this.setState({ modalDetailDokumenSKPShow: true });
    } else {
      this.setState({ modalDetailDokumenShow: true });
    }
  }

  handleSkpDetail(data) {
    data = { ...data, isMenciptakanAlat: true };
    this.props.setSelectedSKP(data);
    this.setState({ ModalDetailSKP_MenciptakanAlatShow: true });
  }

  handleOpenModalDelete(data) {
    this.setState({ data, ModalDeleteSKPShow : true });
    this.setState({DeletedData: data});
  }

  handleDeleteSkpDetail(data) {
    if (data.id && !this.state.hasEdit) {
      const param = {
        id: data.id
      }
      const callback = () => {
        this.handleRefresh()
      };
      this.props.deleteP2kb(param, callback);
    }
    else {
      let newData_tobeshown = this.state.data.filter(
        (item) => item.no !== data.no
      );
      // let newData_tobesent = this.state.p2kb_details.filter(
      //   (item) => item.no !== data.no
      // );
      let newData_tobesent = this.state.new_p2kb.filter(item => item.no !== data.no)
      let skp_total = 0;
      newData_tobeshown = newData_tobeshown.map((item, index) => ({
        ...item,
        no: index + 1,
      }));
      newData_tobesent = newData_tobesent.map((item, index) => ({
        ...item,
        no: this.state.p2kb_details.length + index + 1,
      }));
      newData_tobeshown.forEach((item) => {
        skp_total += item.skp;
      });
      this.setState({ skp_total: skp_total });
      this.setState({ data: newData_tobeshown });
      // this.setState({ p2kb_details: newData_tobesent });
      this.setState({ new_p2kb : newData_tobesent });
      if(newData_tobesent.length === 0){
          this.setState({ hasAdd : false})
      }
    }
  }

  handleEditSkpDetail(values_tobeshown, values_tobesent) {
    if (values_tobesent.hasEdit) {
      this.setState({ hasEdit: true })
    }
    let add_total_skp = {
      ...values_tobeshown,
      total_skp: values_tobeshown.skp,
    };
    const index = values_tobeshown.no - 1;
    let skp_total = 0;
    let newData_tobeshown = [...this.state.data];
    newData_tobeshown[index] = add_total_skp;

    this.setState({ data: newData_tobeshown });

    if(0 <= index && index <= this.state.p2kb_details.length-1){
      //anggap dimulai dari index ke 0, jadinya length dikurang 1
      let newData_tobesent = [...this.state.p2kb_details];
      let id = newData_tobesent[index].id;
      values_tobesent.id = id;
      newData_tobesent[index] = values_tobesent;
      this.setState({ p2kb_details: newData_tobesent });
    }
    if(this.state.new_p2kb.length !== 0){
        let newDataP2KB_tobesent = [...this.state.new_p2kb];
        console.log(index-this.state.p2kb_details.length)
        newDataP2KB_tobesent[(index-this.state.p2kb_details.length)] = values_tobesent;
        this.setState({ new_p2kb : newDataP2KB_tobesent})
    }

    newData_tobeshown.forEach((item) => {
      skp_total += item.total_skp;
    });
    this.setState({ skp_total: skp_total });
  }

  componentDidMount() {
    const param = {
      activity_id: this.props.match.params.id,
      start_date: this.state.start_date,
      end_date: this.state.end_date
    };

    const callback = () => {
      const data = this.props.p2kbList_data.data[0]
      let activity_name = data.activity_data.name;
      let activity_type = data.activity_data.activity_type;
      let realm_activity = data.activity_data.realm_activity.name;
      this.setState({
        activity_name: activity_name,
        realm_activity_name: realm_activity,
        activity_type: activity_type
      })
      this.setState({ isLoad: false });

      if (
        this.props.p2kbList_data &&
        this.props.p2kbList_data.data.length !== 0
      ) {
        const newData = this.props.p2kbList_data.data[0].p2kb_details.map(
          (item, index) => ({
            ...item, no : index + 1
            // activity_date: item.activity_date,
            // attachments: item.attachments,
            // total_skp: item.total_skp,
            // mst_activity_skp_type_detail_data: item.mst_activity_skp_type_detail_data,
            // tingkatan: item.mst_activity_skp_type_detail_data.name,
            // last_activity_skp_detail_id: item.last_activity_skp_detail_id,
            // patent_date: JSON.parse(item.form_json).patent_date,
            // patent_number: JSON.parse(item.form_json).patent_number,
            // tools_function: JSON.parse(item.form_json).tools_function,
            // tools_name: JSON.parse(item.form_json).tools_name,
            // values_id: item.values_id,
            // no: index + 1,
            // id: item.id,
            // form_json: item.form_json,
            // status: item.status
          })
        );
        this.setState({ data: newData });
        this.setState({ p2kb_details: newData });
        this.setState({ edit_id: this.props.p2kbList_data.data[0].id });
        this.setState({
          edit_activity_id: this.props.p2kbList_data.data[0].activity_id,
        });
        this.setState({
          skp_total: this.props.p2kbList_data.data[0].total_skp,
        });
      }
    };
    this.props.p2kbList(param, callback);
  }

  render() {
    const { p2kbList, p2kbList_data } = this.props;
    let data = [];
    let activity_type,
      activity_name,
      realm_activity,
      skp_status,
      last_skp_admin,
      confirmation_date,
      submit_status,
      length,
      records_total = "";
    let is_reject = false;
    if (p2kbList_data && p2kbList_data.data.length > 0) {
      data = p2kbList_data.data[0];
      let arrayData = []
      p2kbList_data.data.map((item, index) => {
        item.p2kb_details.map((detail, idx) => {
          const isIdExists = arrayData.some((dataItem) => dataItem.id === detail.id);
          // console.log(item) // untuk mengecek apakah id sudah berada dalam arrayData
          if (!isIdExists) {
              arrayData.push(detail);
          }
        })
      })
      data.p2kb_details = arrayData;
      activity_name = data.activity_data.name;
      activity_type = data.activity_data.activity_type;
      realm_activity = data.activity_data.realm_activity.name;
      skp_status = data.status;
      last_skp_admin =
        data.role_next_approve !== null
          ? data.role_next_approve.role_name
          : "CPD/CME";
      is_reject = skp_status === 2 ? true : false;
      if (is_reject === true) {
        confirmation_date =
          "Ditolak pada " +
          moment(data.last_rejected_date).format("DD/MM/YYYY");
      } else {
        confirmation_date =
          "Diajukan pada " + moment(data.updated_at).format("DD/MM/YYYY");
      }
      submit_status = this.state.new_p2kb == null || this.state.new_p2kb.length === 0 ? data.submit_status : 0;
      length = data.p2kb_details.length > 10 ? 10 : data.p2kb_details.length;
      records_total = data.p2kb_details.length;
    }
    else {
      activity_name = this.state.activity_name;
      activity_type = this.state.activity_type;
      realm_activity = this.state.realm_activity_name;
    }
    return (
      <ContentContainer>
        <ModalDetailDokumenSKP
          show={this.state.modalDetailDokumenSKPShow}
          onHide={() => this.setState({ modalDetailDokumenSKPShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
        />
        <ModalDetailDokumen
          show={this.state.modalDetailDokumenShow}
          onHide={() => this.setState({ modalDetailDokumenShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
        />
        <ModalAddDetailSKPMenciptakanAlat
          show={this.state.ModalAddDetailSKP_MenciptakanAlatShow}
          onHide={() =>
            this.setState({ ModalAddDetailSKP_MenciptakanAlatShow: false })
          }
          handleAddDetail={this.handleAddSkpDetail}
        />
        <HeaderDetailP2kbComponent
          activity_name={activity_name}
          activity_type={activity_type}
          realm_activity={realm_activity}
          skp={this.state.skp_total}
          skp_status={skp_status}
          last_skp_admin={last_skp_admin}
          icon={IconError}
          isReject={is_reject}
          confirmation_date={confirmation_date}
          isAdmin={false}
          approval={
            data.role_next_approve
              ? data.role_next_approve.role_code.includes("ketua") ||
                data.role_next_approve.role_code.includes("cpd")
                ? "Persetujuan"
                : "Verifikasi"
              : "Persetujuan"
          }
          submit_status={submit_status}
        />
        <ModalRejectSKP
          show={this.state.modalRejectSKPShow}
          onHide={() => this.setState({ modalRejectSKPShow: false })}
          reason={this.state.revisionReason}
          imgUrl={this.state.revisionImage}
        />
        <ModalDeleteSKP
          show={this.state.ModalDeleteSKPShow}
          onHide={() => this.setState({ ModalDeleteSKPShow: false})}
          deleteSKP={() => {
            if(this.state.DeletedData!=null){
              this.handleDeleteSkpDetail(this.state.DeletedData)
            }                   
          }}
        />
        <ModalEditDetailSKPMenciptakanAlat
          show={this.state.ModalEditDetailSKP_MenciptakanAlatShow}
          onHide={() =>
            this.setState({ ModalEditDetailSKP_MenciptakanAlatShow: false })
          }
          handleEditDetail={this.handleEditSkpDetail}
          handleRefresh={this.handleRefresh}
        />
        <ModalDetailSKPMenciptakanAlat
          show={this.state.ModalDetailSKP_MenciptakanAlatShow}
          onHide={() =>
            this.setState({ ModalDetailSKP_MenciptakanAlatShow: false })
          }
          handleEditDetail={this.handleEditSkpDetail}
        />
        {submit_status !== 2 && (
          <div className={css(styles.submitButtonContainer)}>
            <Button
              variant="contained"
              color="primary"
              className={css(styles.rejectButton)}
              onClick={() =>
                this.setState({
                  ModalAddDetailSKP_MenciptakanAlatShow: true,
                })
              }
              startIcon={<AddIcon />}
            >
              Tambah
          </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!this.state.hasEdit  && !this.state.hasAdd}
              className={css(styles.approveButton)}
              onClick={this.handleSubmit}
              startIcon={
                this.state.hasSubmit ? (
                  <CircularProgress size={14} color="secondary" />
                ) : (
                  <CheckIcon />
                )
              }
            >
              Simpan
            </Button>
          </div>
        )}
        <MaterialTable
          tableRef={this.tableRef}
          title="P2kb"
          columns={[
            {
              title: "No",
              field: "name",
              render: (rowData) => {
                return <>{rowData.no}</>;
              },
              width: 20,
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },
            {
              title: "Tanggal Kegiatan",
              render: (data) => {
                return moment(data.activity_date)
                  .locale("id", localization)
                  .format("DD MMMM YYYY");
              },
            },
            {
              title: "Tingkatan Kegiatan",
              render: (data) => {
                if (data.form_json) {
                  return data.mst_activity_skp_type_detail_data.name;
                } else {
                  return data.tingkatan;
                }
              },
              // field: "mst_activity_skp_type_detail_data.name",
            },
            {
              title: "Nama Alat",
              render: (data) => {
                if (data.form_json) {
                  let result = JSON.parse(data.form_json);
                  return result.tools_name;
                } else {
                  return data.tools_name;
                }
              },
            },
            {
              title: "Fungsi Alat",
              render: (data) => {
                if (data.form_json) {
                  let result = JSON.parse(data.form_json);
                  return result.tools_function;
                } else {
                  return data.tools_function;
                }
              },
            },
            { title: "Jumlah SKP", field: "total_skp" },
            {
              title: "Dokumen",
              render: (data) => {
                return (
                  <DetailButtonComponent
                    button={(props) => (
                      <Row
                        onClick={props.onClick}
                        className={css(styles.buttonDocHover)}
                      >
                        <IconDocumentP2KB fill="Black" />
                      </Row>
                    )}
                  >
                    <MenuItem
                      onClick={() =>
                        this.handleOpenDocument(
                          data.attachments[0].filename,
                          "Berkas"
                        )
                      }
                    >
                      <IconLaunch />{" "}
                      <span style={{ marginLeft: 10, paddingTop: 2 }}>
                        Berkas
                      </span>
                    </MenuItem>
                  </DetailButtonComponent>
                );
              },
            },
            {
              title: "Status",
              render: (data) => {
                let submit_status ="";
                if(data.p2kb_data != null){
                    submit_status = data.p2kb_data.submit_status
                }else{
                    submit_status = 0;
                }
                return (
                  <TableStatusComponent
                    status={
                      data.status === undefined
                        ? "Draft"
                        : data.status === 0 && submit_status === 0
                          ? "Draft"
                          : data.status === 0 && submit_status === 1
                            ? "Menunggu"
                            : data.status === 1
                              ? "Approved"
                              : "Ditolak"
                    }
                  />
                );
              },
            },
            {
              title: "",
              render: (data) => {
                let submit_status ="";
                if(data.p2kb_data != null){
                    submit_status = data.p2kb_data.submit_status
                }else{
                    submit_status = 0;
                }
                return data.status === 2 && submit_status === 1 ? (
                  <DetailButtonComponent>
                    <MenuItem
                      onClick={() => this.handleEditSkpDetailModal(data)}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem onClick={() => this.handleOpenModalDelete(data)}>
                      Hapus
                    </MenuItem>
                    {/* <MenuItem onClick={() => this.handleOpenRevision(data)}>
                      Keterangan Revisi
                    </MenuItem> */}
                  </DetailButtonComponent>
                ) : (data.status !== 2 && submit_status === 1) || (data.status === 1 && submit_status === 0) ? (
                  <DetailButtonComponent>
                    <MenuItem onClick={() => this.handleSkpDetail(data)}>
                      Detail
                    </MenuItem>
                  </DetailButtonComponent>
                ) : (
                  (submit_status === 0 && data.status !== 1) && (
                    <DetailButtonComponent>
                      <MenuItem
                        onClick={() => this.handleEditSkpDetailModal(data)}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem onClick={() => this.handleSkpDetail(data)}>
                        Detail
                      </MenuItem>
                      <MenuItem onClick={() => this.handleDeleteSkpDetail(data)}>
                        Hapus
                      </MenuItem>
                    </DetailButtonComponent>
                  )
                );
              },
            },
          ]}
          data={
            this.state.data.length === 0
              ? (query) =>
                new Promise((resolve, reject) => {
                  let param = {
                    page: query.page + 1,
                    length: 10,
                    search_text: this.state.search,
                    status: this.state.status,
                    start_date: this.state.start_date,
                    end_date: this.state.end_date,
                    activity_type: this.state.activity_type,
                    activity_id: this.props.match.params.id,
                  };
                  p2kbList(param, resolve, reject, true);
                })
              : this.state.data
          }
          options={{
            paginationType: "stepped",
            pageSize: 10,
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            headerStyle: {
              backgroundColor: "#F8F9FB",
              zIndex: 0,
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <Row
                // vertical="center"
                horizontal="space-between"
                style={{ paddingRight: 20, paddingLeft: 20 }}
              >
                <Row
                  style={{
                    // backgroundColor: "red",
                    width: "50%",
                    borderBottom: "1px solid rgba(224, 224, 224)",
                  }}
                  vertical="center"
                >
                  <p>
                    {" "}
                    Menampilkan {length} dari {records_total} data{" "}
                  </p>
                </Row>
                <TablePagination
                  {...props}
                  color="primary"
                  labelRowsPerPage={
                    <div style={{ fontSize: 14 }}>{props.labelRowsPerPage}</div>
                  }
                  labelDisplayedRows={(row) => (
                    <div style={{ fontSize: 14, color: "red" }}></div>
                  )}
                  SelectProps={{
                    style: {
                      fontSize: 20,
                    },
                  }}
                />
              </Row>
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  buttonDocHover: {
    ":hover": {
      cursor: "pointer",
    },
  },
  submitButtonContainer: {
    padding: 20,
    textAlign: "left",
  },
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
    marginRight: 10,
  },
  rejectButton: {
    background: "#EAEBED",
    marginLeft: 10,
    marginRight: 20,
    textAlign: "center",
    color: "#495057",
    boxShadow: "none",
  },
});

function mapStateToProps({ p2kbList, addP2kb, editP2kb, deleteP2kb }) {
  return {
    p2kbList_pending: getP2kbListPending(p2kbList),
    p2kbList_data: getP2kbList(p2kbList),
    p2kbList_error: getP2kbListError(p2kbList),
    p2kbEdit_pending: getP2kbEditPending(editP2kb),
    p2kbEdit_data: getP2kbEdit(editP2kb),
    p2kbEdit_error: getP2kbEditError(editP2kb),
    p2kbDelete_pending: getP2kbDeletePending(deleteP2kb),
    p2kbDelete_data: getP2kbDelete(deleteP2kb),
    p2kbDelete_error: getP2kbDeleteError(deleteP2kb),
    addP2kb_pending: getP2kbAddPending(addP2kb),
    addP2kb_data: getP2kbAdd(addP2kb),
    addP2kb_error: getP2kbAddError(addP2kb)
  };
}

export default connect(mapStateToProps, { p2kbList, addP2kb, editP2kb, editP2kb2, deleteP2kb, setSelectedSKP })(
  withRouter(DetailP2kb)
);
