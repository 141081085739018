import React from "react";
import { Row } from "simple-flexbox";
import { TablePagination } from "@material-ui/core";
import { useEffect } from "react";

const PaginationComponent = (props) => {
  const { records, records_total, withoutDesc, ...resProps } = props;

  const windowWidth = window.innerWidth;
  return (
    <td style={{ width: "100%", display: "block" }}>
      <Row
        horizontal={windowWidth > 768 ? "space-between" : "center"}
        style={{
          paddingRight: 20,
          paddingLeft: 20,
        }}
      >
        {windowWidth > 768 && (
          <Row
            style={{
              width: "50%",
            }}
            vertical="center"
          >
            {records && records_total ? (
              <p>
                Menampilkan {records} dari {records_total} data
              </p>
            ) : (
              ""
            )}
          </Row>
        )}
        <div>
          <table>
            <tbody>
              <tr>
                <TablePagination {...resProps} />
              </tr>
            </tbody>
          </table>
        </div>
      </Row>
    </td>
  );
};

export default PaginationComponent;
