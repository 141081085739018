import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, reset } from "redux-form";
import {
    renderSelectWithLabel, renderDatepickerWithLabel, renderInputWithLabel, renderFileForm
} from "../../../components/form/FormComponent";
import { listActivity } from "../../../actions/master-data/activity_action";
import { getListActivity, getActivityPending, getActivityError } from "../../../reducers/master-data/activity_reducer";
import { uploadP2kb, editDetailP2kb } from "../../../actions/p2kb/p2kb_action";
import {
    getP2kbUploadPending,
    getP2kbUpload,
    getP2kbUploadError,
    getP2kbEditDetailPending,
    getP2kbEditDetail,
    getP2kbEditDetailError,
} from "../../../reducers/p2kb/p2kb_reducer";
import DetailContentLihatBerkas from "../../../components/detail/DetailContentLihatBerkas";
import ModalDetailDokumenSKP from "../Modal_document_pdf";
import ModalDetailDokumen from "../Modal_document";
import Loading from "../../../components/global-components/LoadingComponent";

class ModalEditDetailSKP_Seminar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tempSKPData: JSON.parse(localStorage.getItem("skp_add_data_temp")),
            skp_detail: JSON.parse(localStorage.getItem("skp_detail")),
            modalDetailDokumenSKPShow: false,
            modalDetailDokumenShow: false,
            berkas_sertifikat: "",
            berkas_sertifikat_button: false,
            isDoneUploading: true,
            imgUrl: "",
            modal_title: "",
            selected_activity: null,
            selected_scale: null,
            selected_peran: null,
            selected_duration: null,
            spek_berkas_sertifikat: null
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.handleChangeScale = this.handleChangeScale.bind(this);
        this.handleChangePeran = this.handleChangePeran.bind(this);
        this.handleChangeDuration = this.handleChangeDuration.bind(this);
        this.updateState = this.updateState.bind(this);
    }

    handleOpenDocument(imgUrl, modal_title) {
        this.setState({ imgUrl: imgUrl })
        this.setState({ modal_title: modal_title })
        if (imgUrl.split('.')[1] === "pdf") {
            this.setState({ modalDetailDokumenSKPShow: true })
        } else {
            this.setState({ modalDetailDokumenShow: true })
        }
    }

    handleChangeScale(values) {
        this.props.change('last_activity_skp_detail_id', null);
        this.props.change('values_id', null);
        this.props.change('skp_total', null);
    }

    handleChangePeran(values) {
        this.props.change('values_id', null);
        this.props.change('skp_total', null);
    }

    handleChangeDuration(values) {
        let activity_id = null;
        if (this.state.tempSKPData) {
            activity_id = this.state.tempSKPData.activity.value;
        } else {
            activity_id = this.state.skp_detail.activity_id
        }
        let activity_selected = this.props.listActivity_data.data.find(item => item.id === activity_id);
        let scale_selected = activity_selected.childrens.find(item => item.id === this.props.formValue.pertemuan.value);
        let peran_selected = scale_selected.childrens.find(item => item.id === this.props.formValue.last_activity_skp_detail_id.value);
        let duration_selected = peran_selected.values.find(item => item.id === values.value);
        let skp_must_be_multipied = duration_selected.skp_must_be_multipied;
        let value_multipied = duration_selected.value_multipied;
        if (skp_must_be_multipied === 0) {
            this.props.change('skp_total', duration_selected.value_skp);
        } else {
            this.props.change('skp_total', +(duration_selected.value_skp * value_multipied).toFixed(2));
        }
    }

    handleUploadFile(values) {
        const formName = values.target.name;
        // if (formName === "berkas_sip") {
        //     this.setState({ berkas_sip: "Berkas SIP berhasil diunggah" })
        //     this.props.change("berkas_sip", "dvorak");
        // } else if (formName === "berkas_sertifikat") {
        //     this.setState({ berkas_sertifikat: "Berkas Medis berhasil diunggah" })
        //     this.props.change("berkas_sertifikat", "qwerty");
        // }
        // if (values.target.files[0].size < 2000000) {
        this.setState({ isDoneUploading: false })
        this.setState({ berkas_sertifikat_button: true })
        if (formName === "berkas_sertifikat") {
            this.setState({ berkas_sertifikat: <Loading /> })
        }
        const callback = () => {
            this.setState({ isDoneUploading: true })
            this.setState({ berkas_sertifikat_button: false })
            if (this.props.uploadP2kb_data) {
                if (formName === "berkas_sertifikat") {
                    this.props.change("berkas_sertifikat", this.props.uploadP2kb_data);
                    this.setState({ berkas_sertifikat: <Loading done={true} /> })
                    setTimeout(() => this.setState({ berkas_sertifikat: <DetailContentLihatBerkas onClick={() => this.handleOpenDocument(this.props.formValue.berkas_sertifikat, "Bukti Sertifikat")} /> }), 2000)
                }
            }
        }
        const error = () => {
            this.setState({ isDoneUploading: true })
            this.setState({ berkas_sertifikat_button: false })
            if (this.props.uploadP2kb_error) {
                if (formName === "berkas_sertifikat") {
                    this.props.change("berkas_sertifikat", null);
                    this.setState({ berkas_sertifikat: <Loading done={true} failed={true} /> })
                }
            }
        }
        this.props.uploadP2kb(values.target.files[0], callback, error);
        this.props.change("spek_berkas_sertifikat", values.target.files[0]);
        // } else {
        //     if (formName === "berkas_sertifikat") {
        //         this.setState({ berkas_sertifikat: "Batas maksimal ukuran file 2 MB!" })
        //     }
        // }
    }

    handleSubmit(values) {
        let activity_id = null;
        const localStorageEdit = JSON.parse(localStorage.getItem("skp_edit_detail_skp_seminar"));
        const { handleEditDetail, onHide, handleRefresh, editDetailP2kb, formValue } = this.props;
        if (this.state.tempSKPData === null) {
            activity_id = localStorageEdit.mst_activity_skp_type_detail_data.activity_id
        } else {
            activity_id = this.state.tempSKPData.activity.value
        }
        if (formValue.isDetail && formValue.status && formValue.status !== 0) {
            let param = {
                id: values.id,
                activity_date: values.activity_date,
                last_activity_skp_detail_id: values.last_activity_skp_detail_id.value,
                values_id: values.values_id.value,
                activity_name: values.activity_name,
                organizer: values.organizer,
                place_of_organization: values.place_of_organization,
                sk_idi: values.sk_idi,
                city_name: values.city_name,
                member_p2kb_id : localStorageEdit.member_p2kb_id,
                attachments: [
                    {
                        label: "Sertifikat Sebagai Pembicara",
                        filename: values.berkas_sertifikat
                    }
                ]
            }
            if (activity_id === "ba0a85d1-9af2-4eb8-83ef-7029ac9363dp") {
                param = {
                    ...param,
                    title_of_paper: values.title_of_paper,
                }
            } else if (activity_id === "ba0a85d1-9af2-4eb8-83ef-7029ac9363dr") {
                param = {
                    ...param,
                    total_skp_eksternal: values.total_skp_eksternal
                }
            }
            const callback = () => {
                onHide();
                handleRefresh();
            }
            editDetailP2kb(param, callback);
        } else {
            let details_tobesent = {
                activity_date: values.activity_date,
                last_activity_skp_detail_id: values.last_activity_skp_detail_id.value,
                values_id: values.values_id.value,
                hasEdit: true,
                activity_name: values.activity_name,
                organizer: values.organizer,
                place_of_organization: values.place_of_organization,
                sk_idi: values.sk_idi,
                city_name: values.city_name,
                member_p2kb_id : localStorageEdit.member_p2kb_id,
                attachments: [
                    {
                        label: "Sertifikat Sebagai Pembicara",
                        filename: values.berkas_sertifikat
                    }
                ]
            }
            if (activity_id === "ba0a85d1-9af2-4eb8-83ef-7029ac9363dp") {
                details_tobesent = {
                    ...details_tobesent,
                    title_of_paper: values.title_of_paper,
                }
            } else if (activity_id === "ba0a85d1-9af2-4eb8-83ef-7029ac9363dr") {
                details_tobesent = {
                    ...details_tobesent,
                    total_skp_eksternal: values.total_skp_eksternal
                }
            }
            let details_tobeshown = {
                ...details_tobesent,
                total_skp: values.skp_total,
                skala_pertemuan: values.pertemuan.label,
                no: localStorageEdit.no,
                tableData: localStorageEdit.tableData,
                values_id_label: values.values_id.label,
                last_activity_skp_detail_id_label: values.last_activity_skp_detail_id.label,
                pertemuan_label: values.pertemuan.label,
                pertemuan_value: values.pertemuan.value,
                member_p2kb_id : localStorageEdit.member_p2kb_id,
            }
            if (localStorageEdit.isDetail) {
                details_tobeshown = {
                    ...details_tobeshown,
                    mst_activity_skp_type_detail_data: {
                        activity_id: localStorageEdit.mst_activity_skp_type_detail_data.activity_id,
                        name: this.props.formValue.last_activity_skp_detail_id.label,
                        value: this.props.formValue.last_activity_skp_detail_id.value,
                        parent: { name: this.props.formValue.pertemuan.label, value: this.props.formValue.pertemuan.value }
                    },
                }
            }
            handleEditDetail(details_tobeshown, details_tobesent);
            onHide();
        }
    }

    updateState() {
        if (this.state.berkas_sertifikat === "") {
            if (JSON.parse(localStorage.getItem("skp_edit_detail_skp_seminar") !== null)) {
                const editData = JSON.parse(localStorage.getItem("skp_edit_detail_skp_seminar"))
                this.setState({ berkas_sertifikat: <DetailContentLihatBerkas onClick={() => this.handleOpenDocument(editData.attachments[0].filename, "Sertifikat")} /> })
            }
        }
    }

    componentDidUpdate(prevProps) {
        this.updateState();
    }

    componentDidMount() {
        this.props.listActivity({ length: 999 });
    }

    render() {
        const { show,
            onHide,
            handleSubmit,
            uploadP2kb_pending,
            listActivity_data,
            formValue: { pertemuan, last_activity_skp_detail_id, isDetail }
        } = this.props;
        const { tempSKPData,
            skp_detail
        } = this.state;
        let scale_selected, peran_selected = "";
        let scaleOptions, peranOptions, durationOptions = [];
        const activity_id_skp = tempSKPData.activity.value;
        if (listActivity_data && activity_id_skp) {
            let listActivity_selected = listActivity_data.data.find(item => item.id === activity_id_skp);
            scaleOptions = listActivity_selected.childrens.map((item) => ({
                label: item.name,
                value: item.id
            }));
            if (pertemuan) {
                scale_selected = listActivity_selected.childrens.find(item => item.id === pertemuan.value);
                peranOptions = scale_selected?.childrens.map((item) => ({
                    label: item.name,
                    value: item.id
                }));
            }
            if (last_activity_skp_detail_id) {
                peran_selected = scale_selected?.childrens.find(item => item.id === last_activity_skp_detail_id.value);
                durationOptions = peran_selected?.values.map((item) => ({
                    label: item.label,
                    value: item.id
                }));
            }
        }
        return (
            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <ModalDetailDokumenSKP
                    show={this.state.modalDetailDokumenSKPShow}
                    onHide={() => this.setState({ modalDetailDokumenSKPShow: false })}
                    imgUrl={this.state.imgUrl}
                    modal_title={this.state.modal_title}
                />
                <ModalDetailDokumen
                    show={this.state.modalDetailDokumenShow}
                    onHide={() => this.setState({ modalDetailDokumenShow: false })}
                    imgUrl={this.state.imgUrl}
                    modal_title={this.state.modal_title}
                />
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Edit SKP</p>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(this.handleSubmit)}
                        style={{ width: "100%" }}
                    >
                        <Column
                            vertical="center"
                            horizontal="stretch"
                            className={css(styles.bodyModal)}
                        >
                            <>
                                <Field
                                    name="activity_date"
                                    placeholder="Masukkan Tanggal Kegiatan"
                                    label="Tanggal Kegiatan"
                                    component={renderDatepickerWithLabel}
                                />
                                <Field
                                    name="activity_name"
                                    placeholder="Masukkan Nama Kegiatan"
                                    label="Nama Kegiatan"
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="pertemuan"
                                    placeholder="Masukkan Skala Pertemuan"
                                    label="Skala Pertemuan"
                                    options={scaleOptions}
                                    onChange={this.handleChangeScale}
                                    isClearable={false}
                                    component={renderSelectWithLabel}
                                />
                                <Field
                                    name="last_activity_skp_detail_id"
                                    placeholder="Masukkan Peran"
                                    label="Peran"
                                    options={peranOptions}
                                    onChange={this.handleChangePeran}
                                    isClearable={false}
                                    component={renderSelectWithLabel}
                                />
                                <Field
                                    name="values_id"
                                    placeholder="Masukkan Durasi"
                                    label="Durasi"
                                    options={durationOptions}
                                    onChange={this.handleChangeDuration}
                                    isClearable={false}
                                    component={renderSelectWithLabel}
                                />
                                <Field
                                    name="organizer"
                                    placeholder="Masukkan Nama Penyelenggara"
                                    label="Penyelenggara"
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="sk_idi"
                                    placeholder="Masukkan No SKP IDI"
                                    label="No SKP IDI"
                                    component={renderInputWithLabel}
                                />
                                {activity_id_skp && activity_id_skp === "ba0a85d1-9af2-4eb8-83ef-7029ac9363dp" && (
                                    <>
                                        <Field
                                            name="title_of_paper"
                                            placeholder="Masukkan Judul Makalah"
                                            label="Judul Makalah"
                                            component={renderInputWithLabel}
                                        />
                                    </>
                                )}
                                <Field
                                    name="place_of_organization"
                                    placeholder="Masukkan Tempat Penyelenggaraan"
                                    label="Tempat Penyelenggaraan"
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="city_name"
                                    placeholder="Masukkan Nama Kota"
                                    label="Kota"
                                    component={renderInputWithLabel}
                                />
                                {activity_id_skp && activity_id_skp !== "ba0a85d1-9af2-4eb8-83ef-7029ac9363dr" && (
                                    <>
                                        <Field
                                            name="skp_total"
                                            placeholder="Jumlah SKP"
                                            label="Jumlah SKP"
                                            disable={true}
                                            component={renderInputWithLabel}
                                        />
                                    </>
                                )}
                                {activity_id_skp && activity_id_skp === "ba0a85d1-9af2-4eb8-83ef-7029ac9363dr" && (
                                    <>
                                        <Field
                                            name="skp_total"
                                            placeholder="Jumlah SKP 1"
                                            label="Jumlah SKP 1"
                                            disable={true}
                                            component={renderInputWithLabel}
                                        />
                                        <Field
                                            name="total_skp_eksternal"
                                            placeholder="Jumlah SKP 2 (Optional)"
                                            label="Jumlah SKP 2 (Diisi jika SKP 1 tidak sesuai sertifikat)"
                                            component={renderInputWithLabel}
                                        />
                                    </>
                                )}
                                <Field
                                    name="berkas_sertifikat"
                                    type="file"
                                    fileType="skp"
                                    title="Bukti Sertifikat"
                                    disabled={this.state.berkas_sertifikat_button}
                                    isDone={this.state.isDoneUploading}
                                    message={this.props.formValue.berkas_sertifikat === undefined ? "Image atau PDF maksimal 1 MB" : this.state.berkas_sertifikat === null ? "Image atau PDF maksimal 1 MB" : this.state.berkas_sertifikat}
                                    onChange={this.handleUploadFile}
                                    component={renderFileForm}
                                />
                            </>
                        </Column>
                        <Row
                            horizontal="flex-end"
                            vertical="center"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={uploadP2kb_pending}
                                className={css(styles.approveButton)}
                                startIcon={
                                    uploadP2kb_pending ? (
                                        <CircularProgress size={14} color="secondary" />
                                    ) : (
                                        <CheckIcon />
                                    )
                                }
                            >
                                simpan
                            </Button>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        backgroundColor: "#FBFBFD",
        padding: "20px 40px",
    },
    bodyModal: {
        padding: "0px 40px 20px",
        width: "100%",
    },
})

function validate(values) {
    const maxFileSize = 1000000; //1MB
    const typeImage = /image/
    const fileType = "application/pdf"
    const { activity_date, activity_name, pertemuan, last_activity_skp_detail_id, values_id, organizer,
        sk_idi, title_of_paper, place_of_organization, city_name, berkas_sertifikat, spek_berkas_sertifikat } = values;
    const errors = {}
    if (!activity_date) {
        errors.activity_date = "Tanggal kegiatan wajib diisi"
    }
    if (!activity_name) {
        errors.activity_name = "Nama kegiatan wajib diisi"
    }
    if (!pertemuan) {
        errors.pertemuan = "Skala pertemuan wajib diisi"
    }
    if (!last_activity_skp_detail_id) {
        errors.last_activity_skp_detail_id = "Peran wajib diisi"
    }
    if (!values_id) {
        errors.values_id = "Durasi wajib diisi"
    }
    if (!organizer) {
        errors.organizer = "Nama penyelenggara wajib diisi"
    }
    if (!sk_idi) {
        errors.sk_idi = "No SKP IDI wajib diisi"
    }
    if (JSON.parse(localStorage.getItem("skp_edit_detail_skp_seminar"))) {
        if (JSON.parse(localStorage.getItem("skp_edit_detail_skp_seminar")).activity_id && JSON.parse(localStorage.getItem("skp_edit_detail_skp_seminar")).activity_id === "ba0a85d1-9af2-4eb8-83ef-7029ac9363dp") {
            if (!title_of_paper) {
                errors.title_of_paper = "Judul makalah wajib diisi"
            }
        } else if (JSON.parse(localStorage.getItem("skp_edit_detail_skp_seminar")).mst_activity_skp_type_detail_data && JSON.parse(localStorage.getItem("skp_edit_detail_skp_seminar")).mst_activity_skp_type_detail_data.activity_id === "ba0a85d1-9af2-4eb8-83ef-7029ac9363dp") {
            if (!title_of_paper) {
                errors.title_of_paper = "Judul makalah wajib diisi"
            }
        } else if (JSON.parse(localStorage.getItem("skp_add_data_temp")) && JSON.parse(localStorage.getItem("skp_add_data_temp")).activity.value === "ba0a85d1-9af2-4eb8-83ef-7029ac9363dp") {
            if (!title_of_paper) {
                errors.title_of_paper = "Judul makalah wajib diisi"
            }
        }
    }
    if (!place_of_organization) {
        errors.place_of_organization = "Tempat penyelenggaraan wajib diisi"
    }
    if (!city_name) {
        errors.city_name = "Kota wajib diisi"
    }
    // if (JSON.parse(localStorage.getItem("skp_edit_detail_skp_seminar"))) {
    //     if (JSON.parse(localStorage.getItem("skp_edit_detail_skp_seminar")).activity_id && JSON.parse(localStorage.getItem("skp_edit_detail_skp_seminar")).activity_id === "ba0a85d1-9af2-4eb8-83ef-7029ac9363dr") {
    //         if (!total_skp_eksternal) {
    //             errors.total_skp_eksternal = "Jumlah SKP 2 wajib diisi"
    //         }
    //     } else if (JSON.parse(localStorage.getItem("skp_edit_detail_skp_seminar")).mst_activity_skp_type_detail_data && JSON.parse(localStorage.getItem("skp_edit_detail_skp_seminar")).mst_activity_skp_type_detail_data.activity_id === "ba0a85d1-9af2-4eb8-83ef-7029ac9363dr") {
    //         if (!total_skp_eksternal) {
    //             errors.total_skp_eksternal = "Jumlah SKP 2 wajib diisi"
    //         }
    //     } else if (JSON.parse(localStorage.getItem("skp_add_data_temp")) && JSON.parse(localStorage.getItem("skp_add_data_temp")).activity.value === "ba0a85d1-9af2-4eb8-83ef-7029ac9363dp") {
    //         if (!title_of_paper) {
    //             errors.title_of_paper = "Judul makalah wajib diisi"
    //         }
    //     }
    // }
    if (!berkas_sertifikat) {
        errors.berkas_sertifikat = "Berkas sertifikat wajib diisi"
    }
    else{
        if (spek_berkas_sertifikat && (!spek_berkas_sertifikat.type.match(typeImage) && spek_berkas_sertifikat.type !== fileType)){
            errors.berkas_sertifikat = "Format file hanya bisa Image atau PDF"
        }
        else if (spek_berkas_sertifikat && (spek_berkas_sertifikat.size > maxFileSize)){
            errors.berkas_sertifikat = "File lebih dari 1 MB"
        }
    }
    return errors;
}

const afterSubmit = (result, dispatch) => dispatch(reset('ModalAddDetailSKP_Seminar'));

ModalEditDetailSKP_Seminar = reduxForm({
    form: "ModalEditDetailSKP_Seminar",
    validate: validate,
    shouldError: () => true,
    onSubmitSuccess: afterSubmit,
    enableReinitialize: true,
})(ModalEditDetailSKP_Seminar)

function mapStateToProps(state) {
    const { listActivity, uploadP2kb, editDetailP2kb } = state;
    const selector = formValueSelector("ModalEditDetailSKP_Seminar");
    const formValue = selector(state, "skp_total", "sk_idi", "pertemuan", "last_activity_skp_detail_id", "values_id", "berkas_sertifikat", "status", "isDetail", "id", "spek_berkas_sertifikat")
    const editData = JSON.parse(localStorage.getItem("skp_edit_detail_skp_seminar"));
    let initialValues = {};
    if (editData) {
        if (!editData.isDetail) {
            initialValues = {
                activity_date: editData.activity_date,
                activity_name: editData.activity_name,
                pertemuan: {
                    label: editData.pertemuan_label,
                    value: editData.pertemuan_value,
                },
                last_activity_skp_detail_id: {
                    label: editData.last_activity_skp_detail_id_label,
                    value: editData.last_activity_skp_detail_id
                },
                values_id: {
                    label: editData.values_id_label,
                    value: editData.values_id,
                },
                organizer: editData.organizer,
                sk_idi: editData.sk_idi,
                place_of_organization: editData.place_of_organization,
                city_name: editData.city_name,
                skp_total: editData.skp ? editData.skp : editData.total_skp,
                berkas_sertifikat: editData.attachments[0].filename
            }
            if (editData.activity_id === "ba0a85d1-9af2-4eb8-83ef-7029ac9363dp") {
                initialValues = {
                    ...initialValues,
                    title_of_paper: editData.title_of_paper
                }
            }
            if (editData.activity_id === "ba0a85d1-9af2-4eb8-83ef-7029ac9363dr") {
                initialValues = {
                    ...initialValues,
                    total_skp_eksternal: editData.total_skp_eksternal
                }
            }
        } else {
            if (editData.form_json) {
                let form_json = JSON.parse(editData.form_json);
                initialValues = {
                    status: editData.status,
                    isDetail: true,
                    id: editData.id,
                    activity_date: editData.activity_date,
                    activity_name: form_json.activity_name,
                    pertemuan: {
                        label: editData.mst_activity_skp_type_detail_data.parent.name,
                        value: editData.mst_activity_skp_type_detail_data.parent.id
                    },
                    last_activity_skp_detail_id: {
                        label: editData.mst_activity_skp_type_detail_data.name,
                        value: editData.mst_activity_skp_type_detail_data.id
                    },
                    values_id: {
                        label: editData.activity_skp_type_value_data.label,
                        value: editData.activity_skp_type_value_data.id,
                    },
                    organizer: form_json.organizer,
                    sk_idi: form_json.sk_idi,
                    place_of_organization: form_json.place_of_organization,
                    city_name: form_json.city_name,
                    skp_total: editData.total_skp,
                    berkas_sertifikat: editData.attachments[0].filename
                }
                if (editData.mst_activity_skp_type_detail_data.activity_id === "ba0a85d1-9af2-4eb8-83ef-7029ac9363dp") {
                    initialValues = {
                        ...initialValues,
                        title_of_paper: form_json.title_of_paper
                    }
                }
                if (editData.mst_activity_skp_type_detail_data.activity_id === "ba0a85d1-9af2-4eb8-83ef-7029ac9363dr") {
                    initialValues = {
                        ...initialValues,
                        total_skp_eksternal: editData.total_skp_eksternal === 0 ? null : editData.total_skp_eksternal
                    }
                }
            } else {
                initialValues = {
                    status: editData.status,
                    isDetail: true,
                    id: editData.id,
                    activity_date: editData.activity_date,
                    activity_name: editData.activity_name,
                    pertemuan: {
                        label: editData.mst_activity_skp_type_detail_data.parent.name,
                        value: editData.mst_activity_skp_type_detail_data.parent.value
                    },
                    last_activity_skp_detail_id: {
                        label: editData.mst_activity_skp_type_detail_data.name,
                        value: editData.last_activity_skp_detail_id
                    },
                    values_id: {
                        label: editData.values_id_label,
                        value: editData.values_id,
                    },
                    organizer: editData.organizer,
                    sk_idi: editData.sk_idi,
                    place_of_organization: editData.place_of_organization,
                    city_name: editData.city_name,
                    skp_total: editData.total_skp,
                    berkas_sertifikat: editData.attachments[0].filename
                }
                if (editData.mst_activity_skp_type_detail_data.activity_id === "ba0a85d1-9af2-4eb8-83ef-7029ac9363dp") {
                    initialValues = {
                        ...initialValues,
                        title_of_paper: editData.title_of_paper
                    }
                }
                if (editData.mst_activity_skp_type_detail_data.activity_id === "ba0a85d1-9af2-4eb8-83ef-7029ac9363dr") {
                    initialValues = {
                        ...initialValues,
                        total_skp_eksternal: editData.total_skp_eksternal === 0 ? null : editData.total_skp_eksternal
                    }
                }
            }
        }
    }
    return {
        listActivity_pending: getActivityPending(listActivity),
        listActivity_data: getListActivity(listActivity),
        listActivity_error: getActivityError(listActivity),
        uploadP2kb_pending: getP2kbUploadPending(uploadP2kb),
        uploadP2kb_data: getP2kbUpload(uploadP2kb),
        uploadP2kb_error: getP2kbUploadError(uploadP2kb),
        editDetailP2kb_pending: getP2kbEditDetailPending(editDetailP2kb),
        editDetailP2kb_data: getP2kbEditDetail(editDetailP2kb),
        editDetailP2kb_error: getP2kbEditDetailError(editDetailP2kb),
        formValue,
        initialValues
    }
}

export default connect(mapStateToProps, { listActivity, uploadP2kb, editDetailP2kb })(withRouter(ModalEditDetailSKP_Seminar));