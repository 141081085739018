import React, { Component } from "react";
import { Row } from "simple-flexbox";

import ContentContainer from "../../components/container/ContainerComponent";
import LeftContainerComponent from "../../components/container/LeftContainerComponent";
import RightContainerComponent from "../../components/container/RightContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";

import { detailSupport } from "../../actions/support/support_action";
import { withRouter } from "react-router-dom";
import FormContainer from "../../components/container/FormContainer";
// import StatusSquareComponent from "../../components/global-components/StatusSquareComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import ModalDocumentImg from "../../components/global-components/Modal_document_img";
import ModalDocumentPdf from "../../components/global-components/Modal_document_pdf";
import {
  getSupportPending,
  getSupportError,
  getDetailSupport,
  getSupportIsEditFile,
} from "../../reducers/support/support_reducer";
import { connect } from "react-redux";
import moment from "moment";
import { StyleSheet, css } from "aphrodite";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import StatusSquareComponent from "./StatusSquareComponent";

class SupportDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isEditFile: false,
      showApproveModal: false,
      showRejectModal: false,
      modalDocumentImgShow: false,
      modalDocumentPdfShow: false,
    };
    this.tableRef = React.createRef();

    this.shouldComponentRender = this.shouldComponentRender.bind(this);
  }

  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ imgUrl: imgUrl });
    this.setState({ modal_title: modal_title });
    if (
      imgUrl.split(".")[1] === "pdf" ||
      imgUrl.split(".")[1] === "docx" ||
      imgUrl.split(".")[1] === "doc"
    ) {
      this.setState({ modalDocumentPdfShow: true });
    } else {
      this.setState({ modalDocumentImgShow: true });
    }
  }

  shouldComponentRender() {
    const { regisupportationList_pending } = this.props;
    if (regisupportationList_pending === false) {
      return false;
    }
    return true;
  }

  createMarkup(html) {
    return { __html: html };
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      detailSupport,
    } = this.props;
    detailSupport(id);
  }
  render() {
    const { detailSupport_pending, detailSupport_data } = this.props;
    let description,
      filename,
      status,
      updated_at,
      notes,
      title,
      types,
      file_handling,
      ticket_no,
      level,
      member_name;

    if (detailSupport_data && detailSupport_data.length !== 0) {
      [
        {
          description,
          file_handling,
          filename,
          status,
          updated_at,
          notes,
          title,
          types,
          ticket_no,
          level,
          report_member_data: { name: member_name = "-" } = {},
        },
      ] = detailSupport_data;
    }
    return (
      <ContentContainer>
        <ModalDocumentImg
          show={this.state.modalDocumentImgShow}
          onHide={() => this.setState({ modalDocumentImgShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
          folder="member_support"
        />
        <ModalDocumentPdf
          show={this.state.modalDocumentPdfShow}
          onHide={() => this.setState({ modalDocumentPdfShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
          folder="member_support"
        />
        <Row>
          <LeftContainerComponent>
            <div style={{ marginBottom: 12, color: "#495057" }}>
              Dilaporkan pada
            </div>
            <DetailNameComponent
              name={moment(new Date()).format("DD MMMM YYYY")}
            />
            {!detailSupport_pending && (
              <StatusSquareComponent
                statusLabel={
                  status === 0
                    ? "Laporan telah diajukan pada " +
                      moment(updated_at).format("DD MMMM YYYY")
                    : "Laporan telah selesai pada " +
                      moment(updated_at).format("DD MMMM YYYY")
                }
                statusTitle={
                  status === 0
                    ? "menunggu"
                    : status === 1
                    ? "Proses"
                    : status === 2
                    ? "Selesai"
                    : null
                }
                status={status}
                reject_notes={notes}
              />
            )}
          </LeftContainerComponent>
          <RightContainerComponent>
            <Row
              vertical="center"
              horizontal="space-between"
              style={{ width: "100%", marginBottom: 20 }}
            >
              <DetailNameComponent name="Informasi Laporan" />
            </Row>
            <DetailContentComponent label="Judul Pengajuan" value={title} />
            <DetailContentComponent
              label="Jenis Pengajuan"
              value={types === 1 ? "Teknis" : "Profesional"}
            />
            <DetailContentComponent
              label="Nomor Trouble Ticket"
              value={ticket_no}
            />

            <FormContainer
              label={
                <div style={{ height: "100%", paddingTop: 20 }}>Deskripsi</div>
              }
            >
              <div
                className="support"
                dangerouslySetInnerHTML={this.createMarkup(description)}
              />
            </FormContainer>
            <DetailContentComponent
              label="Member yang Dilaporkan"
              value={member_name}
            />

            <DetailContentComponent
              label="Berkas Pendukung"
              value={
                <DetailContentLihatBerkas
                  onClick={() =>
                    this.handleOpenDocument(filename, "Berkas Penanganan")
                  }
                />
              }
            />
            {status === 2 && (
              <DetailContentComponent
                label="Berkas Penanganan"
                value={
                  <DetailContentLihatBerkas
                    onClick={() =>
                      this.handleOpenDocument(
                        file_handling,
                        "Berkas Penanganan"
                      )
                    }
                  />
                }
              />
            )}
            <DetailContentComponent
              label="Status Penanganan"
              style={{
                color:
                  level === 1
                    ? "yellow"
                    : level === 2
                    ? "orange"
                    : level === 3
                    ? "red"
                    : "#212529",
              }}
              value={
                level === 1
                  ? "Ringan"
                  : level === 2
                  ? "Sedang"
                  : level === 3
                  ? "Berat"
                  : "-"
              }
            />
          </RightContainerComponent>
        </Row>
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  attachment: {
    width: 100,
    height: 30,
    border: "1px solid #2CB96A",
    borderRadius: 3,
    color: "#2CB96A",
  },
});

function mapStateToProps(state) {
  const { detailSupport } = state;

  return {
    detailSupport_error: getSupportError(detailSupport),
    detailSupport_pending: getSupportPending(detailSupport),
    detailSupport_data: getDetailSupport(detailSupport),
    detailSupport_isEditFile: getSupportIsEditFile(detailSupport),
  };
}

export default connect(mapStateToProps, {
  detailSupport,
})(withRouter(SupportDetail));
